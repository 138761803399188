import { Button, Stack, TextField } from '@mui/material';
import { FormEvent, useState } from 'react';

import { useDashboardEvents } from 'entities/dashboard/hooks/useDashboardEvents';
import { EmbeddedDashboardFeature } from 'features/dashboard/EmbeddedDashboard.feature';
import Container from 'shared/components/container/Container';
import { Dialog } from 'shared/components/dialog/Dialog';
import { useQueryParams } from 'shared/hooks/use-query-params';
import { useTemporaryPlaylist } from 'shared/hooks/useTemporaryPlaylist';
import { DashboardPlaylistGenericPayload } from 'shared/types/embeddedDashboard';
import { TemporaryPlaylistWidget } from 'widgets/playlist/temporary-playlist/TemporaryPlaylist.widget';

export const DashboardPreviewPage = () => {
  const query = useQueryParams();
  const dashboardIdQueryParam = query.get('dashboardId');
  const [dashboardId, setDashboardId] = useState<string>(dashboardIdQueryParam || '');
  const [playlist, setPlaylist] = useState<DashboardPlaylistGenericPayload | undefined>(undefined);
  const { data: temporaryPlaylist } = useTemporaryPlaylist({ playlist });

  useDashboardEvents({ onEvent: setPlaylist });

  const handleFormSubmit = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setDashboardId(event.currentTarget.dashboardId.value);
  };

  return (
    <Container fullScreen>
      <form onSubmit={handleFormSubmit} style={{ height: '100%' }}>
        {!dashboardIdQueryParam && (
          <Stack direction={'row'} alignItems={'center'} gap={2} padding={2} height={'100px'}>
            <TextField id={'dashboardId'} size={'small'} sx={{ width: '380px' }} />
            <Button color={'primary'} variant={'contained'} size={'large'} type={'submit'}>
              Select Dashboard
            </Button>
          </Stack>
        )}
        {dashboardId && <EmbeddedDashboardFeature key={dashboardId} dashboardId={dashboardId} />}
        <Dialog
          onClose={() => setPlaylist(undefined)}
          open={!!temporaryPlaylist}
          maxWidth={false}
          fullScreen
          sx={{ margin: 2 }}
        >
          {temporaryPlaylist && <TemporaryPlaylistWidget playlist={temporaryPlaylist} />}
        </Dialog>
      </form>
    </Container>
  );
};
