import { AxiosResponseHeaders, RawAxiosResponseHeaders } from 'axios';

import { callBackendApi } from 'api/hooks/callBackendApi';
import { XMLDownloadUrl } from 'api/routes';
import { HTTPMethod } from 'api/types';
import { downloadFileFromBlob } from 'shared/utils/downloadFileFromBlob';

export const XML_GROUP_BY = {
  TEAM: 'team',
  PLAYER: 'player',
};

export const useDownloadAnalysisXml = (
  recordingId: string,
  tacticalAnalysisId?: string,
  isEffectiveTime = false,
  groupBy: (typeof XML_GROUP_BY)[keyof typeof XML_GROUP_BY] = 'team',
) => {
  const downloadXml = () => (response: Blob, headers?: RawAxiosResponseHeaders | AxiosResponseHeaders) => {
    if (!headers) return;

    downloadFileFromBlob(response, headers['content-disposition'].split('filename=')[1].replace(/"/g, ''), 'text/xml');
  };

  return () =>
    callBackendApi<Blob>(
      XMLDownloadUrl(recordingId, tacticalAnalysisId, isEffectiveTime, groupBy),
      HTTPMethod.GET,
      downloadXml(),
    );
};
