import { Card, CardContent, Container, Stack, Typography } from '@mui/material';
import { fontWeight } from 'kognia-ui';
import { IconChevronRight } from 'kognia-ui/icons/IconChevronRight';
import React, { useCallback, useRef } from 'react';
import { generatePath } from 'react-router-dom';

import { useCreateClient } from 'api/backoffice/client/hooks/use-create-client/useCreateClient';
import { routes } from 'kognia/router/routes';
import { EnvironmentForm } from 'pages/backoffice/features/environment-form/EnvironmentForm.feature';
import { EnvironmentFormRef } from 'pages/backoffice/features/environment-form/types/environmentFormRef';
import { EnvironmentOnSubmit } from 'pages/backoffice/features/environment-form/types/environmentOnSubmit';
import { BackofficeContentContainer } from 'pages/backoffice/ui/BackofficeContentContainer';
import { useRedirectTo } from 'shared/components/sidebar-layout/ui/sidebar-menu/hooks/use-redirect-to';

export const CreateEnvironmentPage = () => {
  const { createClient, isPending } = useCreateClient();
  const redirectTo = useRedirectTo();
  const environmentFormRef = useRef<EnvironmentFormRef>(null);

  const handleAddOnSuccess = useCallback(() => {
    if (environmentFormRef.current) {
      environmentFormRef.current.reset();
    }
    redirectTo(generatePath(routes.BACKOFFICE_ENVIRONMENTS));
  }, [redirectTo]);

  const handleCreateClient = useCallback(
    ({ data }: EnvironmentOnSubmit) => {
      createClient({
        data,
        onSuccess: handleAddOnSuccess,
      });
    },
    [createClient, handleAddOnSuccess],
  );

  return (
    <BackofficeContentContainer>
      <Container fixed maxWidth='sm'>
        <Stack direction={'column'} spacing={2}>
          <Typography
            variant={'h6'}
            sx={{ fontWeight: fontWeight['400'] }}
            display='flex'
            alignItems='center'
            gap={0.5}
          >
            <span>Environments</span>
            <IconChevronRight size='small' color='secondary' />
            <span>Add environment</span>
          </Typography>
          <Card>
            <CardContent>
              <EnvironmentForm isLoading={isPending} onSubmit={handleCreateClient} ref={environmentFormRef} />
            </CardContent>
          </Card>
        </Stack>
      </Container>
    </BackofficeContentContainer>
  );
};
