import { zodResolver } from '@hookform/resolvers/zod';
import { AccordionActions, AccordionDetails } from '@mui/material';
import React, { useCallback } from 'react';
import { useForm } from 'react-hook-form';

import { useAnnotationTask } from 'pages/backoffice/api/operations/useAnnotationTask';
import { AnnotationFormActions } from 'pages/backoffice/pages/annotation/ui/AnnotationFormActions';
import { AnnotationToolField } from 'pages/backoffice/pages/annotation/ui/form-fields/AnnotationToolField';
import { IndexTypeGroup } from 'pages/backoffice/pages/annotation/ui/form-fields/IndexTypeGroup';
import { PipelineTypeField } from 'pages/backoffice/pages/annotation/ui/form-fields/PipelineTypeField';
import { RecordingIdField } from 'pages/backoffice/pages/annotation/ui/form-fields/RecordingIdField';
import { TaskTypeField } from 'pages/backoffice/pages/annotation/ui/form-fields/TaskTypeField';
import { annotationsTaskFormSchema } from 'pages/backoffice/pages/annotation/utils/form-fields-validation';
import {
  AnnotationsForm,
  AnnotationsTaskFormSchema,
} from 'pages/backoffice/pages/annotation/utils/form-fields-validation/types';

export const AnnotationsTask = () => {
  const { annotateTask } = useAnnotationTask();

  const {
    register,
    formState: { errors },
    reset,
    resetField,
    handleSubmit: handleFormSubmit,
  } = useForm<AnnotationsTaskFormSchema>({
    resolver: zodResolver(annotationsTaskFormSchema),
  });

  const handleSubmit = useCallback(
    (data: AnnotationsForm) => {
      annotateTask({ data });
    },
    [annotateTask],
  );

  return (
    <form onSubmit={handleFormSubmit(handleSubmit)}>
      <AccordionDetails>
        <RecordingIdField errors={errors} register={register} />
        <TaskTypeField errors={errors} register={register} />
        <IndexTypeGroup errors={errors} register={register} resetField={resetField} />
        <PipelineTypeField errors={errors} register={register} />
        <AnnotationToolField errors={errors} register={register} />
      </AccordionDetails>
      <AccordionActions>
        <AnnotationFormActions reset={() => reset()} />
      </AccordionActions>
    </form>
  );
};
