import { Stack, FormHelperText } from '@mui/material';
import React from 'react';
import { Control, Controller, Path } from 'react-hook-form';

import { SeasonClientsFormFieldsNames } from 'pages/backoffice/pages/associate-season-with-clients/ui/season-clients-form/types';
import { SelectClient } from 'pages/backoffice/pages/associate-season-with-clients/ui/SelectClient';
import { SelectSeason } from 'pages/backoffice/pages/stages/components/select-season';
import { SelectClients } from 'pages/backoffice/ui/select-clients/SelectClients';
import { AutocompleteDropdownButton } from 'shared/components/autocomplete-multi-select/ui/AutocompleteDropdownButton';

interface Props<TFormValues extends Record<string, any>> {
  control: Control<TFormValues>;
  isEdit?: boolean;
  seasonName?: string;
}

export function SeasonClientsFormComponent<TFormValues extends Record<string, any>>({
  control,
  seasonName,
  isEdit = false,
}: Props<TFormValues>) {
  return (
    <Stack direction='column' spacing={2}>
      <Controller
        render={({ field: { onChange, value }, fieldState: { error } }) => (
          <>
            <SelectClient setClientOnChange={onChange} client={value} disabled={isEdit} />
            {error && <FormHelperText error>{error.message}</FormHelperText>}
          </>
        )}
        name={SeasonClientsFormFieldsNames.originalClient as Path<TFormValues>}
        control={control}
      />
      {!isEdit ? (
        <Controller
          render={({ field: { onChange, value }, fieldState: { error } }) => (
            <>
              <SelectSeason setSeasonOnChange={(season) => onChange(season?.id)} selectedSeasonId={value} />
              {error && <FormHelperText error>{error.message}</FormHelperText>}
            </>
          )}
          name={SeasonClientsFormFieldsNames.seasonId as Path<TFormValues>}
          control={control}
        />
      ) : (
        <AutocompleteDropdownButton
          fullWidth
          isOpen={false}
          isSelected={Boolean(seasonName)}
          disabled
          label={seasonName ? seasonName : 'Select season'}
        />
      )}
      <Controller
        render={({ field: { onChange, value }, fieldState: { error } }) => (
          <>
            <SelectClients onChange={onChange} clientIds={value || []} />
            {error && <FormHelperText error>{error.message}</FormHelperText>}
          </>
        )}
        name={SeasonClientsFormFieldsNames.clientIds as Path<TFormValues>}
        control={control}
      />
    </Stack>
  );
}
