import find from 'lodash/find';
import forEach from 'lodash/forEach';
import map from 'lodash/map';

import { TaggingEvent } from 'api/tagging-tool/types';
import {
  RowGroup,
  RowList,
} from 'pages/tactical-analysis/api/use-tactical-analysis-data/generate-timeline-rows/types/row';
import { TimelineTableBlock } from 'pages/tactical-analysis/api/use-tactical-analysis-data/generate-timeline-rows/types/timeline';
import { generateBlockContainerClip } from 'pages/tactical-analysis/api/use-tactical-analysis-data/generate-timeline-rows/utils/generateBlockContainerClip';
import { getClipsFromTaggingEvent } from 'pages/tactical-analysis/api/use-tactical-analysis-data/generate-timeline-rows/utils/getClipsFromTaggingEvent';
import { groupTagsByType } from 'shared/utils/group-tags-by-type';

const groupAndOrderTaggingEvents = (taggingEvents: TaggingEvent[]) => {
  const groupedTags = groupTagsByType(taggingEvents);

  const groupedAndOrderedTags: {
    [key: string]: { [key: string]: { name: string; group: string; tags: TaggingEvent[] } };
  } = {};

  forEach(groupedTags, (tags, tagGroup) => {
    const ordered = tags.sort((a, b) => a.name.localeCompare(b.name));

    ordered.forEach((tag) => {
      if (!groupedAndOrderedTags[tagGroup]) {
        groupedAndOrderedTags[tagGroup] = {};
      }
      if (!groupedAndOrderedTags[tagGroup][`${tag.name}-${tagGroup}`]) {
        groupedAndOrderedTags[tagGroup][`${tag.name}-${tagGroup}`] = { name: tag.name, group: tagGroup, tags: [] };
      }
      groupedAndOrderedTags[tagGroup][`${tag.name}-${tagGroup}`].tags.push(tag);
    });
  });

  return groupedAndOrderedTags;
};

export const generateManualTagsRows = (
  timelineTableBlocks: TimelineTableBlock[],
  taggingEvents: TaggingEvent[],
  recordingId: string,
): RowGroup => {
  const manualTagRowGroups: { [key in string]: RowGroup } = {};

  // Group tagging events by tag name
  const groupedTaggingEvents = groupAndOrderTaggingEvents(taggingEvents);

  forEach(groupedTaggingEvents, (groupedTags, tagGroup) => {
    const rows: RowList = {};
    forEach(groupedTags, (nameTag, tagNameGroup) => {
      const rowId = `${recordingId}-${tagNameGroup}`;

      const modifiedTags = getClipsFromTaggingEvent(nameTag.tags, rowId);
      modifiedTags.forEach((tag) => {
        if (!rows[rowId]) {
          rows[rowId] = generateBlockContainerClip({
            id: rowId,
            rowType: 'manual-tags',
            timelineTableBlocks: timelineTableBlocks,
            title: nameTag.name,
            rowId: rowId,
            clipIdPrefix: tagNameGroup,
          });
        }

        const episodeClip = find(
          rows[rowId].clips,
          (row) =>
            (tag.startTime >= row.startTime && tag.startTime <= row.endTime) ||
            (tag.endTime >= row.startTime && tag.endTime <= row.endTime),
        );

        if (episodeClip) {
          episodeClip?.clips?.push(tag);
        }
      });
    });

    const groupRows = map(rows, (row) => row);
    manualTagRowGroups[tagGroup] = {
      id: `${recordingId}-${tagGroup}`,
      isOpen: true,
      isSelected: false,
      title: `timeline:${tagGroup}`,
      totalClips: groupRows.reduce((acc, row) => acc + row.clips.length, 0),
      rows: groupRows,
      type: 'manual-tags',
    };
  });

  const rowGroupsList = map(manualTagRowGroups, (rowGroup) => rowGroup);
  return {
    id: 'manual-tags',
    isOpen: true,
    isSelected: false,
    title: 'timeline:manual-tags',
    rowGroups: rowGroupsList,
    rows: [],
    totalClips: rowGroupsList.reduce((acc, rowGroup) => acc + rowGroup.totalClips, 0),
    type: 'manual-tags',
  };
};
