import { Player, PlayerSportMetadataApi } from 'shared/types/player/player';
import { playerSchema } from 'shared/types/player/playerSchema';

export const transformPlayerApiResponse = (player: PlayerSportMetadataApi): Player => {
  return {
    id: player.uuid,
    name: player.name,
    photoUrl: player.photo_url,
    fixtures: player.fixtures.map((fixture) => ({
      id: fixture.uuid,
      name: fixture.name,
      homeTeamScore: fixture.home_team_score,
      awayTeamScore: fixture.away_team_score,
      homePenaltyScore: fixture.home_penalty_score,
      awayPenaltyScore: fixture.away_penalty_score,
      matchEndType: fixture.match_end_type,
      position: fixture.position,
      side: fixture.side,
      jerseyNumber: fixture.jersey_number,
      date: fixture.date,
      team: {
        id: fixture.team.uuid,
        name: fixture.team.name,
        abbreviation: fixture.team.abbreviation,
        logoUrl: fixture.team.logo_url,
        teamType: fixture.team.team_type,
        countryCode: fixture.team.country_code,
      },
    })),
    isFavorite: player.is_favorite ?? false,
    mappings: player.mappings.map(({ provider, player_id_provider }) => ({
      provider,
      idProvider: player_id_provider,
    })),
    ...(player.player_defaults
      ? {
          playerDefaults: {
            competition: {
              country: player.player_defaults.competition.country,
              id: player.player_defaults.competition.uuid,
              name: player.player_defaults.competition.name,
              format: player.player_defaults.competition.format,
              organization: player.player_defaults.competition.organization
                ? {
                    id: player.player_defaults.competition.organization.uuid,
                    name: player.player_defaults.competition.organization.name,
                    logoUrl: player.player_defaults.competition.organization.logo_url,
                  }
                : null,
              seasons: player.player_defaults.competition.seasons.map(({ uuid, crosses_year, name, year }) => ({
                id: uuid,
                crossesYear: crosses_year,
                name,
                year,
              })),
            },
            season: {
              competition: {
                country: player.player_defaults.season.competition.country,
                id: player.player_defaults.season.competition.uuid,
                name: player.player_defaults.season.competition.name,
              },
              crossesYear: player.player_defaults.season.crosses_year,
              id: player.player_defaults.season.uuid,
              name: player.player_defaults.season.name,
              stages: player.player_defaults.season.stages.map(
                ({ name, child_stages, order_index, parent_stage, uuid }) => ({
                  name,
                  id: uuid,
                  childStages: child_stages.map((child_stage) => ({
                    id: child_stage.uuid,
                    name: child_stage.name,
                    orderIndex: child_stage.order_index,
                  })),
                  orderIndex: order_index,
                  parentStage: parent_stage
                    ? {
                        id: parent_stage?.uuid,
                        name: parent_stage?.name,
                        orderIndex: parent_stage?.order_index,
                      }
                    : null,
                }),
              ),
              year: player.player_defaults.season.year,
            },
            team: {
              id: player.player_defaults.team.uuid,
              name: player.player_defaults.team.name,
              teamType: player.player_defaults.team.team_type,
              abbreviation: player.player_defaults.team.abbreviation,
              countryCode: player.player_defaults.team.country_code,
              logoUrl: player.player_defaults.team.logo_url,
            },
          },
        }
      : {}),
  };
};

export const transformPlayersApiResponseWithValidation = (players: PlayerSportMetadataApi[]): Player[] => {
  return players.map((player) => {
    const transformedPlayer = transformPlayerApiResponse(player);

    const validationResult = playerSchema.safeParse(transformedPlayer);
    if (validationResult.error) {
      throw new Error('Invalid Player type', { cause: validationResult.error });
    }

    return transformedPlayer;
  });
};
