import { Paper, Stack, Typography } from '@mui/material';
import { Suspense, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { useCompetitionsState, useResetCompetitionsState } from 'entities/competitions/store/steps';
import { CompetitionsBreadcrumbsFeature } from 'features/competitions/breadcrumbs/CompetitionsBreadcrumbs.feature';
import { CompetitionsStepFeature } from 'features/competitions/competitions-step/CompetitionsStep.feature';
import { PlayersStepFeature } from 'features/competitions/players-step/PlayersStep.feature';
import { TeamsStepFeature } from 'features/competitions/teams-step/TeamsStep.feature';
import Spinner from 'shared/components/spinner/Spinner';
import { TeamProfileButton } from 'widgets/competitions/ui/team-profile-button/TeamProfileButton';

export const CompetitionsWidget = () => {
  const { t } = useTranslation('explore');
  const { step, season } = useCompetitionsState();
  const resetCompetitionsState = useResetCompetitionsState();

  useEffect(() => {
    return resetCompetitionsState;
  }, [resetCompetitionsState]);

  return (
    <Paper elevation={0} sx={{ height: '100%', minHeight: '100%', overflow: 'hidden' }}>
      <Stack py={3} px={2} gap={2} width='100%' height={'100%'}>
        <Stack gap={1}>
          <Stack direction='row' width='100%' justifyContent='space-between' alignItems='center'>
            <Typography component='h3' variant='body2' fontWeight='fontWeightMedium' px={1}>
              {t('competitions.title')}
            </Typography>
            {season && (
              <Stack direction='row' alignItems='center' gap={0.5} px={2}>
                <Typography variant='caption' component='span' color='secondary'>
                  {t('explore:competitions.season')}
                </Typography>

                <Typography variant='caption' component='span' color='secondary' fontWeight='fontWeightMedium'>
                  {season.crossYearValue}
                </Typography>
              </Stack>
            )}
          </Stack>
          {step !== 'competitions' && (
            <Stack direction='row' justifyContent='space-between' alignItems='center' minHeight={'32px'} gap={1} px={1}>
              <CompetitionsBreadcrumbsFeature />

              {step === 'players' && <TeamProfileButton />}
            </Stack>
          )}
        </Stack>

        <Stack pr={2} sx={{ flexGrow: 1, overflow: 'hidden' }}>
          {step === 'competitions' && <CompetitionsStepFeature />}
          {step === 'teams' && <TeamsStepFeature />}
          {step === 'players' && (
            <Suspense
              fallback={
                <Stack justifyContent={'center'} alignItems={'center'} pl={2}>
                  <Spinner />
                </Stack>
              }
            >
              <PlayersStepFeature />
            </Suspense>
          )}
        </Stack>
      </Stack>
    </Paper>
  );
};
