import { zodResolver } from '@hookform/resolvers/zod';
import { Stack, FormHelperText } from '@mui/material';
import { forwardRef, useCallback } from 'react';
import { Controller, useForm } from 'react-hook-form';

import { useAddUsersToEnvironments } from 'api/backoffice/user/use-add-users-to-environments';
import { SelectClients } from 'pages/backoffice/ui/select-clients/SelectClients';
import { SelectUsers } from 'pages/backoffice/ui/SelectUsers';
import {
  UsersToEnvironmentsForm,
  UsersToEnvironmentsFormFieldsNames,
  usersToEnvironmentsFormSchema,
  UsersToEnvironmentsFormSchema,
} from 'pages/backoffice/utils/usersToEnvironmentsFormSchema';

interface Props {
  onSuccess: () => void;
}

export const AddUsersToEnvironmentsFormFeature = forwardRef(
  ({ onSuccess }: Props, ref: React.LegacyRef<HTMLFormElement> | undefined) => {
    const { addUsersToEnvironments } = useAddUsersToEnvironments();
    const { handleSubmit: handleFormSubmit, control } = useForm<UsersToEnvironmentsFormSchema>({
      defaultValues: {
        [UsersToEnvironmentsFormFieldsNames.ENVIRONMENT_IDS]: [],
        [UsersToEnvironmentsFormFieldsNames.KOGNIA_USERS]: [],
      },
      resolver: zodResolver(usersToEnvironmentsFormSchema),
    });

    const handleAddUsersToEnvironments = useCallback(
      (data: UsersToEnvironmentsForm) => {
        addUsersToEnvironments({
          clientIds: data[UsersToEnvironmentsFormFieldsNames.ENVIRONMENT_IDS],
          kogniaUsers: data[UsersToEnvironmentsFormFieldsNames.KOGNIA_USERS],
          onSuccess,
        });
      },
      [addUsersToEnvironments, onSuccess],
    );

    return (
      <form ref={ref} onSubmit={handleFormSubmit(handleAddUsersToEnvironments)}>
        <Stack direction='column' spacing={2}>
          <Controller
            render={({ field: { value, onChange }, fieldState: { error } }) => (
              <>
                <SelectUsers kogniaUsers={value} setKogniaUsersOnChange={onChange} />
                {error && <FormHelperText error>{error.message}</FormHelperText>}
              </>
            )}
            name={UsersToEnvironmentsFormFieldsNames.KOGNIA_USERS}
            control={control}
          />

          <Controller
            render={({ field: { value, onChange }, fieldState: { error } }) => (
              <>
                <SelectClients clientIds={value} onChange={onChange} />
                {error && <FormHelperText error>{error.message}</FormHelperText>}
              </>
            )}
            name={UsersToEnvironmentsFormFieldsNames.ENVIRONMENT_IDS}
            control={control}
          />
        </Stack>
      </form>
    );
  },
);

AddUsersToEnvironmentsFormFeature.displayName = 'AddUsersToEnvironmentsFormFeature';
