import React from 'react';

import {
  TacticalAnalysisSkeletonContainer,
  TacticalAnalysisSkeletonContent,
  TacticalAnalysisSkeletonContentFilters,
  TacticalAnalysisSkeletonContentTimeline,
  TacticalAnalysisSkeletonFiltersLoading,
  TacticalAnalysisSkeletonVideoContainer,
  TacticalAnalysisSkeletonVideoSkeleton,
} from 'pages/tactical-analysis/components/tactical-analysis/tactical-analysis-page-skeleton/styled';
import Spinner from 'shared/components/spinner/Spinner';

export const TacticalAnalysisPageSkeleton = () => {
  return (
    <TacticalAnalysisSkeletonContainer>
      <TacticalAnalysisSkeletonVideoContainer>
        <TacticalAnalysisSkeletonVideoSkeleton />
      </TacticalAnalysisSkeletonVideoContainer>
      <TacticalAnalysisSkeletonContent>
        <TacticalAnalysisSkeletonContentFilters />
        <TacticalAnalysisSkeletonContentTimeline>
          <TacticalAnalysisSkeletonFiltersLoading>
            <Spinner />
          </TacticalAnalysisSkeletonFiltersLoading>
        </TacticalAnalysisSkeletonContentTimeline>
      </TacticalAnalysisSkeletonContent>
    </TacticalAnalysisSkeletonContainer>
  );
};
