import { Stack } from '@mui/material';
import { useEffect, useState } from 'react';

import { usePlayerWithDefaults } from 'api/player/usePlayerWithDefaults';
import { PlayerCompetitionsFeature } from 'features/dashboard/PlayerCompetitions.feature';
import { PlayerAlert } from 'pages/player-profile/ui/PlayerAlert';
import { Dialog } from 'shared/components/dialog/Dialog';
import { useTemporaryPlaylist } from 'shared/hooks/useTemporaryPlaylist';
import { DashboardPlaylistGenericPayload } from 'shared/types/embeddedDashboard';
import { PlayerProfileHeaderWidget } from 'widgets/dashboard/player-profile-header/PlayerProfileHeader.widget';
import { PlayerProfileDashboardWidget } from 'widgets/dashboard/PlayerProfileDashboard.widget';
import { TemporaryPlaylistWidget } from 'widgets/playlist/temporary-playlist/TemporaryPlaylist.widget';

type Props = {
  playerId: string;
};

export const PlayerProfile = ({ playerId }: Props) => {
  const { data: player } = usePlayerWithDefaults(playerId);
  const [selectedSeasonYear, setSelectedSeasonYear] = useState<number | undefined>(player.playerDefaults?.season?.year);
  const [selectedCompetition, setSelectedCompetition] = useState<string | undefined>(
    player.playerDefaults?.season?.competition?.id,
  );

  const [playlist, setPlaylist] = useState<DashboardPlaylistGenericPayload | undefined>(undefined);
  const { data: temporaryPlaylist } = useTemporaryPlaylist({ playlist });

  useEffect(() => {
    setSelectedSeasonYear(player.playerDefaults?.season?.year);
    setSelectedCompetition(player.playerDefaults?.season?.competition?.id);
  }, [player]);

  return (
    <>
      <Stack gap={2} padding={(theme) => theme.spacing(1.5, 4, 2, 4)}>
        <PlayerProfileHeaderWidget
          competitionId={selectedCompetition}
          seasonYear={selectedSeasonYear}
          playerId={playerId}
        />

        {selectedCompetition && selectedSeasonYear ? (
          <PlayerAlert playerId={playerId} competitionId={selectedCompetition} seasonYear={selectedSeasonYear} />
        ) : null}

        <PlayerCompetitionsFeature
          selectedSeasonYear={selectedSeasonYear}
          selectedCompetition={selectedCompetition}
          onChangeSelectedCompetition={setSelectedCompetition}
          onChangeSelectedSeasonYear={setSelectedSeasonYear}
        />
      </Stack>

      {selectedCompetition && selectedSeasonYear && (
        <>
          <PlayerProfileDashboardWidget
            key={`${selectedCompetition}-${selectedSeasonYear}`}
            competitionId={selectedCompetition}
            seasonYear={selectedSeasonYear}
            player={player}
            onEvent={setPlaylist}
          />
          <Dialog
            onClose={() => setPlaylist(undefined)}
            open={!!temporaryPlaylist}
            maxWidth={false}
            fullScreen
            sx={{ margin: 2 }}
          >
            {temporaryPlaylist && <TemporaryPlaylistWidget playlist={temporaryPlaylist} />}
          </Dialog>
        </>
      )}
    </>
  );
};
