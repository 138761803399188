import { Avatar, Box, List, ListItem, Typography } from '@mui/material';
import { IconTimeline } from 'kognia-ui/icons/IconTimeline';

import { Organization } from 'pages/backoffice/types/organizations';
import { DialogNew } from 'shared/components/dialog-new/DialogNew';

interface Props {
  organization: Organization;
  onClose: () => void;
}

export const OrganizationDetailsDialog = ({ organization, onClose }: Props) => {
  return (
    <DialogNew
      open={true}
      onClose={onClose}
      maxWidth={'sm'}
      fullWidth
      title={'Organization details'}
      icon={<IconTimeline size={'small'} />}
    >
      <Box>
        <List>
          <ListItem>
            <Typography>
              <Typography mr={1} component={'span'} fontWeight={'fontWeightMedium'}>
                Name:
              </Typography>
              {organization.name}
            </Typography>
          </ListItem>
          <ListItem>
            <Typography fontWeight={'fontWeightMedium'} mr={1}>
              Logo:
            </Typography>
            {organization?.logoUrl ? (
              <Avatar variant={'square'} src={organization.logoUrl ?? undefined} />
            ) : (
              <Typography>No logo</Typography>
            )}
          </ListItem>
          <ListItem>
            <Typography fontWeight={'fontWeightMedium'} mr={1}>
              Competitions:
            </Typography>
            {organization.competitions?.length > 0 ? (
              organization.competitions?.map((competition) => (
                <Typography mr={0.5} key={competition.id}>
                  {competition.name},
                </Typography>
              ))
            ) : (
              <Typography>No competitions</Typography>
            )}
          </ListItem>
        </List>
      </Box>
    </DialogNew>
  );
};
