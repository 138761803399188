import { IconSizes } from 'kognia-ui/components/SvgIcon';
import React, { useCallback } from 'react';

import ActiveFilter from 'shared/components/active-filter/ActiveFilter';

interface Props {
  id: string;
  name: string;
  onRemove: (id: string) => void;
  size?: IconSizes;
}

export const AutocompleteActiveFilter = ({ id, name, onRemove, size = 'medium' }: Props) => {
  const handleOnRemove = useCallback(() => {
    onRemove(id);
  }, [onRemove, id]);

  return <ActiveFilter size={size} onClose={handleOnRemove} closeButton key={id} title={<>{name}</>} />;
};
