import React from 'react';

import { FormFieldProps } from 'pages/backoffice/pages/annotation/ui/form-fields/types';
import { AnnotationFormLabels } from 'pages/backoffice/pages/annotation/utils/form-fields-validation';
import { AnnotationFormFieldsNames } from 'pages/backoffice/pages/annotation/utils/form-fields-validation/types';
import { FormInputField } from 'shared/components/form/ui/FormInputField';

export const StartIxField = ({ errors = {}, register }: FormFieldProps) => {
  return (
    <FormInputField
      id={AnnotationFormFieldsNames.START_IX}
      label={AnnotationFormLabels[AnnotationFormFieldsNames.START_IX]}
      error={!!errors[AnnotationFormFieldsNames.START_IX]}
      fullWidth
      helperText={
        errors[AnnotationFormFieldsNames.START_IX] && <>{errors[AnnotationFormFieldsNames.START_IX]?.message}</>
      }
      {...register(AnnotationFormFieldsNames.START_IX)}
    />
  );
};
