import { zodResolver } from '@hookform/resolvers/zod';
import { FormHelperText, Stack } from '@mui/material';
import { forwardRef, Ref, useEffect, useImperativeHandle, useMemo, useRef } from 'react';
import { Controller, useForm } from 'react-hook-form';

import { Locales } from 'kognia/i18n/types';
import { UserForm, UserFormRef } from 'pages/backoffice/features/users/user-form/types/userForm';
import { getUserFormFields } from 'pages/backoffice/features/users/user-form/utils/getUserFormFields';
import { UserFormFieldsNames } from 'pages/backoffice/features/users/user-form/utils/userFormFieldNames';
import { userFormSchema } from 'pages/backoffice/features/users/user-form/utils/userFormSchema';
import { SelectClients } from 'pages/backoffice/ui/select-clients/SelectClients';
import { FormInputField } from 'shared/components/form/ui/FormInputField';
import { FormSelectField } from 'shared/components/form/ui/FormSelectField';
import { getTypeOptions } from 'shared/components/form/utils/getTypeOptions';

const UserFormLabels = {
  [UserFormFieldsNames.FIRST_NAME]: 'First name',
  [UserFormFieldsNames.LAST_NAME]: 'Last name',
  [UserFormFieldsNames.EMAIL]: 'Email',
  [UserFormFieldsNames.LOCALE]: 'Locale',
  [UserFormFieldsNames.CLIENT_IDS]: 'Environments',
};

interface UserFormProps {
  handleOnSubmit: (data: UserForm) => void;
  defaultValues?: UserForm;
  isEdit?: boolean;
  onDirtyChange?: (isDirty: boolean) => void;
}

export const UserFormFeature = forwardRef(
  ({ handleOnSubmit, defaultValues, isEdit, onDirtyChange }: UserFormProps, ref: Ref<UserFormRef>) => {
    const {
      control,
      register,
      watch,
      handleSubmit: handleFormSubmit,
      formState: { errors, isDirty },
    } = useForm<UserForm>({
      defaultValues: {
        [UserFormFieldsNames.CLIENT_IDS]: [],
        ...defaultValues,
      },
      resolver: zodResolver(userFormSchema),
    });

    const formRef = useRef<HTMLFormElement>(null);
    const formFields = useMemo(() => getUserFormFields({ isEdit }), [isEdit]);

    useEffect(() => {
      if (onDirtyChange) {
        onDirtyChange(isDirty);
      }
    }, [isDirty, onDirtyChange]);

    useImperativeHandle(
      ref,
      () => ({
        requestSubmit: () => formRef?.current?.requestSubmit(),
      }),
      [],
    );

    const localeOptions = getTypeOptions({
      options: Object.values(Locales).map((locale) => ({
        value: locale,
        label: locale,
      })),
    });

    return (
      <form ref={formRef} onSubmit={handleFormSubmit(handleOnSubmit)}>
        <Stack direction='column' spacing={2}>
          {formFields.map((field) => (
            <FormInputField
              key={field.id}
              id={field.id}
              label={UserFormLabels[field.id]}
              error={!!errors[field.id]}
              fullWidth
              disabled={field.disabled}
              helperText={errors[field.id] && <>{errors[field.id]?.message}</>}
              {...register(field.id)}
            />
          ))}

          <FormSelectField
            id={UserFormFieldsNames.LOCALE}
            label={UserFormLabels[UserFormFieldsNames.LOCALE]}
            options={localeOptions}
            value={watch(UserFormFieldsNames.LOCALE)}
            error={!!errors[UserFormFieldsNames.LOCALE]}
            helperText={errors[UserFormFieldsNames.LOCALE] && <>{errors[UserFormFieldsNames.LOCALE]?.message}</>}
            {...register(UserFormFieldsNames.LOCALE)}
          />

          <Controller
            render={({ field, fieldState: { error } }) => (
              <>
                <SelectClients clientIds={field.value} onChange={field.onChange} />
                {error && <FormHelperText error>{error.message}</FormHelperText>}
              </>
            )}
            name={UserFormFieldsNames.CLIENT_IDS}
            control={control}
          />
        </Stack>
      </form>
    );
  },
);

UserFormFeature.displayName = 'UserFormFeature';
