import { useTranslation } from 'react-i18next';

import { queryClient } from 'api/config';
import { useMutationRequest } from 'api/hooks/useMutationRequest';
import { updateTacticalAnalysesUrl } from 'api/routes';
import { DEFAULT_TACTICAL_ANALYSIS_QUERY_REF } from 'api/tactical-analysis/useTacticalAnalyses';
import { HTTPMethod } from 'api/types';

interface Args {
  tacticalAnalysisId: string;
  showOverlays: boolean;
  onSuccess?: (data: unknown) => void;
}

export const useUpdateTacticalAnalysisOverlays = () => {
  const { t } = useTranslation('api');

  const { mutate } = useMutationRequest({
    type: HTTPMethod.PATCH,
    errorMessage: t('api:use-update-tactical-analysis-overlays.error'),
    successMessage: t('api:use-update-tactical-analysis-overlays.success'),
    onSuccess: async () => {
      await queryClient.invalidateQueries({ queryKey: [DEFAULT_TACTICAL_ANALYSIS_QUERY_REF] });
    },
  });

  const toggleOverlays = ({ showOverlays, tacticalAnalysisId, onSuccess }: Args) => {
    mutate({ url: updateTacticalAnalysesUrl(tacticalAnalysisId), data: { showOverlays } }, { onSuccess });
  };

  return { toggleOverlays };
};
