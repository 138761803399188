import { z } from 'zod';

import { SeasonClientsFormFieldsNames } from 'pages/backoffice/pages/associate-season-with-clients/ui/season-clients-form/types';
import { clientSchema } from 'shared/types/user/types';

export const seasonClientsFormSchema = z.object({
  [SeasonClientsFormFieldsNames.id]: z.string().optional(),
  [SeasonClientsFormFieldsNames.seasonName]: z.string().optional(),
  [SeasonClientsFormFieldsNames.originalClient]: clientSchema,
  [SeasonClientsFormFieldsNames.seasonId]: z.string(),
  [SeasonClientsFormFieldsNames.clientIds]: z.array(z.string()).min(1),
});

export const seasonClientsUpdateFormSchema = seasonClientsFormSchema.extend({
  [SeasonClientsFormFieldsNames.seasonId]: z.string().optional(),
});
