import { IconDownload } from 'kognia-ui/icons/IconDownload';
import { IconShare } from 'kognia-ui/icons/IconShare';
import isEmpty from 'lodash/isEmpty';
import trim from 'lodash/trim';
import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { invalidatePlaylistsQuery } from 'api/playlist/useFetchPlaylists';
import { invalidatePlaylistQuery } from 'api/playlist/usePlaylist';
import { useUpdatePlaylist } from 'api/playlist/useUpdatePlaylist';
import { useIsBulkModeActive, useSetIsBulkModeActive } from 'entities/playlist/hooks/useIsBulkModeActive';
import { DownloadPlaylistItemsModal } from 'features/playlist/download-playlist-items-modal/DownloadPlaylistItemsModal';
import { DownloadCurrentPlaylistItemModal } from 'features/playlist/DownloadCurrentPlaylistItemModal';
import { UserPlaylistItemsList } from 'features/playlist/user-playlist-items-list/UserPlaylistItemsList';
import { AddMultipleClips } from 'pages/playlist-detail/components/add-multiple-clips/AddMultipleClips';
import { EditableText } from 'shared/components/editable-text/EditableText';
import SharePlaylistModal from 'shared/components/share-playlist-modal/SharePlaylistModal';
import { VerticalIconButton } from 'shared/components/vertical-icon-button/VerticalIconButton';
import { usePlaylistItems, useVideoPlayerActions } from 'shared/components/video-player';
import { PlaylistItemType } from 'shared/components/video-player/types';
import { useUser } from 'shared/contexts/app-state/hooks/useUser';
import { Playlist } from 'shared/types';
import { PlaylistLayoutWidget } from 'widgets/playlist/playlist-layout/PlaylistLayout.widget';
import { PlaylistItemTrimModal } from 'widgets/playlist/user-playlist/ui/PlaylistItemTrim';

const findVideoById = (videos: PlaylistItemType[], id: string) => {
  return videos.find((video) => video.id === id);
};

type Props = {
  playlist: Playlist;
};

export const UserPlaylist = ({ playlist }: Props) => {
  const { t } = useTranslation();
  const enabledBulkMode = useIsBulkModeActive(playlist.id);
  const setEnabledBulkMode = useSetIsBulkModeActive(playlist.id);

  const actions = useVideoPlayerActions();
  const playingItems = usePlaylistItems();
  const { updatePlaylistName, isPending: isPlaylistUpdating } = useUpdatePlaylist(playlist.id);

  const [trimmingPlaylistItem, setTrimmingPlaylistItem] = useState<string>('');
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isDownloadModalOpen, setIsDownloadModalOpen] = useState(false);
  const [isCurrentItemDownloadModalOpen, setIsCurrentItemDownloadModalOpen] = useState(false);
  const user = useUser();

  const closeModal = useCallback(() => setIsModalOpen(false), []);
  const openModal = useCallback(() => setIsModalOpen(true), []);

  const onUpdateName = useCallback(
    (newText: string) => {
      if (newText === playlist.name || trim(newText).length === 0) return;

      updatePlaylistName({
        name: newText,
        onSuccess: () => {
          invalidatePlaylistQuery();
          invalidatePlaylistsQuery();
        },
      });
    },
    [playlist.name, updatePlaylistName],
  );

  const canEditName = user.id === playlist.user.userId;

  const trimmingPlaylistItemItem =
    trimmingPlaylistItem !== '' ? findVideoById(playingItems, trimmingPlaylistItem) : null;

  const handleTrimmingPlaylistItem = useCallback(
    (trimmingPlaylistItem: string) => {
      setTrimmingPlaylistItem(trimmingPlaylistItem);

      trimmingPlaylistItem ? actions.handleStandBy() : actions.resumeStandBy();
    },
    [actions],
  );

  const handleDownloadModalOpen = useCallback(() => setIsDownloadModalOpen(true), []);
  const handleDownloadModalClose = useCallback(() => setIsDownloadModalOpen(false), []);

  const handleCloseDownloadCurrentItemModal = useCallback(() => {
    actions.resumeStandBy();
    setIsCurrentItemDownloadModalOpen(false);
  }, [actions]);
  return (
    <>
      <PlaylistLayoutWidget
        playlist={playlist}
        titleComponent={
          <EditableText
            canEdit={canEditName}
            originalText={playlist.name}
            onSubmit={onUpdateName}
            isSubmitting={isPlaylistUpdating}
          />
        }
        actionsListComponent={
          <>
            <VerticalIconButton Icon={IconShare} onClick={openModal}>
              {t('common:actions.share')}
            </VerticalIconButton>
            {!isEmpty(playingItems) ? (
              <VerticalIconButton Icon={IconDownload} onClick={handleDownloadModalOpen}>
                {t('common:actions.download')}
              </VerticalIconButton>
            ) : null}
          </>
        }
        timelineActionsComponent={<AddMultipleClips playlistId={playlist.id} />}
        itemsListComponent={
          <UserPlaylistItemsList
            playlist={playlist}
            setTrimmingPlaylistItem={handleTrimmingPlaylistItem}
            trimmingPlaylistItemId={trimmingPlaylistItem}
            enabledBulkMode={enabledBulkMode}
            setEnabledBulkMode={setEnabledBulkMode}
          />
        }
      />
      <>
        {trimmingPlaylistItemItem && (
          <PlaylistItemTrimModal
            open={Boolean(trimmingPlaylistItemItem)}
            playlistId={playlist.id}
            playlistItem={trimmingPlaylistItemItem}
            setTrimmingPlaylistItem={handleTrimmingPlaylistItem}
          />
        )}
        {isModalOpen && <SharePlaylistModal onCloseModal={closeModal} playlist={playlist} />}
        {isDownloadModalOpen ? (
          <DownloadPlaylistItemsModal
            downloadType={'playlist'}
            onClose={handleDownloadModalClose}
            playlistData={playlist}
          />
        ) : null}
        <DownloadCurrentPlaylistItemModal
          playlist={playlist}
          isOpen={isCurrentItemDownloadModalOpen}
          onClose={handleCloseDownloadCurrentItemModal}
        />
      </>
    </>
  );
};
