import { TaggingEvent } from 'api/tagging-tool/types';
import { RowGroup } from 'pages/tactical-analysis/api/use-tactical-analysis-data/generate-timeline-rows/types/row';
import {
  Timeline,
  TimelineTableBlock,
} from 'pages/tactical-analysis/api/use-tactical-analysis-data/generate-timeline-rows/types/timeline';
import { generateEpisodesRows } from 'pages/tactical-analysis/api/use-tactical-analysis-data/generate-timeline-rows/utils/generateEpisodesRows';
import { generateEventsRows } from 'pages/tactical-analysis/api/use-tactical-analysis-data/generate-timeline-rows/utils/generateEventsRows';
import { generateFiltersRow } from 'pages/tactical-analysis/api/use-tactical-analysis-data/generate-timeline-rows/utils/generateFiltersRow';
import { generateFullMatchTimelineBlockFromDuration } from 'pages/tactical-analysis/api/use-tactical-analysis-data/generate-timeline-rows/utils/generateFullMatchTimelineBlockFromDuration';
import { generateManualTagsRows } from 'pages/tactical-analysis/api/use-tactical-analysis-data/generate-timeline-rows/utils/generateManualTagsRows';
import { generateScenariosRows } from 'pages/tactical-analysis/api/use-tactical-analysis-data/generate-timeline-rows/utils/generateScenariosRows';
import { generateTacticsRows } from 'pages/tactical-analysis/api/use-tactical-analysis-data/generate-timeline-rows/utils/generateTacticsRows';
import { Episode } from 'shared/types';
import { RecordingsFilters } from 'shared/types/recording/types';

export const DEFAULT_TIMELINE_DATA: Timeline = {
  episodesRow: {
    id: 'episodes',
    type: 'episodes',
    isHidden: false,
    title: 'episode',
    clips: [],
  },
  filtersRow: undefined,
  rowGroups: [],
  scenariosRowGroup: undefined,
  scenariosIdsList: new Set(),
  tacticsIdsList: new Set(),
};

const transformEpisodeToTimelineTableBlock = (episode: Episode): TimelineTableBlock => ({
  id: episode.id,
  name: episode.name,
  startTime: episode.startTime,
  endTime: episode.endTime,
  matchSegment: episode.matchSegment,
});

export const generateTimelineRows = (
  episodes: Episode[],
  taggingEvents: TaggingEvent[],
  duration: number,
  appliedFilters: RecordingsFilters,
  filteredEpisodes: Episode[],
  recordingId: string,
): Timeline => {
  const timelineBlocks: TimelineTableBlock[] =
    episodes.length > 0
      ? episodes.map(transformEpisodeToTimelineTableBlock)
      : [generateFullMatchTimelineBlockFromDuration(duration)];

  const episodesRow = generateEpisodesRows(timelineBlocks, recordingId);
  const { scenariosRowGroup, scenariosIdsList } = generateScenariosRows(episodes, recordingId);
  const eventsRowGroup = generateEventsRows(episodes, recordingId);
  const { tacticsRowGroup, tacticsIdsList } = generateTacticsRows(episodes, recordingId);
  const manualTagsRowGroup = generateManualTagsRows(timelineBlocks, taggingEvents, recordingId);
  const filtersRow = generateFiltersRow(episodes, filteredEpisodes, appliedFilters, recordingId);
  const rowGroups: RowGroup[] = [tacticsRowGroup, eventsRowGroup, manualTagsRowGroup];

  return {
    filtersRow,
    scenariosRowGroup,
    scenariosIdsList,
    tacticsIdsList,
    rowGroups,
    episodesRow,
  };
};
