import { Typography } from '@mui/material';
import { fontWeight } from 'kognia-ui';
import { IconDelete } from 'kognia-ui/icons/IconDelete';
import { IconEdit } from 'kognia-ui/icons/IconEdit';
import React, { useCallback, useState } from 'react';

import { useInvalidateCoaches } from 'pages/backoffice/api/coaches/use-coaches/useCoaches';
import { useDeleteCoach } from 'pages/backoffice/api/coaches/useDeleteCoach';
import { useUpdateCoach } from 'pages/backoffice/api/coaches/useUpdateCoach';
import { CoachListItem } from 'pages/backoffice/pages/coaches/ui/coach-item/styled';
import { CoachModal } from 'pages/backoffice/pages/coaches/ui/coach-modal/CoachModal';
import { CoachPhoto } from 'pages/backoffice/pages/coaches/ui/coach-photo/CoachPhoto';
import { CoachPhotoSkeleton } from 'pages/backoffice/pages/coaches/ui/coach-photo-skeleton/CoachPhotoSkeleton';
import { Coach } from 'pages/backoffice/types/coaches';
import { MappingsModal } from 'pages/backoffice/ui/mappings-modal/MappingsModal';
import ConfirmDialog from 'shared/components/confirm-dialog/ConfirmDialog';
import KebabMenu, { MenuListOption } from 'shared/components/kebab-menu/KebabMenu';
import { Mapping } from 'shared/types/mapping';

interface Props {
  coach: Coach;
}

export const CoachItem = ({ coach }: Props) => {
  const { update, isPending } = useUpdateCoach();
  const invalidateCoaches = useInvalidateCoaches();
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [isConfirmDeleteDialogOpen, setIsConfirmDeleteDialogOpen] = useState(false);
  const { deleteCoach } = useDeleteCoach();
  const handleEditModalOpen = () => setIsEditModalOpen(true);
  const handleEditModalClose = () => setIsEditModalOpen(false);
  const [isMappingsModalOpen, setIsMappingsModalOpen] = useState(false);
  const handleMappingsModalOpen = () => setIsMappingsModalOpen(true);
  const handleMappingsModalClose = () => setIsMappingsModalOpen(false);

  const handleDeleteCoach = useCallback(() => {
    deleteCoach({
      coachId: coach.id,
    });
  }, [coach.id, deleteCoach]);

  const options: MenuListOption[] = [
    {
      displayText: 'Edit',
      icon: <IconEdit size='small' color='secondary' />,
      onClick: handleEditModalOpen,
    },
    {
      displayText: 'Mappings',
      icon: <IconEdit size='small' color='secondary' />,
      onClick: handleMappingsModalOpen,
    },
    {
      displayText: 'Delete',
      icon: <IconDelete size='small' color='secondary' />,
      onClick: () => setIsConfirmDeleteDialogOpen(true),
    },
  ];

  const handleUpdateMappings = useCallback(
    (mappings: Mapping[]) => {
      update({
        id: coach.id,
        data: {
          mappings: mappings
            .filter((mapping) => mapping.idProvider)
            .map((mapping) => ({
              provider: mapping.provider,
              coach_id_provider: mapping.idProvider,
            })),
        },
        onSuccess: () => {
          invalidateCoaches();
        },
      });
    },
    [update, coach.id, invalidateCoaches],
  );

  return (
    <>
      <CoachListItem key={coach.id}>
        {coach.photoUrl ? <CoachPhoto src={coach.photoUrl} /> : <CoachPhotoSkeleton small />}
        <Typography fontWeight={fontWeight['500']}>{coach.name}</Typography>
        <KebabMenu options={options} />
      </CoachListItem>
      {isEditModalOpen ? <CoachModal open={isEditModalOpen} onClose={handleEditModalClose} coach={coach} /> : null}

      <ConfirmDialog
        type={'warning'}
        title={`You sure you want delete "${coach.name}" coach?`}
        cancelLabel={'Cancel'}
        confirmLabel={'Delete'}
        onConfirm={handleDeleteCoach}
        isOpen={isConfirmDeleteDialogOpen}
        setIsOpen={setIsConfirmDeleteDialogOpen}
      />

      {isMappingsModalOpen ? (
        <MappingsModal
          id={coach.id}
          isLoading={isPending}
          mappings={coach.mappings}
          onClose={handleMappingsModalClose}
          title={`Coach mappings - ${coach.name}`}
          update={handleUpdateMappings}
        />
      ) : null}
    </>
  );
};
