import { FormControl, FormControlLabel, FormHelperText, Radio, RadioGroup } from '@mui/material';
import React, { useCallback } from 'react';
import { UseFormResetField } from 'react-hook-form';

import { EndIxField } from 'pages/backoffice/pages/annotation/ui/form-fields/EndIxField';
import { StartIxField } from 'pages/backoffice/pages/annotation/ui/form-fields/StartIxField';
import { FormFieldProps } from 'pages/backoffice/pages/annotation/ui/form-fields/types';
import {
  AnnotationFormFieldsNames,
  CreateTaskFormSchema,
} from 'pages/backoffice/pages/annotation/utils/form-fields-validation/types';

enum IndexTypeValues {
  FULL_RECORDING = 'fullRecording',
  PARTIAL_RECORDING = 'partialRecording',
}

interface Props extends FormFieldProps {
  resetField: UseFormResetField<CreateTaskFormSchema>;
}

export const IndexTypeGroup = ({ errors, register, resetField }: Props) => {
  const [indexType, setIndexType] = React.useState(IndexTypeValues.FULL_RECORDING);

  const handleRadioChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const { value } = event.target;
      setIndexType(value as IndexTypeValues);
      if (value === IndexTypeValues.FULL_RECORDING) {
        resetField(AnnotationFormFieldsNames.START_IX);
        resetField(AnnotationFormFieldsNames.END_IX);
      }
    },
    [resetField],
  );

  return (
    <>
      <FormControl sx={{ mb: 2 }}>
        <RadioGroup onChange={handleRadioChange} name='index-buttons-group' value={indexType}>
          <FormControlLabel value={IndexTypeValues.FULL_RECORDING} control={<Radio />} label='Full recording' />
          <FormControlLabel
            value={IndexTypeValues.PARTIAL_RECORDING}
            control={<Radio />}
            label='From start index to end index'
          />
        </RadioGroup>
      </FormControl>

      {indexType === IndexTypeValues.PARTIAL_RECORDING ? (
        <>
          <FormHelperText>Start index required. If End index is omitted, it runs to the end</FormHelperText>
          <StartIxField errors={errors} register={register} />
          <EndIxField errors={errors} register={register} />
        </>
      ) : null}
    </>
  );
};
