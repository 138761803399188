import { useEffect } from 'react';
import { generatePath, useNavigate, useParams } from 'react-router-dom';

import { routes } from 'kognia/router/routes';
import { useMetadataFixture } from 'pages/backoffice/api/fixtures/use-metadata-fixture/useMetadataFixture';
import { useSetFixtureData } from 'pages/backoffice/pages/fixtures/fixture-form/config';
import { MatchTeamTypes } from 'shared/types/match';
import { invariant } from 'shared/utils/invariant';

export const EditFixture = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  invariant(id);

  const {
    data: fixture,
    isLoading,
    isSuccess,
  } = useMetadataFixture({
    fixtureId: id,
  });

  const setHomeTeamMetaData = useSetFixtureData();

  useEffect(() => {
    if (fixture && isSuccess) {
      const homeTeam = {
        team: fixture.homeTeam,
        players: fixture.players.filter((p) => p.teamId === fixture.homeTeam.id),
      };
      const awayTeam = {
        team: fixture.awayTeam,
        players: fixture.players.filter((p) => p.teamId === fixture.awayTeam.id),
      };

      const fixtureInfo = {
        name: fixture.name,
        date: fixture.date,
        scoreHomeTeam: fixture.homeTeamScore,
        scoreOpponentTeam: fixture.awayTeamScore,
        duration: fixture.duration,
        season: fixture.season,
        venue: fixture.venue,
        stageId: fixture.stage.id,
        pitchLength: fixture.pitchLength,
        pitchWidth: fixture.pitchWidth,
        homeCoach: fixture.homeCoach,
        awayCoach: fixture.awayCoach,
      };

      setHomeTeamMetaData({
        [MatchTeamTypes.HOME]: homeTeam,
        [MatchTeamTypes.OPPONENT]: awayTeam,
        fixtureInfo,
      });

      navigate(generatePath(routes.BACKOFFICE_FIXTURE_FORM_STEP_1, { id: fixture.id }), { replace: true });
    }
  }, [isSuccess, fixture, isLoading, setHomeTeamMetaData, navigate]);

  if (isSuccess && !fixture) return <div>Fixture not found</div>;
  return null;
};
