import { Button, Grid, Paper } from '@mui/material';
import { IconPlus } from 'kognia-ui/icons/IconPlus';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { defaultRecordingsFiltersTactic } from 'api/recording/useEpisodesWithFilters/useTacticalAnalysesPlaylistItems';
import { RecordingTacticsPlayersFilter } from 'features/multi-analysis-filters/ui/recordings-filters-inside-an-episode/components/recordings-filters-inside-an-episode-tactics/components/recording-tactics-players-filter/RecordingTacticsPlayersFilter';
import ActiveFilters from 'shared/components/active-filters/ActiveFilters';
import FilterBlock from 'shared/components/filter-block/FilterBlock';
import FilterBlockClear from 'shared/components/filter-block-clear/FilterBlockClear';
import FilterBlockStatus from 'shared/components/filter-block-status/FilterBlockStatus';
import FilterColumn from 'shared/components/filter-column/FilterColumn';
import { MatchTeam, TacticsVariants } from 'shared/types';
import { RecordingFiltersTactic } from 'shared/types/recording/types';

interface Props {
  disabled?: boolean;
  filters: RecordingFiltersTactic[];
  onUpdate: (filters: RecordingFiltersTactic[]) => void;
  tactics: { id: string; name: string }[];
  teams: MatchTeam[];
  variant: TacticsVariants;
}

const checkAppliedFilters = (filters: RecordingFiltersTactic[]) => {
  return filters.some(
    (filter) => filter.playerIds.length || filter.tacticalFundamentalType.length || filter.teamIds.length,
  );
};

export const RecordingsFilterInsideAnEpisodeTactics = ({
  disabled,
  filters,
  onUpdate,
  tactics,
  teams,
  variant,
}: Props) => {
  const { t } = useTranslation();
  const isFiltersApplied = checkAppliedFilters(filters);

  const handleSetTacticFilters = useCallback(
    (filters: RecordingFiltersTactic[]) => {
      onUpdate(filters);
    },
    [onUpdate],
  );

  const clearFilters = useCallback(() => {
    handleSetTacticFilters([defaultRecordingsFiltersTactic(variant)]);
  }, [handleSetTacticFilters, variant]);

  const handleUpdateTacticalScenarioFilter = useCallback(
    (fundamental: string, idx: number) => {
      handleSetTacticFilters(
        Object.assign([], filters, { [idx]: { ...filters[idx], tacticalFundamentalType: fundamental } }),
      );
    },
    [filters, handleSetTacticFilters],
  );

  const handleUpdatePlayersFilter = useCallback(
    (players: string[], idx: number) => {
      handleSetTacticFilters(
        Object.assign([], filters, {
          [idx]: {
            ...filters[idx],
            playerIds: players,
          },
        }),
      );
    },
    [filters, handleSetTacticFilters],
  );

  const handleUpdateTeamsFilter = useCallback(
    (teams: string[]) => {
      const tacticFiltersWithTeams = filters.map(
        (filters): RecordingFiltersTactic => ({
          ...filters,
          teamIds: teams,
        }),
      );

      handleSetTacticFilters(tacticFiltersWithTeams);
    },
    [filters, handleSetTacticFilters],
  );

  const handleRemove = useCallback(
    (removeIdx: number) => {
      const tactics = [...filters];
      tactics.splice(removeIdx, 1);

      handleSetTacticFilters(tactics);
    },
    [filters, handleSetTacticFilters],
  );

  const handleAddFundamental = useCallback(() => {
    const firstRowTeamIds = filters[0].teamIds;
    handleSetTacticFilters([...filters, { ...defaultRecordingsFiltersTactic(variant), teamIds: firstRowTeamIds }]);
  }, [handleSetTacticFilters, filters, variant]);

  return (
    <div>
      <Grid container spacing={2} direction={'column'}>
        <Grid item>
          <FilterBlock>
            <FilterBlockStatus active />
            <ActiveFilters>
              <FilterColumn>
                <RecordingTacticsPlayersFilter
                  disabled={disabled}
                  appliedFilters={filters}
                  handleRemove={handleRemove}
                  onPlayersChange={handleUpdatePlayersFilter}
                  onScenarioChange={handleUpdateTacticalScenarioFilter}
                  onTeamsChange={handleUpdateTeamsFilter}
                  tactics={tactics}
                  teams={teams}
                  variant={variant}
                />
              </FilterColumn>
              {isFiltersApplied && (
                <FilterColumn expand>
                  <FilterBlockClear onClick={clearFilters} />
                </FilterColumn>
              )}
            </ActiveFilters>
            <Button
              color='secondary'
              startIcon={
                <Paper sx={{ borderRadius: '50%', display: 'flex' }}>
                  <IconPlus color='tertiary' />
                </Paper>
              }
              sx={{ alignItems: 'center', mt: 2 }}
              onClick={handleAddFundamental}
            >
              {t(`recordings:filters.scenarios-inside-episode.add-${variant}-fundamental`)}
            </Button>
          </FilterBlock>
        </Grid>
      </Grid>
    </div>
  );
};
