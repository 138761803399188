import { useMutationRequest } from 'api/hooks/useMutationRequest';
import { HTTPMethod } from 'api/types';
import { annotationApiUrls } from 'pages/backoffice/api/routes';
import { useAllowedBackofficeOrigin } from 'pages/backoffice/api/useAllowedBackofficeOrigin';
import { metadataApiErrorParser, MetadataApiErrorResponse } from 'pages/backoffice/api/utils';
import { PlayerSportMetadataApi } from 'shared/types/player/player';

type UpdatePlayerParams = Partial<Pick<PlayerSportMetadataApi, 'name' | 'mappings'>> & { image?: File };
interface RequestData {
  data: UpdatePlayerParams;
  playerId: string;
  onSuccess?: () => void;
}

export const useEditPlayer = () => {
  const { getIsAllowedBackofficeOrigin } = useAllowedBackofficeOrigin();
  const mutationRequest = useMutationRequest<unknown, unknown, MetadataApiErrorResponse>({
    type: HTTPMethod.PATCH,
    successMessage: 'Player updated',
    errorMessage: (data) => metadataApiErrorParser(data, 'Error updating player'),
  });

  const editPlayer = ({ playerId, data, onSuccess }: RequestData) => {
    if (!getIsAllowedBackofficeOrigin()) return;

    const payload: UpdatePlayerParams = {};
    const formData = new FormData();
    if (data.name) payload.name = data.name;
    if (data.mappings) payload.mappings = data.mappings;

    formData.append('player', JSON.stringify(payload));
    data.image && formData.append('player_image', data.image);

    mutationRequest.mutate(
      {
        url: `${annotationApiUrls.PLAYERS}/${playerId}`,
        data: formData,
      },
      {
        onSuccess,
      },
    );
  };

  return { ...mutationRequest, editPlayer };
};
