import { Box } from '@mui/material';
import { useSearchParams } from 'react-router-dom';

import { useFetchDownloadRequests } from 'api/download-manager/use-fetch-download-requests/useFetchDownloadRequests';
import { DownloadRequestsListFeature } from 'features/download-manager/download-requests-list/DownloadRequestsList.feature';
import Spinner from 'shared/components/spinner/Spinner';
import { PAGE_DOWNLOAD_REQUESTS_QUERY_KEY } from 'shared/constants/download-manager/downloadManagerQueryKeys';
import { useMarkDownloadRequestsAsViewed } from 'widgets/download-manager/hooks/useMarkDownloadRequestsAsViewed';
import { DownloadManagerPagination } from 'widgets/download-manager/ui/pagination/DownloadManagerPagination';
import { NoDownloadRequestsData } from 'widgets/download-manager/ui/pagination/no-download-requests-list/NoDownloadRequestsData';

const REFETCH_INTERVAL = 60_000;

const getPageValue = (value: string | null) => Number(value || '0');

export const DownloadManagerWidget = () => {
  const [searchParams, setSearchParams] = useSearchParams({ page: '0' });

  const { data, isPending, isPlaceholderData } = useFetchDownloadRequests({
    queryRef: PAGE_DOWNLOAD_REQUESTS_QUERY_KEY,
    queryParams: { page: Number(searchParams.get('page') || '0') },
    options: { gcTime: REFETCH_INTERVAL, staleTime: 0, refetchInterval: REFETCH_INTERVAL },
  });
  useMarkDownloadRequestsAsViewed(data);

  const handleIncreasePage = () => {
    setSearchParams((prev) => {
      return {
        ...prev,
        page: String(
          Math.min(
            getPageValue(prev.get('page')) + 1,
            data?.downloadRequests.page.totalPages || Number.MAX_SAFE_INTEGER,
          ),
        ),
      };
    });
  };

  const handleDecreasePage = () => {
    setSearchParams((prev) => {
      return {
        ...prev,
        page: String(Math.max(getPageValue(prev.get('page')) - 1, 0)),
      };
    });
  };

  return (
    <Box flexGrow={1} display='flex' flexDirection='column' justifyContent='space-between' gap={3}>
      {isPending || isPlaceholderData ? (
        <Box flexGrow={1} display='flex' flexDirection='column' justifyContent='center' alignItems='center'>
          <Spinner />
        </Box>
      ) : (
        <>
          {data?.downloadRequests.content.length ? (
            <DownloadRequestsListFeature data={data.downloadRequests.content} />
          ) : (
            <NoDownloadRequestsData />
          )}
        </>
      )}

      {data && data.downloadRequests.content.length ? (
        <Box display='flex' alignItems='center' justifyContent='center'>
          <DownloadManagerPagination
            page={getPageValue(searchParams.get('page'))}
            totalPages={data.downloadRequests.page.totalPages}
            onClickPrev={handleDecreasePage}
            onClickNext={handleIncreasePage}
          />
        </Box>
      ) : null}
    </Box>
  );
};
