import { useQueryClient } from '@tanstack/react-query';

import { useInfinityQuery } from 'api/hooks/useInfinityQuery';
import { InfinityQueryResult } from 'api/hooks/useInfinityQuery/types';
import { transformMetadataRecordingsFromApiResponse } from 'pages/backoffice/api/recordings/use-metadata-recordings/transformer';
import {
  MetadataRecordingsApiFilters,
  MetadataRecordingsApiResponse,
} from 'pages/backoffice/api/recordings/use-metadata-recordings/types';
import { getRecordingsUrl } from 'pages/backoffice/api/routes';
import { useAllowedBackofficeOrigin } from 'pages/backoffice/api/useAllowedBackofficeOrigin';
import { MetadataRecording } from 'pages/backoffice/types/recordings';
import { SortDirection } from 'shared/types/filters/types';

const QUERY_KEY = 'metadata-recordings';

export const useMetadataRecordings = (): InfinityQueryResult<MetadataRecording, MetadataRecordingsApiFilters> => {
  const { isAllowedBackofficeOrigin } = useAllowedBackofficeOrigin();

  return useInfinityQuery<MetadataRecording, MetadataRecordingsApiFilters, MetadataRecordingsApiResponse>({
    generateUrl: getRecordingsUrl,
    key: QUERY_KEY,
    options: {
      size: 10,
      sort: 'name',
      sortDirection: SortDirection.DESC,
    },
    transformer: transformMetadataRecordingsFromApiResponse,
    queryOptions: {
      enabled: isAllowedBackofficeOrigin,
    },
  });
};

export const useInvalidateMetadataRecordings = () => {
  const queryClient = useQueryClient();

  return () => queryClient.invalidateQueries({ queryKey: [QUERY_KEY] });
};
