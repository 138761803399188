import { Box, Button, Grid, Stack, TextField, Typography } from '@mui/material';
import { IconPlus } from 'kognia-ui/icons/IconPlus';
import { ChangeEvent, useCallback } from 'react';

import { useClients } from 'api/backoffice/client/hooks/use-clients/useClients';
import { routes } from 'kognia/router/routes';
import { EnvironmentItemColumn } from 'pages/backoffice/pages/environments/components/environment-item-column/EnvironmentItemColumn';
import { EnvironmentsList } from 'pages/backoffice/pages/environments/components/environments-list/EnvironmentsList';
import { BackofficeContentContainer } from 'pages/backoffice/ui/BackofficeContentContainer';
import { ListHeader } from 'shared/components/list/ui/list-header/ListHeader';
import NotFound from 'shared/components/not-found/NotFound';
import Pagination from 'shared/components/pagination/Pagination';
import { useRedirectTo } from 'shared/components/sidebar-layout/ui/sidebar-menu/hooks/use-redirect-to';
import Spinner from 'shared/components/spinner/Spinner';
import { useDebounce } from 'shared/hooks/use-debounce/useDebounce';

export const Environments = () => {
  const { items, page, fetchNextPage, isFetching, setFilters } = useClients();
  const redirectTo = useRedirectTo();

  const handleCreateEnvironment = useCallback(() => redirectTo(routes.BACKOFFICE_CREATE_ENVIRONMENT), [redirectTo]);

  const handleSearchChange = useDebounce((event: ChangeEvent<HTMLInputElement>) => {
    if (event.target.value.length === 1) return;
    setFilters({ name: event.target.value });
  });

  return (
    <BackofficeContentContainer>
      <Stack direction='row' alignItems='center' justifyContent='space-between' marginBottom={2}>
        <Typography variant='h4'>Environments</Typography>
        <Button
          variant='contained'
          size='large'
          startIcon={<IconPlus size='small' sx={{ color: 'common.white' }} />}
          onClick={handleCreateEnvironment}
        >
          New environment
        </Button>
      </Stack>

      <Stack marginBottom={2} justifyContent='flex-end' direction='row'>
        <TextField size='small' placeholder='Search environments' onChange={handleSearchChange} />
      </Stack>

      <ListHeader>
        <Grid container>
          <EnvironmentItemColumn item xs={3}>
            <Typography variant='body2'>Name</Typography>
          </EnvironmentItemColumn>
          <EnvironmentItemColumn item xs={3}>
            <Typography variant='body2'>ID</Typography>
          </EnvironmentItemColumn>
          <EnvironmentItemColumn item xs={3}>
            <Typography variant='body2'>City</Typography>
          </EnvironmentItemColumn>
          <EnvironmentItemColumn item xs={2}>
            <Typography variant='body2'>Country</Typography>
          </EnvironmentItemColumn>
          <EnvironmentItemColumn item xs={1}>
            <Typography variant='body2'>Action</Typography>
          </EnvironmentItemColumn>
        </Grid>
      </ListHeader>
      {!isFetching && items.length === 0 ? (
        <Box marginTop={4}>
          <NotFound header={'No results'} />
        </Box>
      ) : null}
      {isFetching ? <Spinner isFullPage /> : null}
      {items.length > 0 ? (
        <Box sx={{ py: 1 }}>
          <EnvironmentsList environments={items} />
        </Box>
      ) : null}
      {page ? (
        <Pagination
          total={page.totalElements}
          displayed={items.length}
          onShowMore={fetchNextPage}
          getStatsText={(displayed, total) => `${displayed} of ${total} environments`}
        />
      ) : null}
    </BackofficeContentContainer>
  );
};
