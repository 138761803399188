import { Box, Typography, Stack, IconButton, Link } from '@mui/material';
import { fontSizes } from 'kognia-ui';
import { IconDownload } from 'kognia-ui/icons/IconDownload';
import { IconTime } from 'kognia-ui/icons/IconTime';
import { IconWarning } from 'kognia-ui/icons/IconWarning';
import { useTranslation } from 'react-i18next';

import Spinner from 'shared/components/spinner/Spinner';
import { DownloadRequest } from 'shared/types/download-manager/downloadRequests';
import { convertFromISO8601ToSeconds } from 'shared/utils/convert-from-iso-8601-to-seconds/convertFromISO8601ToSeconds';
import { secondsAsDuration } from 'shared/utils/seconds-as-duration';

export interface DownloadManagerItemProps {
  data: DownloadRequest;
}

export const DownloadItem = ({ data }: DownloadManagerItemProps) => {
  const { t } = useTranslation();
  const isDisabledDownloadButton = data.status === 'processing' || data.status === 'error';

  return (
    <Box
      display='flex'
      alignItems='center'
      justifyContent='space-between'
      flexWrap='wrap'
      width='100%'
      padding={({ spacing }) => spacing(1, 2)}
      bgcolor='white'
      border='1px solid'
      borderColor={data.status !== 'processing' && !data.viewed ? 'info.main' : 'transparent'}
      borderRadius={1}
      data-testid='download-manager-item-box'
    >
      <Typography
        component='span'
        maxWidth={{ xs: '104px', md: '164px', lg: '392px', xl: '640px' }}
        fontSize={fontSizes.default}
        fontWeight='fontWeightMedium'
        whiteSpace='nowrap'
        overflow='hidden'
        textOverflow='ellipsis'
      >
        {data.name}
      </Typography>

      <Stack direction='row' alignItems='center' gap={2}>
        {data.status === 'processing' && (
          <Stack direction='row' alignItems='center' gap={0.5} minWidth='96px'>
            <Spinner size='small' color='info' />
            <Typography component='span' fontSize={fontSizes.small}>
              {t('download-manager:status.preparing')}
            </Typography>
          </Stack>
        )}

        {data.status === 'error' && (
          <Stack direction='row' alignItems='center' gap={0.5} minWidth='96px'>
            <IconWarning size='small' color='error' />
            <Typography component='span' fontSize={fontSizes.small}>
              {t('download-manager:status.failed')}
            </Typography>
          </Stack>
        )}

        <Stack direction='row' alignItems='center' justifyContent='space-between' gap={1} minWidth='96px'>
          <Stack direction='row' alignItems='center'>
            <IconTime size='small' color='secondary' />
            <Typography
              component='span'
              fontSize={fontSizes.small}
              lineHeight={1}
              color='inherit'
              fontWeight='fontWeightLight'
            >
              {secondsAsDuration(convertFromISO8601ToSeconds(data.duration), false)}
            </Typography>
          </Stack>

          <IconButton
            component={Link}
            download={data.name}
            href={data.downloadUrl}
            size='small'
            sx={{
              padding: 0,
              '&:after': {
                display: 'none',
                width: 0,
              },
            }}
            disabled={isDisabledDownloadButton}
            data-testid='download-manager-item-icon-button'
          >
            <IconDownload size='small' sx={{ color: isDisabledDownloadButton ? 'secondary.light' : 'text.primary' }} />
          </IconButton>
        </Stack>
      </Stack>
    </Box>
  );
};
