import { Box, Button, Stack, TextField, Typography } from '@mui/material';
import { fontSizes } from 'kognia-ui';
import { IconPlus } from 'kognia-ui/icons/IconPlus';
import { ChangeEvent, useCallback, useState } from 'react';

import { useVenues } from 'pages/backoffice/api/venues/use-venues/useVenues';
import { VenueModal } from 'pages/backoffice/pages/venues/components/venue-modal';
import { VenuesList } from 'pages/backoffice/pages/venues/components/venues-list';
import { VenueListHeaderGrid } from 'pages/backoffice/pages/venues/styled';
import { BackofficeContentContainer } from 'pages/backoffice/ui/BackofficeContentContainer';
import { ListSkeleton } from 'pages/backoffice/ui/ListSkeleton';
import { ListHeader } from 'shared/components/list/ui/list-header/ListHeader';
import NotFound from 'shared/components/not-found/NotFound';
import Pagination from 'shared/components/pagination/Pagination';
import { useDebounce } from 'shared/hooks/use-debounce/useDebounce';

export const VenuesPage = () => {
  const [isCreateModalOpen, setIsCreateModalOpen] = useState(false);
  const { items, page, fetchNextPage, isFetching, isPending, setFilters } = useVenues();

  const handleCreateModalOpen = useCallback(() => setIsCreateModalOpen(true), []);
  const handleCreateModalClose = useCallback(() => setIsCreateModalOpen(false), []);

  const handleSearchChange = useDebounce((event: ChangeEvent<HTMLInputElement>) => {
    setFilters({ name: event.target.value });
  });

  return (
    <BackofficeContentContainer>
      <Stack direction='row' alignItems='center' justifyContent='space-between' marginBottom={2}>
        <Typography variant='h4'>Venues</Typography>
        <Button
          variant='contained'
          size='large'
          startIcon={<IconPlus size='small' sx={{ color: 'common.white' }} />}
          onClick={handleCreateModalOpen}
        >
          New venue
        </Button>
      </Stack>

      <Stack marginBottom={2} justifyContent='flex-end' direction='row'>
        <TextField size='small' placeholder='Search venues' onChange={handleSearchChange} />
      </Stack>

      <ListHeader>
        <VenueListHeaderGrid>
          <Typography variant='body1' fontSize={fontSizes.default} fontWeight={'fontWeightMedium'}>
            Name
          </Typography>
          <Typography variant='body1' fontSize={fontSizes.default} fontWeight={'fontWeightMedium'}>
            City
          </Typography>
          <Typography variant='body1' fontSize={fontSizes.default} fontWeight={'fontWeightMedium'}>
            Country
          </Typography>
          <div>&nbsp;</div>
        </VenueListHeaderGrid>
      </ListHeader>
      {!isPending && items.length === 0 ? (
        <Box marginTop={4}>
          <NotFound header={'No results'} />
        </Box>
      ) : null}
      {isPending ? <ListSkeleton /> : <VenuesList venues={items} />}
      {page ? (
        <Pagination
          total={page.totalElements}
          displayed={items.length}
          onShowMore={fetchNextPage}
          loading={isFetching}
          getStatsText={(displayed, total) => `${displayed} of ${total} venues`}
        />
      ) : null}
      <VenueModal open={isCreateModalOpen} onClose={handleCreateModalClose} />
    </BackofficeContentContainer>
  );
};
