import { TacticId } from '@kognia/tactical-analysis-data';
import { Box, Typography } from '@mui/material';
import { fontWeight } from 'kognia-ui';
import { IconOverlays } from 'kognia-ui/icons/IconOverlays';
import React, { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { OverlaySelectorContent } from 'shared/components/overlay-selector-panel/overlay-selector-content';
import { ClosedPanelButton, IconChevron, Panel, PanelTitle } from 'shared/components/overlay-selector-panel/styled';
import Spinner from 'shared/components/spinner/Spinner';
import {
  useIsOverlayPanelOpen,
  useSetIsOverlayPanelOpen,
} from 'shared/components/video-player/video-player-component/hooks/overlay-panel';

type Props = {
  id: string;
  availableTactics: TacticId[] | undefined;
  initialSelectedTactics: TacticId[] | undefined;
  frameTactics: TacticId[];
  onTacticsChange: (tactics: TacticId[]) => void;
  onIsOverlayPanelOpenChange?: (isOpen: boolean) => void;
  isLoading: boolean;
};

export const MAX_PANEL_WIDTH = 354;
export const MIN_PANEL_WIDTH = 74;

export const OverlaySelectorPanel = ({
  id,
  availableTactics,
  frameTactics,
  initialSelectedTactics,
  onTacticsChange,
  isLoading,
  onIsOverlayPanelOpenChange,
}: Props) => {
  const { t } = useTranslation();
  const isOverlayPanelOpen = useIsOverlayPanelOpen(id);
  const setIsOverlayPanelOpenState = useSetIsOverlayPanelOpen(id, onIsOverlayPanelOpenChange);
  const handlePanelToggle = useCallback(() => {
    setIsOverlayPanelOpenState(!isOverlayPanelOpen);
  }, [setIsOverlayPanelOpenState, isOverlayPanelOpen]);

  const panelWidth = useMemo(() => (isOverlayPanelOpen ? MAX_PANEL_WIDTH : MIN_PANEL_WIDTH), [isOverlayPanelOpen]);

  return (
    <Panel gap={1} isOpen={isOverlayPanelOpen} style={{ height: '100%', width: `${panelWidth}px` }}>
      <PanelTitle direction={'row'} gap={1} alignItems={'center'}>
        {isOverlayPanelOpen ? (
          <>
            <Box onClick={handlePanelToggle} sx={{ cursor: 'pointer' }}>
              <IconChevron isOpen={isOverlayPanelOpen} isButton size='small' />
              <IconOverlays size='small' />
            </Box>
            <Typography sx={{ fontWeight: fontWeight['500'] }}>
              {t('video-player:overlays.overlays-settings')}
            </Typography>
          </>
        ) : (
          <ClosedPanelButton onClick={handlePanelToggle}>
            <IconChevron isOpen={isOverlayPanelOpen} isButton size='small' />
            <IconOverlays size='small' />
          </ClosedPanelButton>
        )}
      </PanelTitle>
      {isLoading ? (
        <Box sx={{ padding: 2, display: 'flex', justifyContent: 'center' }}>
          <Spinner />
        </Box>
      ) : null}
      {availableTactics && initialSelectedTactics && isOverlayPanelOpen && !isLoading ? (
        <OverlaySelectorContent
          id={`${id}-overlay-selector-content`}
          width={MAX_PANEL_WIDTH}
          isOpen={isOverlayPanelOpen}
          availableTactics={availableTactics}
          frameTactics={frameTactics}
          initialSelectedTactics={initialSelectedTactics}
          onTacticsChange={onTacticsChange}
        />
      ) : null}
    </Panel>
  );
};
