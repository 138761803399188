import { useAtomValue, useSetAtom } from 'jotai';
import React, { useEffect } from 'react';

import { FEATURE_FLAG } from 'api/user/use-fetch-feature-flags';
import { initHotjar } from 'kognia/analytics/initHotjar';
import { registerTrackedUser } from 'kognia/error-tracking/register-user';
import { useSetUserAndClientIdForMetrics } from 'kognia/metrics/hooks/use-set-user-and-client-id-for-metrics';
import { APP_COLLECT_METRICS } from 'kognia/metrics/utils';
import { AppLoading } from 'shared/components/app-loading/AppLoading';
import { currentClientIdAtom } from 'shared/contexts/app-state/atoms';
import { useAppData } from 'shared/contexts/app-state/hooks/useAppData';
import { useFeatureFlag } from 'shared/contexts/app-state/hooks/useFeatureFlag';
import { chooseInitialClientId } from 'shared/utils/chooseInitialClientId';
import { findClientOrThrow } from 'shared/utils/findClientOrThrow';
import { getBrandingFromClient } from 'shared/utils/getBrandingFromClient';
import { updateFavicon } from 'shared/utils/updateFavicon';

type AppStateProviderProps = { children: React.ReactNode };

export function AppStateProvider({ children }: AppStateProviderProps) {
  const setUserAndClientIdForMetrics = useSetUserAndClientIdForMetrics();
  const setCurrentClientId = useSetAtom(currentClientIdAtom);
  const currentClientId = useAtomValue(currentClientIdAtom);
  const hotjarFeatureFlag = useFeatureFlag(FEATURE_FLAG.APP_HOTJAR);

  const { data, isSuccess, isAppSettled } = useAppData();

  useEffect(() => {
    if (isSuccess && data?.user) {
      const initialCurrentClientId = chooseInitialClientId(data.user.clients);
      APP_COLLECT_METRICS && setUserAndClientIdForMetrics(initialCurrentClientId, data.user);

      if (currentClientId) {
        updateFavicon(getBrandingFromClient(findClientOrThrow(data.user.clients, initialCurrentClientId)).faviconUrl);
      }

      setCurrentClientId(initialCurrentClientId);
      hotjarFeatureFlag && initHotjar(data.user, initialCurrentClientId);
      registerTrackedUser({ user: data.user, clientId: initialCurrentClientId });
    }
  }, [currentClientId, data.user, hotjarFeatureFlag, isSuccess, setCurrentClientId, setUserAndClientIdForMetrics]);

  return (
    <>
      <AppLoading isLoaded={isAppSettled} />
      {children}
    </>
  );
}
