import { Stack, Typography } from '@mui/material';
import { fontWeight } from 'kognia-ui';
import { TeamAvatar } from 'kognia-ui/components/avatar/TeamAvatar';
import { IconEdit } from 'kognia-ui/icons/IconEdit';
import { IconTimeline } from 'kognia-ui/icons/IconTimeline';
import { useCallback, useState } from 'react';

import { useInvalidateTeams } from 'pages/backoffice/api/teams/use-teams/useTeams';
import { useUpdateTeam } from 'pages/backoffice/api/teams/use-update-team/useUpdateTeam';
import { EditTeamModal } from 'pages/backoffice/pages/teams/ui/EditTeamModal';
import { TeamListItem } from 'pages/backoffice/pages/teams/ui/team-item/styled';
import { TeamDetailsModal } from 'pages/backoffice/pages/teams/ui/TeamDetailsModal';
import { MappingsModal } from 'pages/backoffice/ui/mappings-modal/MappingsModal';
import KebabMenu, { MenuListOption } from 'shared/components/kebab-menu/KebabMenu';
import { Mapping } from 'shared/types/mapping';
import { Team } from 'shared/types/team/team';
import { Countries } from 'shared/utils/countries';

interface Props {
  team: Team;
}

export const TeamItem = ({ team }: Props) => {
  const { editTeam, isPending } = useUpdateTeam();
  const invalidateTeams = useInvalidateTeams();
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [isDetailsModalOpen, setIsDetailsModalOpen] = useState(false);
  const [isMappingsModalOpen, setIsMappingsModalOpen] = useState(false);
  const handleEditModalOpen = () => setIsEditModalOpen(true);
  const handleEditModalClose = () => setIsEditModalOpen(false);
  const handleDetailsModalOpen = () => setIsDetailsModalOpen(true);
  const handleDetailsModalClose = () => setIsDetailsModalOpen(false);
  const handleMappingsModalOpen = () => setIsMappingsModalOpen(true);
  const handleMappingsModalClose = () => setIsMappingsModalOpen(false);

  const options: MenuListOption[] = [
    {
      displayText: 'Edit',
      icon: <IconEdit size='small' color='secondary' />,
      onClick: handleEditModalOpen,
    },
    {
      displayText: 'Mappings',
      icon: <IconEdit size='small' color='secondary' />,
      onClick: handleMappingsModalOpen,
    },
    {
      displayText: 'Details',
      icon: <IconTimeline size='small' color='secondary' />,
      onClick: handleDetailsModalOpen,
    },
  ];

  const handleUpdateMappings = useCallback(
    (mappings: Mapping[]) => {
      editTeam({
        id: team.id,
        data: {
          mappings: mappings
            .filter((mapping) => mapping.idProvider)
            .map((mapping) => ({
              provider: mapping.provider,
              team_id_provider: mapping.idProvider,
            })),
        },
        onSuccess: () => {
          invalidateTeams();
        },
      });
    },
    [editTeam, team, invalidateTeams],
  );

  return (
    <>
      <TeamListItem key={team.id}>
        <Stack direction={'row'} spacing={2} alignItems={'center'}>
          <TeamAvatar src={team.logoUrl ?? undefined} name={team.name} />
          <Typography fontWeight={fontWeight['500']}>{team.name}</Typography>
        </Stack>
        <Typography>{team.abbreviation}</Typography>
        <Typography>
          {Countries.find((item) => item.code.toUpperCase() === team.countryCode?.toUpperCase())?.name || ''}
        </Typography>
        <KebabMenu options={options} />
      </TeamListItem>
      {isEditModalOpen ? (
        <EditTeamModal open={isEditModalOpen} onClose={handleEditModalClose} team={team} teamId={team.id} />
      ) : null}
      {isDetailsModalOpen && team?.fixtures ? (
        <TeamDetailsModal teamMappings={team.mappings} teamFixtures={team.fixtures} onClose={handleDetailsModalClose} />
      ) : null}
      {isMappingsModalOpen ? (
        <MappingsModal
          id={team.id}
          isLoading={isPending}
          mappings={team.mappings}
          onClose={handleMappingsModalClose}
          title={`Team mappings - ${team.name}`}
          update={handleUpdateMappings}
        />
      ) : null}
    </>
  );
};
