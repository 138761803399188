import { useTranslation } from 'react-i18next';

import { useFetchTaggingRecordings } from 'api/recording/useFetchTaggingRecordings';
import { AlignmentSourceList } from 'pages/tagging-tool-aling-sources/components/alignment-source-list/AlignmentSourceList';
import Container from 'shared/components/container/Container';
import NotFound from 'shared/components/not-found/NotFound';
import Spinner from 'shared/components/spinner/Spinner';
import usePageStateMachine from 'shared/hooks/use-page-state-machine';
import { Recording } from 'shared/types/recording/types';

export const AlignmentSourceListContainer = () => {
  const { t } = useTranslation();
  const { data, isPageLoading, isPageReady, isPageMissingData } = usePageStateMachine<Recording[]>(() =>
    useFetchTaggingRecordings(),
  );

  return (
    <Container>
      {isPageLoading && <Spinner isFullPage />}
      {isPageMissingData && <NotFound header={t('tagging-tool:not-found')} />}
      {isPageReady && data && <AlignmentSourceList recordings={data} />}
    </Container>
  );
};
