import { KeypadListScreen } from 'pages/tagging-tool-keypad-list/components/keypad-list/KeypadListScreen';
import Container from 'shared/components/container/Container';

export const KeypadListPage = () => {
  return (
    <Container>
      <KeypadListScreen />
    </Container>
  );
};
