import { Box, Button } from '@mui/material';
import { IconFolder } from 'kognia-ui/icons/IconFolder';
import { IconPlaylist } from 'kognia-ui/icons/IconPlaylist';
import { IconTag } from 'kognia-ui/icons/IconTag';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { generatePath, useNavigate } from 'react-router-dom';

import { TaggingEvent } from 'api/tagging-tool/types';
import { routes } from 'kognia/router/routes';
import Filters from 'pages/tactical-analysis/components/tactical-analysis/filters';
import ExportButton from 'pages/tactical-analysis/components/tactical-analysis/filters-bar/export-button/ExportButton';
import { FiltersBarContainer } from 'pages/tactical-analysis/components/tactical-analysis/filters-bar/FiltersBar.styled';
import ZoomRange from 'pages/tactical-analysis/components/tactical-analysis/zoom-range';
import { useTacticalAnalysisEpisodes } from 'pages/tactical-analysis/hooks/use-tactical-analysis-episodes';
import { useTimelineIsMultiselectModeActive } from 'pages/tactical-analysis/hooks/use-timeline-is-multiselect-mode-active';
import { useTimelineTableData } from 'pages/tactical-analysis/hooks/use-timeline-table-data';
import { ButtonOutlined } from 'shared/components/button-outlined/ButtonOutlined';
import { MatchTeams } from 'shared/types';
import { VideoSource } from 'shared/types/recording/types';

interface Props {
  isLoading?: boolean;
  recordingId: string;
  teams: MatchTeams;
  videoSources: VideoSource[];
  tags: TaggingEvent[];
}

export const FiltersBar = ({ isLoading = false, recordingId, teams, videoSources, tags }: Props): JSX.Element => {
  const episodes = useTacticalAnalysisEpisodes(recordingId);
  const { t } = useTranslation();
  const navigate = useNavigate();

  const timelineTableData = useTimelineTableData(recordingId);
  const { isMultiselectModeActive, setIsMultiselectModeActive } = useTimelineIsMultiselectModeActive(recordingId);

  if (timelineTableData.rowGroups.length === 0) return <></>;

  return (
    <>
      <FiltersBarContainer>
        <Box display='flex' alignItems='center' bgcolor='common.white'>
          {episodes.length > 0 && !isLoading && (
            <Filters episodeCount={episodes.length} episodes={episodes} recordingId={recordingId} teams={teams} />
          )}
        </Box>
        <Box display='flex' alignItems='center' gap={1} height='100%'>
          <Button
            color={isMultiselectModeActive ? 'primary' : 'inherit'}
            sx={{ whiteSpace: 'nowrap', fontWeight: 'fontWeightRegular' }}
            id='add-to-playlist-button'
            startIcon={<IconFolder size='small' color={isMultiselectModeActive ? 'primary' : undefined} />}
            onClick={() => setIsMultiselectModeActive(!isMultiselectModeActive)}
          >
            {t('timeline:actions.add-to-playlist')}
          </Button>
          <ExportButton
            recordingId={recordingId}
            tacticalAnalysisId={episodes.length > 0 ? episodes[0].tacticalAnalysisId : undefined}
            videoSources={videoSources}
            hasTags={tags.length > 0}
          />
          <ButtonOutlined
            startIcon={<IconPlaylist size='small' color='secondary' />}
            onClick={() => navigate(generatePath(routes.RECORDING_PLAYLISTS, { id: recordingId }))}
            data-testid='timeline-playlists-button'
          >
            {t('timeline:actions.playlists')}
          </ButtonOutlined>
          <ButtonOutlined
            startIcon={<IconTag size='small' color='secondary' />}
            onClick={() => navigate(generatePath(routes.TAGGING_TOOL_TAG, { recordingId }))}
          >
            {t('timeline:actions.add-tags')}
          </ButtonOutlined>
          <ZoomRange />
        </Box>
      </FiltersBarContainer>
    </>
  );
};
