import { Typography } from '@mui/material';
import { fontWeight } from 'kognia-ui';
import { PlayerAvatar } from 'kognia-ui/components/avatar/PlayerAvatar';
import { IconEdit } from 'kognia-ui/icons/IconEdit';
import { IconTimeline } from 'kognia-ui/icons/IconTimeline';
import { useCallback, useState } from 'react';

import { useInvalidatePlayers } from 'pages/backoffice/api/players/use-players/usePlayers';
import { useEditPlayer } from 'pages/backoffice/api/players/useEditPlayer';
import { EditPlayerModal } from 'pages/backoffice/pages/players/components/edit-player-modal';
import { PlayerDetailsModal } from 'pages/backoffice/pages/players/components/player-details-modal';
import { PlayersListItem } from 'pages/backoffice/pages/players/components/players-list/styled';
import { MappingsModal } from 'pages/backoffice/ui/mappings-modal/MappingsModal';
import KebabMenu, { MenuListOption } from 'shared/components/kebab-menu/KebabMenu';
import { Mapping } from 'shared/types/mapping';
import { Player } from 'shared/types/player/player';

interface Props {
  player: Player;
}

export const PlayerItem = ({ player }: Props) => {
  const { editPlayer, isPending } = useEditPlayer();
  const invalidatePlayers = useInvalidatePlayers();
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [isDetailsModalOpen, setIsDetailsModalOpen] = useState(false);
  const [isMappingModalOpen, setMappingModalOpen] = useState(false);
  const handleEditModalOpen = () => setIsEditModalOpen(true);
  const handleEditModalClose = () => setIsEditModalOpen(false);
  const handleDetailsModalOpen = () => setIsDetailsModalOpen(true);
  const handleDetailsModalClose = () => setIsDetailsModalOpen(false);
  const handleMappingModalOpen = () => setMappingModalOpen(true);
  const handleMappingsModalClose = () => setMappingModalOpen(false);

  const options: MenuListOption[] = [
    {
      displayText: 'Edit',
      icon: <IconEdit size='small' color='secondary' />,
      onClick: handleEditModalOpen,
    },
    {
      displayText: 'Mappings',
      icon: <IconEdit size='small' color='secondary' />,
      onClick: handleMappingModalOpen,
    },
    {
      displayText: 'Details',
      icon: <IconTimeline size='small' color='secondary' />,
      onClick: handleDetailsModalOpen,
    },
  ];

  const handleUpdateMappings = useCallback(
    (mappings: Mapping[]) => {
      editPlayer({
        playerId: player.id,
        data: {
          mappings: mappings
            .filter((mapping) => mapping.idProvider)
            .map((mapping) => ({
              provider: mapping.provider,
              player_id_provider: mapping.idProvider,
            })),
        },
        onSuccess: () => invalidatePlayers(),
      });
    },
    [editPlayer, player.id, invalidatePlayers],
  );

  return (
    <>
      <PlayersListItem key={player.id}>
        <PlayerAvatar src={player.photoUrl} />
        <Typography marginLeft={2} fontWeight={fontWeight['500']}>
          {player.name}
        </Typography>
        <KebabMenu options={options} />
      </PlayersListItem>
      {isEditModalOpen ? (
        <EditPlayerModal open={isEditModalOpen} onClose={handleEditModalClose} player={player} playerId={player.id} />
      ) : null}
      {isDetailsModalOpen && player?.fixtures ? (
        <PlayerDetailsModal
          mappings={player.mappings}
          onClose={handleDetailsModalClose}
          playerFixtures={player.fixtures}
        />
      ) : null}
      {isMappingModalOpen && player ? (
        <MappingsModal
          id={player.id}
          isLoading={isPending}
          mappings={player.mappings}
          onClose={handleMappingsModalClose}
          title={`Player mappings - ${player.name}`}
          update={handleUpdateMappings}
        />
      ) : null}
    </>
  );
};
