import { array, z } from 'zod';

import { competitionMappingSchema } from 'pages/backoffice/api/competitions/types/Competition.types';
import { CompetitionFormFieldsNames } from 'pages/backoffice/pages/competitions/ui/competition-form/types';
import {
  CompetitionFormat,
  CompetitionGender,
  CompetitionRegion,
  CompetitionTeamType,
} from 'pages/backoffice/types/competitions';
import { transformEmptyToNull } from 'pages/backoffice/utils/transformEmptyToNull';

export const competitionFormSchema = z.object({
  [CompetitionFormFieldsNames.id]: z.string().optional(),
  [CompetitionFormFieldsNames.name]: z.string().trim().min(2),
  [CompetitionFormFieldsNames.country]: z.string().optional().transform(transformEmptyToNull),
  [CompetitionFormFieldsNames.competitionImage]: z.instanceof(FileList).optional(),
  [CompetitionFormFieldsNames.format]: z
    .nativeEnum(CompetitionFormat)
    .or(z.literal(''))
    .optional()
    .transform(transformEmptyToNull),
  [CompetitionFormFieldsNames.gender]: z
    .nativeEnum(CompetitionGender)
    .or(z.literal(''))
    .optional()
    .transform(transformEmptyToNull),
  [CompetitionFormFieldsNames.tier]: z
    .string()
    .or(z.number())
    .optional()
    .transform((value) => {
      if (typeof value === 'string' && parseInt(value, 10).toString() === value) {
        return Number(value);
      }

      return transformEmptyToNull(value?.toString());
    })
    .refine((value) => value === null || (typeof value !== 'string' && Number(value) > 0), {
      message: 'Tier must be a positive number',
    }),
  [CompetitionFormFieldsNames.region]: z
    .nativeEnum(CompetitionRegion)
    .or(z.literal(''))
    .optional()
    .transform(transformEmptyToNull),
  [CompetitionFormFieldsNames.teamType]: z
    .nativeEnum(CompetitionTeamType)
    .or(z.literal(''))
    .optional()
    .transform(transformEmptyToNull),
  [CompetitionFormFieldsNames.organizationId]: z.string().optional().transform(transformEmptyToNull),
});

export const competitionEditFormSchema = competitionFormSchema.omit({ [CompetitionFormFieldsNames.id]: true }).extend({
  [CompetitionFormFieldsNames.mappings]: array(competitionMappingSchema),
});
