import { ErrorMessage } from '@hookform/error-message';
import { FormControl, FormHelperText, Input, Stack } from '@mui/material';
import React, { useCallback } from 'react';
import { UseFormReturn } from 'react-hook-form';

import {
  OrganizationFormFieldsNames,
  OrganizationFormLabels,
} from 'pages/backoffice/features/organization-form/constants/organizationForm.constants';
import {
  OrganizationFormValues,
  OrganizationOnSubmit,
} from 'pages/backoffice/features/organization-form/types/organizationForm.types';
import { FormFormLabel } from 'shared/components/form/ui/FormFormLabel';
import { FormInputField } from 'shared/components/form/ui/FormInputField';
import { getErrorByFieldName } from 'shared/utils/get-error-by-field-name/getErrorByFieldName';

interface Props {
  formId: string;
  onSubmit: (params: OrganizationOnSubmit) => void;
  form: UseFormReturn<OrganizationFormValues>;
}

export const OrganizationFormFeature = ({
  formId,
  onSubmit,
  form: {
    handleSubmit,
    register,
    formState: { errors },
  },
}: Props) => {
  const handleOnFormSubmit = useCallback(
    (data: OrganizationFormValues) => {
      onSubmit({
        data,
      });
    },
    [onSubmit],
  );

  return (
    <form id={formId} onSubmit={handleSubmit(handleOnFormSubmit)}>
      <Stack direction='column'>
        <FormInputField
          id={OrganizationFormFieldsNames.name}
          label={OrganizationFormLabels[OrganizationFormFieldsNames.name]}
          error={!!getErrorByFieldName(errors, OrganizationFormFieldsNames.name)}
          helperText={<ErrorMessage errors={errors} name={OrganizationFormFieldsNames.name} />}
          {...register(OrganizationFormFieldsNames.name)}
        />

        <FormControl fullWidth margin='normal'>
          <FormFormLabel>Image</FormFormLabel>
          <Input
            type='file'
            size='small'
            {...register(OrganizationFormFieldsNames.image)}
            error={!!errors[OrganizationFormFieldsNames.image]}
          />
          {errors[OrganizationFormFieldsNames.image] && (
            <FormHelperText error>
              <>{errors[OrganizationFormFieldsNames.image].message}</>
            </FormHelperText>
          )}
        </FormControl>
      </Stack>
    </form>
  );
};

OrganizationFormFeature.displayName = 'OrganizationForm';
