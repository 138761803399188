import { Box, ClickAwayListener, ListItem } from '@mui/material';
import React, { useCallback, useState } from 'react';

import { usePlayers } from 'pages/backoffice/api/players/use-players/usePlayers';
import { getJerseyNumber } from 'pages/backoffice/pages/fixtures/fixture-form/components/selected-players';
import { FixturesPlayer } from 'pages/backoffice/types/fixture';
import { AutocompletePlayerResult } from 'pages/backoffice/ui/AutocompletePlayerResult';
import ActiveFilters from 'shared/components/active-filters/ActiveFilters';
import { Autocomplete } from 'shared/components/autocomplete/Autocomplete';
import { AutocompleteActiveFilter } from 'shared/components/autocomplete-multi-select/ui/AutocompleteActiveFilter';
import { AutocompleteDropdownButton } from 'shared/components/autocomplete-multi-select/ui/AutocompleteDropdownButton';
import {
  AutocompletePopper,
  AutocompletePopperContent,
} from 'shared/components/autocomplete-multi-select/ui/AutocompletePopperContent';
import { AutocompletePopperWrapper } from 'shared/components/autocomplete-multi-select/ui/AutocompletePopperWrapper';
import { AutocompleteResultsWrapper } from 'shared/components/autocomplete-multi-select/ui/AutocompleteResultsWrapper';
import { AutocompleteTag } from 'shared/components/autocomplete-multi-select/ui/AutocompleteTag';

interface Props {
  setPlayersOnChange: (players: FixturesPlayer[]) => void;
  players: FixturesPlayer[];
  showPlayersSelectionList?: boolean;
}

type PlayerFilters = {
  name: string;
};

const INITIAL_PLAYERS_FILTERS: PlayerFilters = {
  name: '',
};

const AUTOCOMPLETE_WIDTH = 400;

export const SelectPlayers = ({ players, setPlayersOnChange, showPlayersSelectionList = true }: Props) => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [autocompleteValues, setAutocompleteValues] = useState<FixturesPlayer[]>([]);
  const { items: playersResults, isLoading, setFilters, fetchNextPage } = usePlayers();

  const handleSetPlayersOnChange = useCallback(
    (players: FixturesPlayer[]) => {
      setPlayersOnChange(players.map((player) => ({ ...player, jerseyNumber: getJerseyNumber(player) })));
    },
    [setPlayersOnChange],
  );

  const isOpen = Boolean(anchorEl);

  const close = useCallback(() => {
    setFilters(INITIAL_PLAYERS_FILTERS);
    setAnchorEl(null);
  }, [setAnchorEl, setFilters]);

  const open = useCallback(
    (event: React.MouseEvent<HTMLElement>) => {
      setAutocompleteValues(players);
      setAnchorEl(event.currentTarget);
    },
    [setAutocompleteValues, players],
  );

  const handleAddClipsClick = useCallback(
    (event: React.MouseEvent<HTMLElement>) => {
      isOpen ? close() : open(event);
    },
    [isOpen, close, open],
  );

  const handleUpdateValue = useCallback(
    (newValues: FixturesPlayer[] | null) => {
      if (newValues === null) return;

      setAutocompleteValues(newValues);
    },
    [setAutocompleteValues],
  );

  const handleApply = useCallback(() => {
    setAutocompleteValues((autocompleteValues) => {
      handleSetPlayersOnChange(autocompleteValues);
      return autocompleteValues;
    });
    close();
  }, [setAutocompleteValues, handleSetPlayersOnChange, close]);

  const handleReset = useCallback(() => {
    setAutocompleteValues([]);
  }, [setAutocompleteValues]);

  const handleRemoveRecording = useCallback(
    (recordingId: string) => {
      handleSetPlayersOnChange(players.filter((value) => value.id !== recordingId));
    },
    [handleSetPlayersOnChange, players],
  );

  const handleRemoveAutocompletePlayer = useCallback(
    (recordingId: string) => {
      setAutocompleteValues(autocompleteValues.filter((value) => value.id !== recordingId));
    },
    [setAutocompleteValues, autocompleteValues],
  );

  const handleRenderItem = useCallback(
    (
      props: React.ComponentProps<typeof ListItem>,
      option: FixturesPlayer,
      {
        selected,
      }: {
        selected: boolean;
      },
    ) => {
      return (
        <ListItem {...props} key={option.id} disablePadding>
          <AutocompletePlayerResult autocompleteWidth={AUTOCOMPLETE_WIDTH} isChecked={selected} player={option} />
        </ListItem>
      );
    },
    [],
  );

  const handleSetName = useCallback(
    (name: string) => {
      setFilters({ name: name });
    },
    [setFilters],
  );

  const renderTags = useCallback(
    (players: FixturesPlayer[]): React.ReactNode => {
      return (
        <AutocompleteTag>
          {players.map((player) => {
            return (
              <AutocompleteActiveFilter
                id={player.id}
                key={player.id}
                name={player.name}
                onRemove={handleRemoveAutocompletePlayer}
                size='small'
              />
            );
          })}
        </AutocompleteTag>
      );
    },
    [handleRemoveAutocompletePlayer],
  );

  const paperComponent: React.JSXElementConstructor<React.HTMLAttributes<HTMLElement>> = useCallback(
    (props) => (
      <AutocompleteResultsWrapper {...props} resetText={'clear selection'} onReset={handleReset} onApply={handleApply}>
        {props.children}
      </AutocompleteResultsWrapper>
    ),
    [handleReset, handleApply],
  );

  const isOptionEqual = useCallback((option: FixturesPlayer, value: FixturesPlayer) => option.id === value.id, []);
  const getOptionName = useCallback((option: FixturesPlayer) => option.name, []);

  return (
    <Box>
      <AutocompleteDropdownButton
        isOpen={isOpen}
        onClick={handleAddClipsClick}
        isSelected={players.length > 0}
        fullWidth
        label={players.length > 0 ? `${players.length} Player(s)` : 'Search...'}
      />
      {isOpen && (
        <ClickAwayListener onClickAway={handleApply}>
          <AutocompletePopper anchorEl={anchorEl} open={isOpen} placement='bottom-start'>
            <AutocompletePopperContent elevation={8}>
              <Autocomplete
                autoFocus
                PaperComponent={paperComponent}
                PopperComponent={AutocompletePopperWrapper}
                fetchNextPage={fetchNextPage}
                getItemLabel={getOptionName}
                inputWidth={AUTOCOMPLETE_WIDTH}
                isLoading={isLoading}
                isOptionEqualToValue={isOptionEqual}
                listWidth={AUTOCOMPLETE_WIDTH}
                multiple={true}
                onChange={handleSetName}
                open
                options={playersResults}
                renderOption={handleRenderItem}
                renderTags={renderTags}
                resultsHeight={310}
                resultsNoMatches={'No players found'}
                updateValue={handleUpdateValue}
                value={autocompleteValues}
              />
            </AutocompletePopperContent>
          </AutocompletePopper>
        </ClickAwayListener>
      )}
      {showPlayersSelectionList && players && (
        <ActiveFilters>
          {players.map((player) => (
            <AutocompleteActiveFilter
              id={player.id}
              key={player.id}
              name={player.name}
              onRemove={handleRemoveRecording}
            />
          ))}
        </ActiveFilters>
      )}
    </Box>
  );
};
