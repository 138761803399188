import { LoadingButton } from '@mui/lab';
import { Backdrop, Button, IconButton, styled } from '@mui/material';
import MuiDialog, { DialogProps } from '@mui/material/Dialog';
import { IconClose } from 'kognia-ui/icons/IconClose';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { DialogActions } from 'shared/components/dialog-new/ui/DialogActions';
import { DialogButtonContainer } from 'shared/components/dialog-new/ui/DialogButtonContainer';
import { DialogContent } from 'shared/components/dialog-new/ui/DialogContent';
import { DialogHeader } from 'shared/components/dialog-new/ui/DialogHeader';

interface DialogBackdropProps {
  disableBackground: boolean;
}

const DialogBackdrop = styled(Backdrop, {
  shouldForwardProp: (prop) => prop !== 'disableBackground',
})<DialogBackdropProps>(({ disableBackground }) => ({
  ...(disableBackground && {
    backgroundColor: 'transparent',
  }),
  pointerEvents: 'none',
}));

const DialogCloseButton = styled(IconButton)(({ theme }) => ({
  position: 'absolute',
  top: theme.spacing(1),
  right: theme.spacing(1),
}));

interface Props extends DialogProps {
  disableBackground?: boolean;
  disableBackdropOnClose?: boolean;
  hiddenActions?: boolean;
  title?: string;
  icon?: JSX.Element;
  onCancel?: () => void;
  onSubmit?: () => void;
  buttonCancelText?: string;
  buttonSubmitText?: string;
  error?: boolean;
  submitDisabled?: boolean;
  buttonFormId?: string;
  isLoading?: boolean;
}

const DOWNLOAD_BUTTON_MIN_WIDTH = 136;

export const DialogNew = ({
  disableBackground = false,
  disableBackdropOnClose = false,
  hiddenActions = false,
  isLoading = false,
  onClose,
  children,
  title,
  icon,
  onCancel,
  onSubmit,
  buttonCancelText,
  buttonSubmitText,
  error = false,
  submitDisabled = false,
  buttonFormId,
  ...rest
}: Props) => {
  const { t } = useTranslation();

  return (
    <MuiDialog
      {...rest}
      onClose={disableBackdropOnClose ? undefined : onClose}
      components={{ Backdrop: DialogBackdrop }}
      componentsProps={{
        backdrop: () => ({ disableBackground }),
      }}
    >
      {title ? <DialogHeader icon={icon}>{title}</DialogHeader> : null}
      <DialogContent noHeader={!title}>{children}</DialogContent>
      {!hiddenActions ? (
        <DialogActions>
          {onCancel ? (
            <DialogButtonContainer>
              <Button
                variant='outlined'
                color='secondary'
                onClick={onCancel}
                sx={{ minWidth: DOWNLOAD_BUTTON_MIN_WIDTH }}
              >
                {buttonCancelText ? buttonCancelText : t('common:actions.cancel')}
              </Button>
            </DialogButtonContainer>
          ) : null}
          {onSubmit || buttonFormId ? (
            <DialogButtonContainer>
              <LoadingButton
                loading={isLoading}
                disabled={submitDisabled || isLoading}
                variant='contained'
                onClick={onSubmit}
                color={error ? 'error' : 'primary'}
                type={buttonFormId ? 'submit' : 'button'}
                form={buttonFormId}
                sx={{ minWidth: DOWNLOAD_BUTTON_MIN_WIDTH }}
              >
                {buttonSubmitText ? buttonSubmitText : t('common:actions.done')}
              </LoadingButton>
            </DialogButtonContainer>
          ) : null}
        </DialogActions>
      ) : null}

      {typeof onClose === 'function' ? (
        <DialogCloseButton onClick={(event) => onClose && onClose(event, 'backdropClick')}>
          <IconClose size='small' />
        </DialogCloseButton>
      ) : null}
    </MuiDialog>
  );
};
