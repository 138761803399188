import { Box, DialogContent, DialogTitle, Stack, Typography } from '@mui/material';
import { fontSizes } from 'kognia-ui';
import { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';

import { Dialog } from 'shared/components/dialog/Dialog';
import { KeyboardKeyOptions } from 'shared/components/keyboard-shortcuts/types/keyboard-key-options';
import { KeyboardShortcut } from 'shared/components/keyboard-shortcuts/ui/keyboard-shortcut/KeyboardShortcut';
import { getIsMacOS } from 'shared/utils/is-mac-os';

type ShortCut = {
  name: string;
  shortcut: ReactNode;
};

type ShortCutsList = {
  name: string;
  shortcuts: ShortCut[];
};

interface Props {
  isOpen: boolean;
  onClose: () => void;
}

export const KeyboardShortcutsModal = ({ isOpen, onClose }: Props) => {
  const { t } = useTranslation();
  const isMacOs = getIsMacOS();

  const keyboardShortcuts: ShortCutsList[] = [
    {
      name: t('common:keyboard-shortcuts.global-shortcuts'),
      shortcuts: [
        {
          name: t('common:keyboard-shortcuts.toggle-help'),
          shortcut: <KeyboardShortcut keys={[[KeyboardKeyOptions.QuestionMark]]} />,
        },
      ],
    },
    {
      name: t('common:keyboard-shortcuts.video-player'),
      shortcuts: [
        {
          name: t('common:keyboard-shortcuts.play-pause'),
          shortcut: <KeyboardShortcut keys={[[KeyboardKeyOptions.SpaceBar]]} />,
        },
        {
          name: t('common:keyboard-shortcuts.fullscreen'),
          shortcut: <KeyboardShortcut keys={[[KeyboardKeyOptions.f]]} />,
        },
        {
          name: t('common:keyboard-shortcuts.plus-five-seconds'),
          shortcut: <KeyboardShortcut keys={[[KeyboardKeyOptions.ArrowRight]]} />,
        },
        {
          name: t('common:keyboard-shortcuts.minus-five-seconds'),
          shortcut: <KeyboardShortcut keys={[[KeyboardKeyOptions.ArrowLeft]]} />,
        },
        {
          name: t('common:keyboard-shortcuts.next-playlist-item'),
          shortcut: <KeyboardShortcut keys={[[KeyboardKeyOptions.Control, KeyboardKeyOptions.ArrowLeft]]} />,
        },
        {
          name: t('common:keyboard-shortcuts.previous-playlist-item'),
          shortcut: <KeyboardShortcut keys={[[KeyboardKeyOptions.Control, KeyboardKeyOptions.ArrowRight]]} />,
        },
        {
          name: t('common:keyboard-shortcuts.hide-show-overlays'),
          shortcut: <KeyboardShortcut keys={[[KeyboardKeyOptions.Alt, KeyboardKeyOptions.o]]} />,
        },
      ],
    },
    {
      name: t('common:keyboard-shortcuts.timeline'),
      shortcuts: [
        {
          name: t('common:keyboard-shortcuts.center-playhead'),
          shortcut: <KeyboardShortcut keys={[[KeyboardKeyOptions.Alt, KeyboardKeyOptions.c]]} />,
        },
        {
          name: t('common:keyboard-shortcuts.zoom-in'),
          shortcut: (
            <KeyboardShortcut
              keys={[
                [KeyboardKeyOptions.Plus],
                [isMacOs ? KeyboardKeyOptions.Command : KeyboardKeyOptions.Control, KeyboardKeyOptions.ScrollUp],
              ]}
            />
          ),
        },
        {
          name: t('common:keyboard-shortcuts.zoom-out'),
          shortcut: (
            <KeyboardShortcut
              keys={[
                [KeyboardKeyOptions.Minus],
                [isMacOs ? KeyboardKeyOptions.Command : KeyboardKeyOptions.Control, KeyboardKeyOptions.ScrollDown],
              ]}
            />
          ),
        },
      ],
    },
  ];

  const keyboardShortcutsList = keyboardShortcuts.map((sectionItem) => {
    const shortcutsList = sectionItem.shortcuts.map((shortcutItem) => {
      return (
        <Stack key={shortcutItem.name} spacing={2} direction='row' alignItems='center'>
          <Stack spacing={1} direction='row' justifyContent='flex-end' flexBasis='40%' alignItems='center'>
            {shortcutItem.shortcut}
          </Stack>
          <Stack spacing={1}>
            <Typography fontSize={fontSizes.small} fontWeight='fontWeightMedium'>
              {shortcutItem.name}
            </Typography>
          </Stack>
        </Stack>
      );
    });

    return (
      <Box key={sectionItem.name} sx={{ breakInside: 'avoid', mb: 4 }}>
        <Stack mb={1} marginLeft={'40%'}>
          <Typography fontSize={fontSizes.default} ml={2} fontWeight='fontWeightMedium'>
            {sectionItem.name}
          </Typography>
        </Stack>
        <Stack spacing={1.5}>{shortcutsList}</Stack>
      </Box>
    );
  });

  return (
    <Dialog open={isOpen} onClose={onClose} maxWidth='lg' fullWidth>
      <DialogTitle
        fontSize={fontSizes.xLarge}
        fontWeight='fontWeightRegular'
        sx={{ paddingTop: 5, px: 4, paddingBottom: 4 }}
      >
        Keyboard shortcuts
      </DialogTitle>
      <DialogContent sx={{ px: 4, pb: 6 }}>
        <Box sx={{ columnCount: 2 }}>{keyboardShortcutsList}</Box>
      </DialogContent>
    </Dialog>
  );
};
