import { useCallback } from 'react';

import { Clip } from 'pages/tactical-analysis/api/use-tactical-analysis-data/generate-timeline-rows/types/clip';
import { ClipsList } from 'pages/tactical-analysis/components/timeline/timeline-table/components/clips-list';
import { RowContentContainer } from 'pages/tactical-analysis/components/timeline/timeline-table/components/row-content-container';
import { useRowTeam } from 'pages/tactical-analysis/components/timeline/timeline-table/hooks/useRowTeam';
import {
  useRow,
  useRowIsHighlighted,
  useSetRowIsHighlighted,
} from 'pages/tactical-analysis/components/timeline/timeline-table/store/atoms';

type Props = {
  clips: Clip[];
  recordingId: string;
  rowId: string;
};

export const RowContent = ({ clips, recordingId, rowId }: Props) => {
  const row = useRow(rowId);
  const team = useRowTeam(recordingId, row.teamId);
  const isRowHighlighted = useRowIsHighlighted(rowId);
  const setRowHighlighted = useSetRowIsHighlighted(rowId);

  const handleMouseEnter = useCallback(() => {
    setRowHighlighted(true);
  }, [setRowHighlighted]);

  const handleMouseLeave = useCallback(() => {
    setRowHighlighted(false);
  }, [setRowHighlighted]);

  return (
    <RowContentContainer
      team={team}
      borderTop={rowId === `${recordingId}-filters`}
      isHighlighted={isRowHighlighted}
      isSelected={row.isSelected}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      <ClipsList recordingId={recordingId} clips={clips} />
    </RowContentContainer>
  );
};
