import { Button, Tooltip } from '@mui/material';
import { IconFullScreen } from 'kognia-ui/icons/IconFullScreen';
import React, { useCallback, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';

import { getFullScreenElement, requestFullscreen } from 'shared/utils/elementFullscreen';
import { getIsFormTag } from 'shared/utils/is-form-tag';

declare global {
  interface HTMLElement {
    webkitRequestFullscreen: () => void;
    mozRequestFullScreen: () => void;
    msRequestFullscreen: () => void;
  }
}

declare global {
  interface Document {
    webkitExitFullscreen: () => void;
    mozCancelFullScreen: () => void;
    msExitFullscreen: () => void;
    webkitFullscreenElement: any | null | undefined;
    mozFullScreenElement: any | null | undefined;
    msFullscreenElement: any | null | undefined;
  }
}

const prefixes = ['', 'moz', 'webkit', 'ms'];

interface Props {
  showFullScreen: string;
  setShowFullScreen: (param: ((showFullScreen: string) => string) | string) => void;
  containerRef: React.RefObject<HTMLElement>;
}

const FullscreenButton = ({ containerRef, setShowFullScreen }: Props) => {
  const { t } = useTranslation();
  const buttonRef = useRef<HTMLButtonElement>(null);

  const handleClick = useCallback(
    (event: React.MouseEvent<HTMLElement> | KeyboardEvent) => {
      event.preventDefault();
      event.stopPropagation();

      if (containerRef.current) {
        requestFullscreen(containerRef.current);
      }

      buttonRef?.current?.blur();
    },
    [containerRef, buttonRef],
  );

  const handleKeyDown = useCallback(
    (event: KeyboardEvent) => {
      const tagName = (event.target as HTMLElement).tagName;
      const isFormTag = getIsFormTag(tagName);
      if (
        (event.key === 'f' || event.key === 'F') &&
        !isFormTag &&
        !event.ctrlKey &&
        !event.shiftKey &&
        !event.metaKey
      ) {
        handleClick(event);
      }
    },
    [handleClick],
  );

  const handleOnFullscreenChange = useCallback(() => {
    setShowFullScreen(getFullScreenElement() ? 'visible' : 'hidden');
  }, [setShowFullScreen]);

  useEffect(() => {
    window.addEventListener('keydown', handleKeyDown);

    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, [handleKeyDown]);

  useEffect(() => {
    if (!containerRef.current) return;
    const containerCurrent = containerRef.current;

    prefixes.forEach((prefix) => {
      containerCurrent.addEventListener(`${prefix}fullscreenchange`, handleOnFullscreenChange);
    });

    return () => {
      if (containerCurrent) {
        prefixes.forEach((prefix) => {
          containerCurrent.removeEventListener(`${prefix}fullscreenchange`, handleOnFullscreenChange);
        });
      }
    };
  }, [containerRef, handleOnFullscreenChange]);

  return (
    <Tooltip enterDelay={2000} title={`${t('video-player:controls.fullscreen')}`}>
      <Button ref={buttonRef} size={'small'} onClick={handleClick}>
        <IconFullScreen sx={{ color: 'common.white' }} />
      </Button>
    </Tooltip>
  );
};

export default FullscreenButton;
