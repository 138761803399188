export const downloadFileFromBlob = (res: Blob, filename: string, fileType: string) => {
  const blob = new Blob([res], {
    type: fileType,
  });
  const blobURL = window.URL.createObjectURL(blob);

  const downloadLink = document.createElement('a');
  downloadLink.target = '_blank';
  downloadLink.href = blobURL;
  downloadLink.download = filename;
  downloadLink.dispatchEvent(
    new MouseEvent('click', {
      bubbles: true,
      cancelable: true,
      view: window,
    }),
  );
  setTimeout(function () {
    window.URL.revokeObjectURL(blobURL);
  }, 200);
};
