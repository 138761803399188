import { zodResolver } from '@hookform/resolvers/zod';
import { AccordionActions, AccordionDetails } from '@mui/material';
import { IconDelete } from 'kognia-ui/icons/IconDelete';
import React, { useCallback } from 'react';
import { useForm } from 'react-hook-form';

import { useDeleteTask } from 'pages/backoffice/api/operations/useDeleteTask';
import { AnnotationFormActions } from 'pages/backoffice/pages/annotation/ui/AnnotationFormActions';
import { AnnotationToolField } from 'pages/backoffice/pages/annotation/ui/form-fields/AnnotationToolField';
import { IndexTypeGroup } from 'pages/backoffice/pages/annotation/ui/form-fields/IndexTypeGroup';
import { RecordingIdField } from 'pages/backoffice/pages/annotation/ui/form-fields/RecordingIdField';
import { TaskTypeField } from 'pages/backoffice/pages/annotation/ui/form-fields/TaskTypeField';
import { deleteTaskFormSchema } from 'pages/backoffice/pages/annotation/utils/form-fields-validation';
import { DeleteTaskFormSchema } from 'pages/backoffice/pages/annotation/utils/form-fields-validation/types';
import { DialogNew } from 'shared/components/dialog-new/DialogNew';

export const DeleteTask = () => {
  const [confirmationDialogOpen, setConfirmationDialogOpen] = React.useState(false);
  const {
    register,
    formState: { errors },
    reset,
    resetField,
    getValues,
    handleSubmit: handleFormSubmit,
  } = useForm<DeleteTaskFormSchema>({
    resolver: zodResolver(deleteTaskFormSchema),
  });

  const values = getValues();

  const hasStartIndexValue = values?.startIx ? values?.startIx.length > 0 : false;
  const hasEndIndexValue = values?.endIx ? values?.endIx.length > 0 : false;

  const handleConfirmDialogOpen = useCallback(() => setConfirmationDialogOpen(true), []);
  const handleConfirmDialogClose = useCallback(() => setConfirmationDialogOpen(false), []);

  const { deleteTask } = useDeleteTask();

  const handleSubmit = useCallback(() => {
    handleConfirmDialogOpen();
  }, [handleConfirmDialogOpen]);

  const handleConfirmSubmit = useCallback(() => {
    deleteTask({ data: values });
    handleConfirmDialogClose();
  }, [deleteTask, handleConfirmDialogClose, values]);

  return (
    <form onSubmit={handleFormSubmit(handleSubmit)}>
      <AccordionDetails>
        <RecordingIdField errors={errors} register={register} />
        <IndexTypeGroup register={register} errors={errors} resetField={resetField} />
        <TaskTypeField errors={errors} register={register} />
        <AnnotationToolField errors={errors} register={register} />
      </AccordionDetails>
      <AccordionActions>
        <AnnotationFormActions reset={() => reset()} />
      </AccordionActions>
      <DialogNew
        title='Confirmation'
        icon={<IconDelete />}
        onCancel={handleConfirmDialogClose}
        onClose={handleConfirmDialogClose}
        onSubmit={handleConfirmSubmit}
        buttonSubmitText='Delete'
        open={confirmationDialogOpen}
        error
      >
        {hasStartIndexValue && !hasEndIndexValue ? (
          <>
            This will delete tasks from <strong>{values.startIx}</strong> to the end for{' '}
            <strong>{values.recordingId}</strong>.
          </>
        ) : null}
        {hasStartIndexValue && hasEndIndexValue ? (
          <>
            This will delete tasks from <strong>{values.startIx}</strong> to <strong>{values.endIx}</strong> for{' '}
            <strong>{values.recordingId}</strong>.
          </>
        ) : null}
        {!hasStartIndexValue ? (
          <>
            This will delete all tasks for recording <strong>{values.recordingId}</strong>.
          </>
        ) : null}
      </DialogNew>
    </form>
  );
};
