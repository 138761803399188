import { array, number, object, string, z } from 'zod';

import { competitionSchema } from 'shared/types/competition/competitionSchema';
import { FixtureMatchEndType } from 'shared/types/fixtures/fixtures';
import { mappingSchema } from 'shared/types/mapping';
import { MappingProvider } from 'shared/types/mappings/mappingProvider';
import { defaultSeasonSchema } from 'shared/types/season/seasonSchema';
import { TeamTypes } from 'shared/types/team/team';

const playerTeamSchema = object({
  id: string(),
  name: string(),
  abbreviation: string().optional().nullable(),
  logoUrl: string().optional().nullable(),
  countryCode: string().optional(), // TODO: change type after changing types for team
  teamType: z.enum([TeamTypes.CLUB, TeamTypes.NATIONAL_TEAM]),
});

export const playerFixturesSchema = object({
  position: number().min(0).max(10),
  side: number().min(0).max(7),
  jerseyNumber: number().min(0),
  name: string(),
  homeTeamScore: number(),
  awayTeamScore: number(),
  homePenaltyScore: number(),
  awayPenaltyScore: number(),
  matchEndType: z.enum([
    FixtureMatchEndType.EXTENDED_TIME,
    FixtureMatchEndType.GOLDEN_GOAL_RULE,
    FixtureMatchEndType.NORMAL_TIME,
    FixtureMatchEndType.PENALTY_SHOOT_OUT,
  ]),
  id: string(),
  team: playerTeamSchema,
  date: string().nullable(),
});

export const playerMappingSchema = object({
  provider: z.nativeEnum(MappingProvider),
  idProvider: string(),
});

const playerApiDefaultsSchema = object({
  team: playerTeamSchema,
  season: defaultSeasonSchema,
  competition: competitionSchema,
});

export const playerSchema = object({
  name: string(),
  id: string(),
  photoUrl: string().optional().nullable(),
  fixtures: array(playerFixturesSchema),
  mappings: array(mappingSchema),
  playerDefaults: playerApiDefaultsSchema.optional(),
  isFavorite: z.boolean(),
});
