import { Box } from '@mui/material';
import React, { useEffect } from 'react';

import { useCurrentPlaylistItem, useIsSeeking, useVideoPlayerState } from 'shared/components/video-player/hooks';
import {
  useAreOverlaysReady,
  useOverlaysController,
} from 'shared/components/video-player/hooks/use-overlays-controller';
import { READY_STATES } from 'shared/components/video-player/state/states';

type Props = {
  disabled: boolean;
  videoPlayerContainerRef: React.RefObject<HTMLDivElement>;
};

export const VideoOverlays = ({ disabled, videoPlayerContainerRef }: Props) => {
  const { container, overlayGenerator, matrix3dTransformation } = useOverlaysController(videoPlayerContainerRef);
  const { fundamentalsSelected, recordingId } = useCurrentPlaylistItem();
  const isSeeking = useIsSeeking();
  const areOverlaysReady = useAreOverlaysReady();
  const { readyState } = useVideoPlayerState();

  useEffect(() => {
    if (fundamentalsSelected.tacticalAnalysisId) {
      overlayGenerator.init({ tacticalAnalysisId: fundamentalsSelected.tacticalAnalysisId, recordingId });
    }
  }, [fundamentalsSelected.tacticalAnalysisId, overlayGenerator, recordingId]);

  const areOverlaysVisible =
    !disabled &&
    !!matrix3dTransformation &&
    areOverlaysReady &&
    !isSeeking &&
    fundamentalsSelected.tacticalAnalysisId &&
    (readyState === READY_STATES.PLAYING || readyState === READY_STATES.PAUSED || readyState === READY_STATES.STAND_BY);

  return (
    <Box
      ref={container}
      key={recordingId}
      sx={{
        pointerEvents: 'none',
        aspectRatio: '16/9',
        position: 'absolute',
        top: 0,
        left: 0,
        transformOrigin: '0 0',
        '& *::selection': {
          background: 'transparent',
        },
      }}
      style={{
        display: areOverlaysVisible ? 'block' : 'none',
        transform: matrix3dTransformation ? `matrix3d(${matrix3dTransformation.join(',')})` : 'none',
      }}
    />
  );
};
