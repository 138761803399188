import queryString from 'query-string';
import React, { useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useParams } from 'react-router-dom';

// eslint-disable-next-line import/no-restricted-paths
import { AddToPlaylistMenuStateProvider } from 'pages/tactical-analysis/components/tactical-analysis/add-to-playlist-menu/add-to-playlist-menu-state';
import { TagRecordingScreen } from 'pages/tagging-tool-tag-recording/components/tag-recording-screen/TagRecordingScreen';
import { UseTaggingToolTagRecordingDataType } from 'pages/tagging-tool-tag-recording/hooks/useTaggingToolTagRecordingData';
import { useTaggingToolTagRecordingPage } from 'pages/tagging-tool-tag-recording/hooks/useTaggingToolTagRecordingPage';
import { generateTaggingVideoTypes } from 'pages/tagging-tool-tag-recording/utils/generateTaggingVideoTypes';
import Container from 'shared/components/container/Container';
import Spinner from 'shared/components/spinner/Spinner';
import { SwitchEnvironment } from 'shared/components/switch-environment/SwitchEnvironment';
import { VideoPlayerStateProvider } from 'shared/components/video-player';
import { PANORAMIC_PLAYING_MODE } from 'shared/components/video-player/defaultPlayingModes';
import { PlaylistItemType } from 'shared/components/video-player/types';
import { useBranding } from 'shared/hooks/use-branding/useBranding';
import usePageStateMachine from 'shared/hooks/use-page-state-machine';
import { invariant } from 'shared/utils/invariant';

export const TaggingToolTagContainer = () => {
  const { recordingId } = useParams();
  invariant(recordingId);
  const { t } = useTranslation();
  const { search } = useLocation();
  const { isLive } = queryString.parse(search);
  const { data, isPageLoading, isPageReady, refetch, isInvalidClient } =
    usePageStateMachine<UseTaggingToolTagRecordingDataType>(() =>
      useTaggingToolTagRecordingPage(recordingId, isLive === 'true'),
    );
  const branding = useBranding();

  useEffect(() => {
    document.title = t('common:metas.title.tagging', { clientDisplayName: branding.displayName });
  }, [branding.displayName, t]);

  const videoTypes = useMemo(
    () =>
      !data?.matchWithEpisodes?.match?.videoSources
        ? []
        : generateTaggingVideoTypes({
            videoSources: data.matchWithEpisodes.match.videoSources,
          }),
    [data],
  );

  const playlistItems: PlaylistItemType[] = useMemo(
    () =>
      !data?.matchWithEpisodes?.match?.id
        ? []
        : [
            {
              id: 'tagging-tool-item',
              duration: 0,
              videoTypes,
              name: '',
              index: 0,
              fundamentalsSelected:
                data?.matchWithEpisodes.episodes.length > 0
                  ? {
                      tacticalAnalysisId: data?.matchWithEpisodes.episodes[0].tacticalAnalysisId,
                      fundamentalsSelected: ['all'],
                    }
                  : { tacticalAnalysisId: undefined, fundamentalsSelected: [] },
              hasHomographies: false,
              recordingId: data?.matchWithEpisodes.match.id,
              showOverlays: false,
            },
          ],
    [videoTypes, data],
  );

  if (isInvalidClient && data?.matchWithEpisodes?.match.clientIds) {
    return <SwitchEnvironment resourceClientsIds={data.matchWithEpisodes.match.clientIds} />;
  }

  return (
    <Container>
      {isPageLoading && <Spinner isFullPage />}
      {isPageReady && data?.taggingEvents && (
        <AddToPlaylistMenuStateProvider id={'tagging-tool-playlist-menu'}>
          <VideoPlayerStateProvider
            refreshData={refetch}
            playerId={`tagging-tool-${data.matchWithEpisodes?.match.id || data.taggingEvents.name}`}
            playlistItems={playlistItems}
            playingMode={videoTypes[0]?.playingMode || PANORAMIC_PLAYING_MODE}
          >
            <TagRecordingScreen isLive={isLive === 'true'} key={recordingId} recordingId={recordingId} />
          </VideoPlayerStateProvider>
        </AddToPlaylistMenuStateProvider>
      )}
    </Container>
  );
};
