import { zodResolver } from '@hookform/resolvers/zod';
import { AccordionActions, AccordionDetails } from '@mui/material';
import { useCallback } from 'react';
import { useForm } from 'react-hook-form';

import { useReprocessThirdPartyEvents } from 'pages/backoffice/api/operations/useReprocessThirdPartyEvents';
import { AnnotationFormActions } from 'pages/backoffice/pages/annotation/ui/AnnotationFormActions';
import { RecordingIdField } from 'pages/backoffice/pages/annotation/ui/form-fields/RecordingIdField';
import { reprocessThirdPartyEventsFormSchema } from 'pages/backoffice/pages/annotation/utils/form-fields-validation';
import {
  ReprocessThirdPartyEventsForm,
  ReprocessThirdPartyEventsFormSchema,
} from 'pages/backoffice/pages/annotation/utils/form-fields-validation/types';

export const ReprocessThirdPartyEvents = () => {
  const { reprocessThirdPartyEvents } = useReprocessThirdPartyEvents();

  const {
    register,
    formState: { errors },
    reset,
    handleSubmit: handleFormSubmit,
  } = useForm<ReprocessThirdPartyEventsFormSchema>({
    resolver: zodResolver(reprocessThirdPartyEventsFormSchema),
  });

  const handleSubmit = useCallback(
    (data: ReprocessThirdPartyEventsForm) => {
      reprocessThirdPartyEvents({ data });
    },
    [reprocessThirdPartyEvents],
  );

  return (
    <form onSubmit={handleFormSubmit(handleSubmit)}>
      <AccordionDetails>
        <RecordingIdField errors={errors} register={register} />
      </AccordionDetails>
      <AccordionActions>
        <AnnotationFormActions reset={() => reset()} />
      </AccordionActions>
    </form>
  );
};
