import { z } from 'zod';

import { mappingSchema } from 'shared/types/mapping';
import { MappingProvider } from 'shared/types/mappings/mappingProvider';
import { Pagination } from 'shared/types/pagination/types';

type VenueMappingApi = {
  provider: MappingProvider;
  venue_id_provider: string;
};

export interface VenueApi {
  uuid: string;
  name: string;
  city: string;
  country_code: string;
  mappings: VenueMappingApi[];
}

export type VenuesFilters = {
  name?: string;
  sort?: string;
  sortDirection?: string;
};

export interface VenuesApiResponse {
  data: VenueApi[];
  page: Pagination;
}

export const VenueSchema = z.object({
  id: z.string(),
  name: z.string(),
  city: z.string(),
  countryCode: z.string(),
  mappings: z.array(mappingSchema).optional(),
});

export type Venue = z.TypeOf<typeof VenueSchema>;
