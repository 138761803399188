import { Box, Typography } from '@mui/material';
import { Colors, fontSizes, fontWeight } from 'kognia-ui';
import { IconPlus } from 'kognia-ui/icons/IconPlus';
import React, { useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { generatePath, useNavigate } from 'react-router-dom';

import { routes } from 'kognia/router/routes';
import { KeypadListItem } from 'pages/tagging-tool-keypad-list/components/keypad-list/components/keypad-list-item/KeypadListItem';
import { KeypadNameModal } from 'pages/tagging-tool-keypad-list/components/keypad-list/components/keypad-name-modal/KeypadNameModal';
import { KeypadRemoveModal } from 'pages/tagging-tool-keypad-list/components/keypad-list/components/keypad-remove-modal/KeypadRemoveModal';
import { ActionType, useKeypadListScreenState } from 'pages/tagging-tool-keypad-list/components/keypad-list/state';
import { KeypadListContent } from 'pages/tagging-tool-keypad-list/components/keypad-list/styled';
import {
  AddKeypadButton,
  AddKeypadButtonActionText,
} from 'pages/tagging-tool-keypad-list/components/keypad-list/styled';
import { PageSubTitle } from 'shared/components/page-sub-title/PageSubTitle';
import { PageTitle } from 'shared/components/page-title/PageTitle';
import Spinner from 'shared/components/spinner/Spinner';
import { useBranding } from 'shared/hooks/use-branding/useBranding';
import { FetchKeypadsItem } from 'tagging-tool/service/keypad';

export const KeypadListScreen = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [state, dispatch] = useKeypadListScreenState();
  const branding = useBranding();

  useEffect(() => {
    document.title = t('common:metas.title.keypads', { clientDisplayName: branding.displayName });
  }, [branding.displayName, t]);

  const nameModalOpen = state.showNameModal === true;
  const removeKeypadModalOpen = state.showDeleteModal === true;

  const handleEdit = useCallback(
    (data: FetchKeypadsItem) => {
      navigate(
        generatePath(routes.KEYPADS_EDIT, {
          id: data.id,
        }),
      );
    },
    [navigate],
  );

  const handleAddNew = useCallback(() => {
    dispatch({ type: ActionType.NEW_KEYPAD });
  }, [dispatch]);

  const handleChangeName = useCallback(
    (payload: FetchKeypadsItem) => {
      dispatch({ type: ActionType.UPDATE_NAME, payload });
    },
    [dispatch],
  );

  const handleDuplicate = useCallback(
    (item: FetchKeypadsItem) => {
      const payload: FetchKeypadsItem = {
        ...item,
        name: t('tagging-tool:keypad.clone-name', { name: item.name }),
      };
      dispatch({ type: ActionType.CLONE_KEYPAD, payload });
    },
    [dispatch, t],
  );

  const handleSetDefault = useCallback(
    (payload: FetchKeypadsItem) => {
      dispatch({ type: ActionType.SET_DEFAULT, payload });
    },
    [dispatch],
  );

  const handleNameModalCancel = useCallback(() => {
    dispatch({ type: ActionType.CANCEL_NAME_MODAL });
  }, [dispatch]);

  const handleNameModalSave = useCallback(
    (payload: string) => {
      dispatch({ type: ActionType.SUBMIT, payload });
    },
    [dispatch],
  );

  const handleDelete = useCallback(
    (payload: FetchKeypadsItem) => {
      dispatch({ type: ActionType.DELETE, payload });
    },
    [dispatch],
  );

  const handleDeleteModalCancel = useCallback(() => {
    dispatch({ type: ActionType.CANCEL_DELETE_MODAL });
  }, [dispatch]);

  const handleDeleteModalSave = useCallback(() => {
    dispatch({ type: ActionType.SUBMIT_DELETE });
  }, [dispatch]);

  return (
    <>
      <div>
        {state.loading && <Spinner isFullPage />}
        {state.data !== undefined && (
          <Box>
            <PageTitle>{t('tagging-tool:keypad.keypad-list-title')}</PageTitle>
            <PageSubTitle>{t('tagging-tool:keypad.keypad-list')}</PageSubTitle>

            <KeypadListContent>
              <AddKeypadButton onClick={handleAddNew}>
                <AddKeypadButtonActionText>
                  <IconPlus size='small' />
                  <Typography fontWeight={fontWeight['500']} fontSize={fontSizes.default}>
                    {t('tagging-tool:keypad.add-new')}
                  </Typography>
                </AddKeypadButtonActionText>
                <Typography fontSize={fontSizes.small} color={Colors.storm}>
                  {t('tagging-tool:keypad.based-on-generic-keypad')}
                </Typography>
              </AddKeypadButton>
              {state.data.map((data, idx) => {
                return (
                  <KeypadListItem
                    key={`keypad$${idx}`}
                    data={data}
                    onEdit={handleEdit}
                    onChangeName={handleChangeName}
                    onDuplicate={handleDuplicate}
                    onSetDefault={handleSetDefault}
                    onRemove={handleDelete}
                  />
                );
              })}
            </KeypadListContent>
          </Box>
        )}
      </div>
      {nameModalOpen ? (
        <KeypadNameModal
          open={nameModalOpen}
          name={state.targetKeypadData?.name}
          onSave={handleNameModalSave}
          onCancel={handleNameModalCancel}
        />
      ) : null}
      {removeKeypadModalOpen ? (
        <KeypadRemoveModal
          open={state.showDeleteModal === true}
          name={state.targetKeypadData?.name}
          onDelete={handleDeleteModalSave}
          onCancel={handleDeleteModalCancel}
        />
      ) : null}
    </>
  );
};
