import { Stack, Typography } from '@mui/material';
import { fontSizes } from 'kognia-ui';
import { TeamAvatar } from 'kognia-ui/components/avatar/TeamAvatar';
import { MouseEvent, useCallback, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { generatePath, useNavigate } from 'react-router-dom';

import { useTeamsInfinityQuery } from 'entities/dashboard/api/use-teams-infinity-query/useTeamsInfinityQuery';
import { optimisticFavoritesUpdateTeamWithDefaults } from 'entities/dashboard/api/useTeamWithDefaults';
import { useUpdateTeamFavorites } from 'entities/dashboard/api/useUpdateTeamFavorites';
import { Country } from 'features/dashboard/team-profile-header/ui/Country';
import { routes } from 'kognia/router/routes';
import { DashboardMenuItem } from 'shared/components/DashboardMenuItem';
import { DashboardMenuList } from 'shared/components/DashboardMenuList';
import { FavoriteStarButton } from 'shared/components/FavoriteStarButton';
import { MenuItemContentWrapper } from 'shared/components/MenuItemContentWrapper';
import Spinner from 'shared/components/spinner/Spinner';
import { useIntersectionObserver } from 'shared/hooks/use-intersection-observer/useIntersectionObserver';
import { ActionTypes } from 'shared/streams/actionTypes';
import { publishEvent } from 'shared/streams/eventEmitter';
import { MetricsNames } from 'shared/types/metrics';
import { Team } from 'shared/types/team/team';

export const TeamFavoritesListFeature = () => {
  const lastItemRef = useRef<HTMLLIElement | null>(null);
  const { items, hasNextPage, isLoading, fetchNextPage } = useTeamsInfinityQuery({
    initialFilters: { isFavorite: true },
  });
  const { updateTeamFavorites } = useUpdateTeamFavorites();
  const navigate = useNavigate();
  const { t } = useTranslation(['common']);

  const handleMenuItemClick = useCallback(
    (teamId: string) => {
      publishEvent({
        type: ActionTypes.NAVIGATE_TO_FAVORITE,
        payload: {
          name: MetricsNames.FAVORITE_NAVIGATE_TO,
          data: {
            type: 'team',
          },
        },
      });
      navigate(generatePath(routes.TEAM_PROFILE, { teamId }));
    },
    [navigate],
  );

  const handleFavoritesButtonClick = useCallback(
    (event: MouseEvent<HTMLButtonElement>, team: Team) => {
      event.stopPropagation();
      publishEvent({
        type: ActionTypes.UPDATE_FAVORITES,
        payload: {
          name: MetricsNames.FAVORITES_UPDATE,
          data: {
            action: !team.isFavorite ? 'add' : 'remove',
            place: 'card',
            type: 'team',
          },
        },
      });
      updateTeamFavorites({
        teamId: team.id,
        isFavorite: !team.isFavorite,
        onMutate: optimisticFavoritesUpdateTeamWithDefaults,
      });
    },
    [updateTeamFavorites],
  );

  const handleObserver = useCallback(() => {
    if (!hasNextPage || isLoading) {
      return;
    }

    fetchNextPage();
  }, [fetchNextPage, hasNextPage, isLoading]);

  useIntersectionObserver(handleObserver, lastItemRef);

  if (isLoading) {
    return (
      <Stack justifyContent={'center'} alignItems={'center'} pl={2} py={2}>
        <Spinner />
      </Stack>
    );
  }

  if (!items.length) {
    return (
      <Stack justifyContent={'center'} pl={2}>
        <Typography variant={'caption'} fontWeight={'fontWeightMedium'}>
          {t('favorites.no-team-favorites-results')}
        </Typography>
      </Stack>
    );
  }

  return (
    <DashboardMenuList>
      {items.map((item, index) => (
        <DashboardMenuItem
          dense
          key={item.id}
          ref={index === items.length - 1 ? lastItemRef : null}
          onClick={() => handleMenuItemClick(item.id)}
          tabIndex={0}
        >
          <MenuItemContentWrapper gridTemplateColumns={'1fr 1fr auto'}>
            <Stack direction={'row'} alignItems={'center'} gap={1} overflow={'hidden'}>
              <TeamAvatar size={'xsmall'} src={item.logoUrl} />
              <Typography fontSize={fontSizes.default} overflow={'hidden'} textOverflow={'ellipsis'}>
                {item.name}
              </Typography>
            </Stack>
            <Country countryCode={item.countryCode} showCountryName={false} showTooltip />
            <Stack direction={'row'} justifyContent={'flex-end'}>
              <FavoriteStarButton
                isFavorite={item.isFavorite}
                onClick={(event) => handleFavoritesButtonClick(event, item)}
                title={t(item.isFavorite ? 'favorites.remove' : 'favorites.add')}
              />
            </Stack>
          </MenuItemContentWrapper>
        </DashboardMenuItem>
      ))}
    </DashboardMenuList>
  );
};
