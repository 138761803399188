import { Button, Typography } from '@mui/material';
import { ReactNode } from 'react';
import { useLocation } from 'react-router-dom';

import { useIsGoogleAuthorized } from 'pages/backoffice/api/useIsGoogleAuthorized';
import {
  BackofficeLoadingContainer,
  BackofficeUnauthorizedContainer,
} from 'pages/backoffice/ui/backoffice-protected-route/styled';
import { redirectToGoogleLogin } from 'pages/backoffice/utils/redirectToGoogleLogin';
import Spinner from 'shared/components/spinner/Spinner';
import { ENVIRONMENT } from 'shared/utils/env-variables';

type ProtectedRouteProps = {
  children: ReactNode;
};

const isLocalhost = ENVIRONMENT !== 'production';

export const BackofficeProtectedRoute = ({ children }: ProtectedRouteProps) => {
  const location = useLocation();
  const { isBackofficeAuthorized, isLoading } = useIsGoogleAuthorized({ enabled: !isLocalhost });

  if (isLocalhost ? false : isLoading)
    return (
      <BackofficeLoadingContainer>
        <Spinner />
      </BackofficeLoadingContainer>
    );

  if (!isBackofficeAuthorized && !isLocalhost)
    return (
      <BackofficeUnauthorizedContainer>
        <Typography variant='h6'>Unauthorized</Typography>
        <Typography variant='body1' textAlign='center' marginBottom={2}>
          You can authorize with Google by clicking button below
        </Typography>
        <div>
          <Button variant='contained' onClick={() => redirectToGoogleLogin({ callbackUrl: location.pathname })}>
            Authorize with Google
          </Button>
        </div>
      </BackofficeUnauthorizedContainer>
    );

  return <>{children}</>;
};
