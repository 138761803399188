import React from 'react';

import { BackofficeTaskType } from 'pages/backoffice/api/types';
import { FormFieldProps } from 'pages/backoffice/pages/annotation/ui/form-fields/types';
import { AnnotationFormLabels } from 'pages/backoffice/pages/annotation/utils/form-fields-validation';
import { AnnotationFormFieldsNames } from 'pages/backoffice/pages/annotation/utils/form-fields-validation/types';
import { FormSelectField } from 'shared/components/form/ui/FormSelectField';
import { getTypeOptions } from 'shared/components/form/utils/getTypeOptions';

export const TaskTypeField = ({ errors = {}, register }: FormFieldProps) => {
  return (
    <FormSelectField
      id={AnnotationFormFieldsNames.TASK_TYPE}
      label={AnnotationFormLabels[AnnotationFormFieldsNames.TASK_TYPE]}
      options={getTypeOptions({ options: Object.values(BackofficeTaskType).map((option) => ({ value: option })) })}
      defaultValue={BackofficeTaskType.TRAJECTORIES}
      error={!!errors[AnnotationFormFieldsNames.TASK_TYPE]}
      helperText={
        errors[AnnotationFormFieldsNames.TASK_TYPE] && <>{errors[AnnotationFormFieldsNames.TASK_TYPE]?.message}</>
      }
      {...register(AnnotationFormFieldsNames.TASK_TYPE)}
    />
  );
};
