import { Competition } from 'shared/types/competition/competition';
import { CompetitionApi } from 'shared/types/competition/competitionApi';

export const transformCompetitions = (items: CompetitionApi[]): Competition[] => {
  return items.map((item) => ({
    id: item.uuid,
    name: item.name,
    country: item.country,
    logoUrl: item.logo_url,
    format: item.format,
    organization: item.organization
      ? {
          id: item.organization.uuid,
          name: item.organization.name,
          logoUrl: item.organization.logo_url,
        }
      : null,
    seasons: item.seasons.map((season) => ({
      id: season.uuid,
      year: season.year,
      crossesYear: season.crosses_year,
      name: season.name,
    })),
  }));
};
