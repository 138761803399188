import { TacticId } from '@kognia/tactical-analysis-data';
import { useCallback } from 'react';

import { useTimelineTactics } from 'pages/tactical-analysis/hooks/use-timeline-tactics';
import { OverlaySelectorPanel } from 'shared/components/overlay-selector-panel/OverlaySelectorPanel';
import { useCurrentPlaylistItem, useVideoPlayerActions, useVideoPlayerId } from 'shared/components/video-player/hooks';
import { useOverlaysFrameInfo } from 'shared/components/video-player/hooks/use-overlays-controller';
import { PlaylistItemType } from 'shared/components/video-player/types';

interface Props {
  recordingId: string;
}

export const TimelineOverlaysSelectorPanel = ({ recordingId }: Props) => {
  const { selectedTactics, setSelectedTactics, tactics } = useTimelineTactics(recordingId);
  const videoPlayerId = useVideoPlayerId();
  const currentPlaylistItem = useCurrentPlaylistItem();
  const actions = useVideoPlayerActions();
  const frameInfo = useOverlaysFrameInfo();
  const availableTactics = currentPlaylistItem.hasHomographies && currentPlaylistItem.showOverlays ? tactics : [];

  const handleChange = useCallback(
    (tactics: TacticId[]) => {
      const updatedPlaylistItem: PlaylistItemType[] = [
        {
          ...currentPlaylistItem,
          fundamentalsSelected: {
            tacticalAnalysisId: currentPlaylistItem.fundamentalsSelected.tacticalAnalysisId,
            fundamentalsSelected: tactics,
          },
        },
      ];

      setSelectedTactics(tactics);
      actions.updatePlaylistItems(updatedPlaylistItem);
    },
    [actions, currentPlaylistItem, setSelectedTactics],
  );

  return (
    <OverlaySelectorPanel
      id={videoPlayerId}
      availableTactics={availableTactics}
      frameTactics={frameInfo.frameTactics}
      initialSelectedTactics={selectedTactics}
      onTacticsChange={handleChange}
      isLoading={selectedTactics === undefined}
    />
  );
};
