import { nativeEnum, string, object, z } from 'zod';

import { OrganizationApi } from 'pages/backoffice/api/organizations/types';
import { SeasonSimpleApi } from 'pages/backoffice/api/seasons/types';
import {
  CompetitionFormat,
  CompetitionGender,
  CompetitionRegion,
  CompetitionTeamType,
} from 'pages/backoffice/types/competitions';
import { MappingProvider } from 'shared/types/mappings/mappingProvider';

export const competitionMappingSchema = object({
  provider: nativeEnum(MappingProvider),
  competition_id_provider: string(),
});

export type CompetitionMappingApi = z.infer<typeof competitionMappingSchema>;
export interface CompetitionApi {
  uuid: string;
  name: string;
  country: string | null;
  seasons: SeasonSimpleApi[];
  logo_url: string;
  format: CompetitionFormat | null;
  gender: CompetitionGender | null;
  tier: number | null;
  region: CompetitionRegion | null;
  team_type: CompetitionTeamType | null;
  organization: OrganizationApi | null;
  mappings: CompetitionMappingApi[];
}
