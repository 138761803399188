import React from 'react';
import { useTranslation } from 'react-i18next';

import { getPlayerName, getTeamType } from 'pages/tactical-analysis/components/tactical-analysis/utils/get-team-type';
import ActiveFilter, { DefaultVariant } from 'shared/components/active-filter/ActiveFilter';
import { MatchTeams } from 'shared/types/match';
import { RecordingFiltersTactic } from 'shared/types/recording/types';

interface Props {
  contained?: boolean;
  teamId?: string;
  fundamentals: RecordingFiltersTactic[];
  showCloseButton?: boolean;
  teams: MatchTeams;
  onClose: (fundamental: string) => void;
  onClosePlayer: (player: string, fundamental: string) => void;
}

const FundamentalsSummary = ({
  teamId = '',
  teams,
  contained,
  showCloseButton,
  fundamentals,
  onClose,
  onClosePlayer,
}: Props) => {
  const { t } = useTranslation();

  if (
    fundamentals.every(
      (fundamental) =>
        fundamental.tacticalFundamentalType === '' && fundamental.playerIds.every((player) => player === ''),
    )
  )
    return null;

  const fundamentalTeamType = teamId ? getTeamType(teamId, teams) : DefaultVariant.DEFAULT;

  return (
    <>
      {fundamentals.map((fundamental) => (
        <React.Fragment key={fundamental.tacticalFundamentalType}>
          {fundamental.tacticalFundamentalType && (
            <ActiveFilter
              onClose={() => onClose(fundamental.tacticalFundamentalType)}
              closeButton={showCloseButton}
              contained={contained}
              key={fundamental.tacticalFundamentalType}
              variant={fundamentalTeamType}
              title={t(`fundamentals:fundamentals.${fundamental.tacticalFundamentalType}`)}
            />
          )}
          {fundamental.playerIds.map(
            (player) =>
              player && (
                <ActiveFilter
                  onClose={() => onClosePlayer(player, fundamental.tacticalFundamentalType)}
                  closeButton={showCloseButton}
                  contained={contained}
                  key={`${fundamental.tacticalFundamentalType}-${player}`}
                  variant={fundamentalTeamType}
                  title={getPlayerName(player, [teams.homeTeam, teams.opponentTeam]) || ''}
                />
              ),
          )}
        </React.Fragment>
      ))}
    </>
  );
};

export default FundamentalsSummary;
