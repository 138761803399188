import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { useLiveSessionsWithFilters } from 'api/tagging-tool/use-live-sessions-with-filters';
import { RecordingListPageSkeleton } from 'pages/recordings-list/components/recording-list-page-skeleton';
import { TaggingRecordingList } from 'pages/tagging-tool-recording-list/components/tagging-recording-list/TaggingRecordingList';
import Container from 'shared/components/container/Container';
import { useBranding } from 'shared/hooks/use-branding/useBranding';

export const TaggingToolRecordingList = () => {
  const { t } = useTranslation();
  const { isLoading, isSuccess } = useLiveSessionsWithFilters();

  const branding = useBranding();

  useEffect(() => {
    document.title = t('common:metas.title.tagging', { clientDisplayName: branding.displayName });
  }, [branding.displayName, t]);

  if (isLoading) return <RecordingListPageSkeleton />;

  return <Container>{isSuccess && <TaggingRecordingList />}</Container>;
};
