import { useMutationRequest } from 'api/hooks/useMutationRequest';
import { HTTPMethod } from 'api/types';
import { annotationApiUrls } from 'pages/backoffice/api/routes';
import { CreateSeasonClientsApiParams } from 'pages/backoffice/api/season-clients/use-create-season-clients/types';
import { useInvalidateSeasonClients } from 'pages/backoffice/api/season-clients/useSeasonClients';

interface RequestData {
  data: CreateSeasonClientsApiParams;
  onSuccess?: () => void;
}

export const useCreateSeasonClients = () => {
  const invalidateSeasonClients = useInvalidateSeasonClients();
  const mutationRequest = useMutationRequest({
    type: HTTPMethod.POST,
    successMessage: 'Association season clients created',
    errorMessage: 'Association season clients create error',
    onSuccess: invalidateSeasonClients,
  });

  const create = ({ data, onSuccess }: RequestData) => {
    mutationRequest.mutate(
      {
        url: annotationApiUrls.SEASON_CLIENTS,
        data: {
          original_client_id: data.originalClientId,
          season_uuid: data.seasonId,
          client_ids: data.clientIds,
        },
      },
      { onSuccess },
    );
  };

  return { ...mutationRequest, create };
};
