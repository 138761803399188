import { Box, Divider, IconButton } from '@mui/material';
import { useSetAtom } from 'jotai';
import { IconBall } from 'kognia-ui/icons/IconBall';
import { IconDashboard } from 'kognia-ui/icons/IconDashboard';
import { IconDoubleArrowLeft } from 'kognia-ui/icons/IconDoubleArrowLeft';
import { IconDoubleArrowRight } from 'kognia-ui/icons/IconDoubleArrowRight';
import { IconDownload } from 'kognia-ui/icons/IconDownload';
import { IconHome } from 'kognia-ui/icons/IconHome';
import { IconInfo } from 'kognia-ui/icons/IconInfo';
import { IconKeypadEmpty } from 'kognia-ui/icons/IconKeypadEmpty';
import { IconPlaylist } from 'kognia-ui/icons/IconPlaylist';
import { IconTag } from 'kognia-ui/icons/IconTag';
import { IconTutorial } from 'kognia-ui/icons/IconTutorial';
import { IconUser } from 'kognia-ui/icons/IconUser';
import { ReactNode, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { FEATURE_FLAG } from 'api/user/use-fetch-feature-flags';
import { sidebarDrawerElement } from 'entities/download-manager/store/sidebarDrawerElement';
import { SidebarMenuItemDownloadManagerFeature } from 'features/download-manager/sidebar-menu-item-download-manager/SidebarMenuItemDownloadManager.feature';
import { routes } from 'kognia/router/routes';
import { KeyboardShortcutsModal } from 'shared/components/keyboard-shortcuts-modal/KeyboardShortcutsModal';
import {
  SidebarHeader,
  SidebarLayoutContent,
  SidebarLayoutSidebar,
  SidebarLayoutWrapper,
  SidebarLogoWrapper,
  UserAvatar,
} from 'shared/components/sidebar-layout/SideBarLayout.styled';
import { ClientDropdown } from 'shared/components/sidebar-layout/ui/client-dropdown/ClientDropdown';
import { SidebarLogo } from 'shared/components/sidebar-layout/ui/sidebar-logo/SidebarLogo';
import { MenuItem, MenuItemType, SidebarMenu } from 'shared/components/sidebar-layout/ui/sidebar-menu/SidebarMenu';
import { useFeatureFlag } from 'shared/contexts/app-state/hooks/useFeatureFlag';
import { useUser } from 'shared/contexts/app-state/hooks/useUser';
import { useSidebar } from 'shared/hooks/use-sidebar';
import { getIsFormTag } from 'shared/utils/is-form-tag';

interface Props {
  children: ReactNode;
}

export const SidebarLayout = ({ children }: Props) => {
  const navigate = useNavigate();
  const sidebarDrawerRef = useRef<HTMLDivElement>(null);
  const setSidebarDrawerElement = useSetAtom(sidebarDrawerElement);
  const [isKeyboardShortcutsModalOpen, setIsKeyboardShortcutsModalOpen] = useState(false);
  const showAppDashboards = useFeatureFlag(FEATURE_FLAG.APP_DASHBOARDS);
  const showAppExplore = useFeatureFlag(FEATURE_FLAG.APP_EXPLORE);
  const showAppDashboardPreview = useFeatureFlag(FEATURE_FLAG.APP_DASHBOARD_PREVIEW);
  const showExploreHomePage = useFeatureFlag(FEATURE_FLAG.APP_EXPLORE_HOME_PAGE);

  const { t } = useTranslation();
  const { isSidebarOpen, toggleSidebar } = useSidebar();
  const { firstName, lastName, avatarUrl } = useUser();

  const handleDrawerChange = useCallback(() => toggleSidebar(), [toggleSidebar]);
  const handleKeyboardShortcutsModalOpen = useCallback(() => setIsKeyboardShortcutsModalOpen(true), []);
  const handleKeyboardShortcutsModalClose = useCallback(() => setIsKeyboardShortcutsModalOpen(false), []);
  const toggleKeyboardShortcutsModal = useCallback(() => setIsKeyboardShortcutsModalOpen((prev) => !prev), []);

  useEffect(() => {
    setSidebarDrawerElement(sidebarDrawerRef.current);
  }, [setSidebarDrawerElement]);

  const handleKeyDown = useCallback(
    (event: KeyboardEvent) => {
      const isFormTag = getIsFormTag((event.target as HTMLElement).tagName);
      if (isFormTag) return;
      if (event.key === '?') {
        toggleKeyboardShortcutsModal();
      }
    },
    [toggleKeyboardShortcutsModal],
  );

  useEffect(() => {
    window.addEventListener('keydown', handleKeyDown);

    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, [handleKeyDown]);

  const primaryMenuItems: MenuItem[] = useMemo(() => {
    const items: MenuItem[] = [
      {
        label: t('common:navigation.home'),
        link: routes.HOME_PAGE,
        icon: <IconHome size='small' />,
        type: MenuItemType.LINK,
      },
      {
        label: t('common:navigation.tactical-analysis'),
        link: routes.RECORDING_LIST,
        icon: <IconBall size='small' />,
        type: MenuItemType.LINK,
      },
      {
        label: t('common:navigation.tagging'),
        link: routes.TAGGING_TOOL,
        icon: <IconTag size='small' />,
        type: MenuItemType.LINK,
      },
      {
        label: t('common:navigation.playlists'),
        link: routes.PLAYLISTS,
        icon: <IconPlaylist size='small' />,
        type: MenuItemType.LINK,
      },
      {
        label: t('common:navigation.keypads'),
        link: routes.KEYPADS,
        icon: <IconKeypadEmpty size='small' />,
        type: MenuItemType.LINK,
      },
      {
        label: t('Backoffice'),
        isAdminAccess: true,
        icon: <IconInfo size='small' />,
        onClick: () => navigate(routes.BACKOFFICE),
        type: MenuItemType.CUSTOM_CLICK,
      },
      {
        label: t('common:navigation.downloads'),
        link: routes.DOWNLOAD_MANAGER,
        icon: <IconDownload size='small' />,
        type: MenuItemType.LINK,
        CustomComponent: SidebarMenuItemDownloadManagerFeature,
      },
    ];

    const dashboardPreviewItem: MenuItem[] = showAppDashboardPreview
      ? [
          {
            label: 'Dashboard preview',
            link: routes.DASHBOARD_PREVIEW,
            icon: <IconDashboard size='small' />,
            type: MenuItemType.LINK,
          },
        ]
      : [];

    const dashboardItem: MenuItem[] =
      (showAppExplore || showAppDashboards) && !showExploreHomePage
        ? [
            {
              label: t('common:navigation.explore'),
              link: showAppExplore ? routes.EXPLORE : routes.PLAYER_PROFILE_DASHBOARD,
              icon: <IconDashboard size='small' />,
              type: MenuItemType.LINK,
            },
          ]
        : [];

    return [...items, ...dashboardItem, ...dashboardPreviewItem];
  }, [t, showAppDashboardPreview, showAppDashboards, showAppExplore, showExploreHomePage, navigate]);

  const footerMenuItems: MenuItem[] = useMemo(
    () => [
      {
        label: t('common:navigation.help'),
        icon: <IconTutorial size='small' />,
        type: MenuItemType.PARENT,
        items: [
          {
            label: t('common:navigation.feedback'),
            link: routes.SUBMIT_FEEDBACK,
            type: MenuItemType.LINK,
            bottomDivider: true,
          },
          {
            label: t('common:navigation.keyboard-shortcuts'),
            type: MenuItemType.CUSTOM_CLICK,
            onClick: handleKeyboardShortcutsModalOpen,
          },
        ],
      },
    ],
    [t, handleKeyboardShortcutsModalOpen],
  );

  const accountMenuItems: MenuItem[] = [
    {
      label: `${firstName} ${lastName}`,
      type: MenuItemType.PARENT,
      icon: (
        <UserAvatar>
          {avatarUrl ? <img src={avatarUrl} alt={`${firstName} ${lastName}`} /> : <IconUser size='small' />}
        </UserAvatar>
      ),
      items: [
        {
          label: t('common:navigation.user-profile'),
          link: routes.ACCOUNT,
          type: MenuItemType.LINK,
        },
        {
          label: t('common:actions.logout'),
          link: routes.LOGOUT,
          type: MenuItemType.LINK,
        },
      ],
    },
  ];

  return (
    <SidebarLayoutWrapper>
      <SidebarLayoutSidebar ref={sidebarDrawerRef} variant='permanent' open={isSidebarOpen}>
        <Box display='flex' flexDirection='column' justifyContent='space-between' height='100%'>
          <div>
            <SidebarHeader open={isSidebarOpen}>
              <SidebarLogoWrapper spacingBottom={!isSidebarOpen}>
                <SidebarLogo isSmall={!isSidebarOpen} />
              </SidebarLogoWrapper>
              <Box ml={isSidebarOpen ? 2 : 0}>
                <IconButton onClick={handleDrawerChange}>
                  {isSidebarOpen ? <IconDoubleArrowLeft size='small' /> : <IconDoubleArrowRight size='small' />}
                </IconButton>
              </Box>
            </SidebarHeader>

            <SidebarMenu menuItems={primaryMenuItems} isSidebarOpen={isSidebarOpen} />
          </div>
          <Box>
            <Box mb={1}>
              <SidebarMenu menuItems={accountMenuItems} isSidebarOpen={isSidebarOpen} />
            </Box>
            <ClientDropdown isSidebarOpen={isSidebarOpen} />
            <Divider sx={{ margin: ({ spacing }) => spacing(2, 0) }} />
            <SidebarMenu menuItems={footerMenuItems} isSidebarOpen={isSidebarOpen} />
          </Box>
        </Box>
      </SidebarLayoutSidebar>
      <SidebarLayoutContent component='main' open={isSidebarOpen}>
        {children}
      </SidebarLayoutContent>
      <KeyboardShortcutsModal isOpen={isKeyboardShortcutsModalOpen} onClose={handleKeyboardShortcutsModalClose} />
    </SidebarLayoutWrapper>
  );
};
