import { FormFieldProps } from 'pages/backoffice/pages/annotation/ui/form-fields/types';
import { AnnotationFormLabels } from 'pages/backoffice/pages/annotation/utils/form-fields-validation';
import { AnnotationFormFieldsNames } from 'pages/backoffice/pages/annotation/utils/form-fields-validation/types';
import { FormSwitchField } from 'shared/components/form/ui/FormSwitchField';

export const DryRunField = ({ register }: FormFieldProps) => {
  return (
    <FormSwitchField
      id={AnnotationFormFieldsNames.DRY_RUN}
      label={AnnotationFormLabels[AnnotationFormFieldsNames.DRY_RUN]}
      {...register(AnnotationFormFieldsNames.DRY_RUN)}
    />
  );
};
