import { zodResolver } from '@hookform/resolvers/zod';
import { Accordion, AccordionDetails, AccordionSummary, Button, Stack } from '@mui/material';
import { IconChevronDown } from 'kognia-ui/icons/IconChevronDown';
import { IconDelete } from 'kognia-ui/icons/IconDelete';
import { useCallback, useState } from 'react';
import { useForm } from 'react-hook-form';

import { ProcessFixturePreset } from 'pages/backoffice/entities/process-fixture/types/processFixture.types';
import { useHandleProcessFixtureSubmit } from 'pages/backoffice/widgets/process-fixture/ProcessFixtureForm/hooks/useHandleProcessFixtureSubmit';
import {
  eventsFilesFieldsNames,
  ProcessFixtureEventsProvider,
  ProcessFixtureFieldsNames,
  ProcessFixtureForm,
  processFixtureFormSchema,
  ProcessFixturePipelineType,
  ProcessFixtureTrackingProvider,
  trackingFilesFieldsNames,
} from 'pages/backoffice/widgets/process-fixture/ProcessFixtureForm/types/processFixtureForm.types';
import { BundesligaForm } from 'pages/backoffice/widgets/process-fixture/ProcessFixtureForm/ui/BundesligaForm';
import { ChyronHegoForm } from 'pages/backoffice/widgets/process-fixture/ProcessFixtureForm/ui/ChyronHegoForm';
import { HawkeyeForm } from 'pages/backoffice/widgets/process-fixture/ProcessFixtureForm/ui/HawkeyeForm';
import { OptaForm } from 'pages/backoffice/widgets/process-fixture/ProcessFixtureForm/ui/OptaForm';
import { SecondSpectrumForm } from 'pages/backoffice/widgets/process-fixture/ProcessFixtureForm/ui/SecondSpectrumForm';
import { SignalityForm } from 'pages/backoffice/widgets/process-fixture/ProcessFixtureForm/ui/SignalityForm';
import { SportlogiqForm } from 'pages/backoffice/widgets/process-fixture/ProcessFixtureForm/ui/SportlogiqForm';
import { DialogNew } from 'shared/components/dialog-new/DialogNew';
import { FormInputField } from 'shared/components/form/ui/FormInputField';
import { FormSelectField } from 'shared/components/form/ui/FormSelectField';
import { FormSwitchField } from 'shared/components/form/ui/FormSwitchField';
import { getTypeOptions } from 'shared/components/form/utils/getTypeOptions';

interface Props {
  selectedPreset: ProcessFixturePreset;
  defaultValues: Partial<ProcessFixtureForm>;
}

export const ProcessFixtureFormWidget = ({ defaultValues, selectedPreset }: Props) => {
  const [confirmationDialogOpen, setConfirmationDialogOpen] = useState(false);
  const handleProcessFixtureSubmit = useHandleProcessFixtureSubmit({
    onSubmit: () => setConfirmationDialogOpen(false),
  });
  const handleConfirmDialogOpen = useCallback(() => setConfirmationDialogOpen(true), []);
  const handleConfirmDialogClose = useCallback(() => setConfirmationDialogOpen(false), []);

  const {
    register,
    formState: { errors },
    unregister,
    getValues,
    handleSubmit,
    watch,
  } = useForm<processFixtureFormSchema>({
    resolver: zodResolver(processFixtureFormSchema),
    defaultValues: defaultValues,
  });

  const showProviderField =
    watch(ProcessFixtureFieldsNames.pipelineType) === ProcessFixturePipelineType.THIRD_PARTY_TACTICAL_TRACKING;
  const showTrackingProviderFields = (trackingProvider: ProcessFixtureTrackingProvider) =>
    showProviderField && watch(ProcessFixtureFieldsNames.trackingProvider) === trackingProvider;
  const showEventsProviderFields = (eventsProvider: ProcessFixtureEventsProvider) =>
    selectedPreset !== ProcessFixturePreset.SCOUTING &&
    showProviderField &&
    watch(ProcessFixtureFieldsNames.eventsProvider) === eventsProvider;

  const handleConfirmSubmit = useCallback(() => {
    handleProcessFixtureSubmit(getValues());
  }, [handleProcessFixtureSubmit, getValues]);

  const handleFormSubmit = useCallback(
    (values: ProcessFixtureForm) => {
      if (values[ProcessFixtureFieldsNames.restartProcessing]) {
        handleConfirmDialogOpen();
      } else {
        handleProcessFixtureSubmit(values);
      }
    },
    [handleConfirmDialogOpen, handleProcessFixtureSubmit],
  );

  return (
    <form onSubmit={handleSubmit(handleFormSubmit)}>
      <FormInputField
        id={ProcessFixtureFieldsNames.recordingId}
        label='Recording ID'
        error={!!errors[ProcessFixtureFieldsNames.recordingId]}
        fullWidth
        helperText={
          errors[ProcessFixtureFieldsNames.recordingId] && <>{errors[ProcessFixtureFieldsNames.recordingId]?.message}</>
        }
        {...register(ProcessFixtureFieldsNames.recordingId)}
      />

      <FormInputField
        id={ProcessFixtureFieldsNames.videoPath}
        label='Video path'
        error={!!errors[ProcessFixtureFieldsNames.videoPath]}
        fullWidth
        helperText={
          errors[ProcessFixtureFieldsNames.videoPath] && <>{errors[ProcessFixtureFieldsNames.videoPath]?.message}</>
        }
        {...register(ProcessFixtureFieldsNames.videoPath)}
      />

      <FormInputField
        id={ProcessFixtureFieldsNames.bucket}
        label='Bucket'
        error={!!errors[ProcessFixtureFieldsNames.bucket]}
        fullWidth
        helperText={
          errors[ProcessFixtureFieldsNames.bucket] && <>{errors[ProcessFixtureFieldsNames.bucket]?.message}</>
        }
        {...register(ProcessFixtureFieldsNames.bucket)}
      />

      <FormSelectField
        id={ProcessFixtureFieldsNames.pipelineType}
        label='Pipeline type'
        error={!!errors[ProcessFixtureFieldsNames.pipelineType]}
        defaultValue={defaultValues[ProcessFixtureFieldsNames.pipelineType]}
        disabled={Boolean(defaultValues[ProcessFixtureFieldsNames.pipelineType])}
        fullWidth
        options={getTypeOptions({
          options: Object.values(ProcessFixturePipelineType).map((value) => ({
            value,
          })),
        })}
        helperText={
          errors[ProcessFixtureFieldsNames.pipelineType] && (
            <>{errors[ProcessFixtureFieldsNames.pipelineType]?.message}</>
          )
        }
        {...register(ProcessFixtureFieldsNames.pipelineType)}
        onChange={(event) => {
          Object.values(trackingFilesFieldsNames).forEach((name) => unregister(name));
          Object.values(eventsFilesFieldsNames).forEach((name) => unregister(name));
          return register(ProcessFixtureFieldsNames.pipelineType).onChange(event);
        }}
      />

      {showProviderField ? (
        <FormSelectField
          id={ProcessFixtureFieldsNames.trackingProvider}
          label='Tracking provider'
          error={!!errors[ProcessFixtureFieldsNames.trackingProvider]}
          defaultValue={defaultValues[ProcessFixtureFieldsNames.trackingProvider]}
          disabled={Boolean(defaultValues[ProcessFixtureFieldsNames.trackingProvider])}
          fullWidth
          options={getTypeOptions({
            options: Object.values(ProcessFixtureTrackingProvider).map((value) => ({
              value,
            })),
          })}
          helperText={
            errors[ProcessFixtureFieldsNames.trackingProvider] && (
              <>{errors[ProcessFixtureFieldsNames.trackingProvider]?.message}</>
            )
          }
          {...register(ProcessFixtureFieldsNames.trackingProvider)}
          onChange={(event) => {
            Object.values(trackingFilesFieldsNames).forEach((name) => unregister(name));
            return register(ProcessFixtureFieldsNames.trackingProvider).onChange(event);
          }}
        />
      ) : null}

      {showTrackingProviderFields(ProcessFixtureTrackingProvider.SPORTLOGIQ) && (
        <SportlogiqForm
          register={register}
          errors={errors}
          defaultValue={defaultValues[ProcessFixtureFieldsNames.sportlogiqTrackingType]}
        />
      )}

      {showTrackingProviderFields(ProcessFixtureTrackingProvider.HAWKEYE) && (
        <HawkeyeForm register={register} errors={errors} />
      )}

      {showTrackingProviderFields(ProcessFixtureTrackingProvider.CHYRONHEGO) && (
        <ChyronHegoForm register={register} errors={errors} />
      )}

      {showTrackingProviderFields(ProcessFixtureTrackingProvider.SIGNALITY) && (
        <SignalityForm register={register} errors={errors} />
      )}

      {showTrackingProviderFields(ProcessFixtureTrackingProvider.SECOND_SPECTRUM) && (
        <SecondSpectrumForm register={register} errors={errors} />
      )}

      {showProviderField ? (
        <FormSelectField
          id={ProcessFixtureFieldsNames.eventsProvider}
          label='Events provider'
          error={!!errors[ProcessFixtureFieldsNames.eventsProvider]}
          defaultValue={defaultValues[ProcessFixtureFieldsNames.eventsProvider]}
          disabled={Boolean(defaultValues[ProcessFixtureFieldsNames.eventsProvider])}
          fullWidth
          options={getTypeOptions({
            options: Object.values(ProcessFixtureEventsProvider).map((value) => ({
              value,
            })),
          })}
          helperText={
            errors[ProcessFixtureFieldsNames.eventsProvider] && (
              <>{errors[ProcessFixtureFieldsNames.eventsProvider]?.message}</>
            )
          }
          {...register(ProcessFixtureFieldsNames.eventsProvider)}
          onChange={(event) => {
            Object.values(eventsFilesFieldsNames).forEach((name) => unregister(name));
            return register(ProcessFixtureFieldsNames.eventsProvider).onChange(event);
          }}
        />
      ) : null}

      {showEventsProviderFields(ProcessFixtureEventsProvider.OPTA) && <OptaForm register={register} errors={errors} />}

      {showEventsProviderFields(ProcessFixtureEventsProvider.BUNDESLIGA) && (
        <BundesligaForm register={register} errors={errors} />
      )}

      <Accordion sx={{ marginBottom: 2 }}>
        <AccordionSummary expandIcon={<IconChevronDown size='small' />}>Optional</AccordionSummary>
        <AccordionDetails>
          <FormSwitchField
            id={ProcessFixtureFieldsNames.runProcessing}
            label='Run processing'
            defaultChecked={defaultValues[ProcessFixtureFieldsNames.runProcessing]}
            {...register(ProcessFixtureFieldsNames.runProcessing)}
          />
          <FormSwitchField
            id={ProcessFixtureFieldsNames.restartProcessing}
            label='Restart processing'
            defaultChecked={defaultValues[ProcessFixtureFieldsNames.restartProcessing]}
            {...register(ProcessFixtureFieldsNames.restartProcessing)}
          />
          <FormSwitchField
            id={ProcessFixtureFieldsNames.showOverlays}
            label='Show overlays'
            defaultChecked={defaultValues[ProcessFixtureFieldsNames.showOverlays]}
            {...register(ProcessFixtureFieldsNames.showOverlays)}
          />
          <FormSwitchField
            id={ProcessFixtureFieldsNames.autoRunTacticalAnalysis}
            label='Auto run tactical analysis'
            defaultChecked={defaultValues[ProcessFixtureFieldsNames.autoRunTacticalAnalysis]}
            {...register(ProcessFixtureFieldsNames.autoRunTacticalAnalysis)}
          />
        </AccordionDetails>
      </Accordion>

      <DialogNew
        title='Confirmation'
        icon={<IconDelete />}
        onCancel={handleConfirmDialogClose}
        onClose={handleConfirmDialogClose}
        onSubmit={handleConfirmSubmit}
        buttonSubmitText='Delete'
        open={confirmationDialogOpen}
        error
      >
        This will delete the current data for <strong>{getValues(ProcessFixtureFieldsNames.recordingId)}</strong> and
        restart the process.
      </DialogNew>

      <Stack direction='row' justifyContent='flex-end' spacing={1}>
        <Button variant='contained' type='submit'>
          Submit
        </Button>
      </Stack>
    </form>
  );
};
