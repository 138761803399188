import { FormControl, FormHelperText, Input, MenuItem, Select, TextField } from '@mui/material';
import { useMemo } from 'react';
import { Control, Controller, FieldErrors, UseFormRegister } from 'react-hook-form';

import { SelectOrganization } from 'pages/backoffice/features/SelectOrganization';
import {
  CompetitionFormFieldsNames,
  CompetitionFormSchema,
} from 'pages/backoffice/pages/competitions/ui/competition-form/types';
import {
  CompetitionFormat,
  CompetitionGender,
  CompetitionRegion,
  CompetitionTeamType,
} from 'pages/backoffice/types/competitions';
import { FormFormLabel } from 'shared/components/form/ui/FormFormLabel';
import { Countries } from 'shared/utils/countries';

interface Props {
  register: UseFormRegister<CompetitionFormSchema>;
  errors: FieldErrors<CompetitionFormSchema>;
  control: Control<CompetitionFormSchema>;
}

export function CompetitionsForm({ register, errors, control }: Props) {
  const countryCodes = useMemo(
    () =>
      Countries.map((country) => (
        <MenuItem key={country.code.toLowerCase()} value={country.code.toUpperCase()}>
          {country.name}
        </MenuItem>
      )),
    [],
  );

  const formats = useMemo(
    () =>
      Object.values(CompetitionFormat).map((value) => (
        <MenuItem key={value} value={value}>
          {value}
        </MenuItem>
      )),
    [],
  );

  const genders = useMemo(
    () =>
      Object.values(CompetitionGender).map((value) => (
        <MenuItem key={value} value={value}>
          {value}
        </MenuItem>
      )),
    [],
  );

  const regions = useMemo(
    () =>
      Object.values(CompetitionRegion).map((value) => (
        <MenuItem key={value} value={value}>
          {value}
        </MenuItem>
      )),
    [],
  );

  const teamTypes = useMemo(
    () =>
      Object.values(CompetitionTeamType).map((value) => (
        <MenuItem key={value} value={value}>
          {value}
        </MenuItem>
      )),
    [],
  );

  return (
    <>
      <FormControl fullWidth margin='normal'>
        <FormFormLabel>Competition Name</FormFormLabel>
        <TextField
          size='small'
          {...register(CompetitionFormFieldsNames.name)}
          error={!!errors[CompetitionFormFieldsNames.name]}
          helperText={errors[CompetitionFormFieldsNames.name] && <>{errors[CompetitionFormFieldsNames.name].message}</>}
        />
      </FormControl>
      <Controller
        control={control}
        name={CompetitionFormFieldsNames.country}
        render={({ field: { onChange, value, name }, fieldState: { error } }) => (
          <FormControl error={!!error} fullWidth margin='normal'>
            <FormFormLabel>Country {value}</FormFormLabel>
            <Select size={'small'} labelId={`${name}-label`} id={name} value={value} onChange={onChange} displayEmpty>
              <MenuItem value={''}>No Country</MenuItem>
              {countryCodes}
            </Select>
            {error && <FormHelperText error>{error.message}</FormHelperText>}
          </FormControl>
        )}
      />

      <FormControl fullWidth margin='normal'>
        <FormFormLabel>Image URL</FormFormLabel>
        <Input
          type='file'
          size='small'
          {...register(CompetitionFormFieldsNames.competitionImage)}
          error={!!errors[CompetitionFormFieldsNames.competitionImage]}
        />
        {errors[CompetitionFormFieldsNames.competitionImage] && (
          <FormHelperText error>
            <>{errors[CompetitionFormFieldsNames.competitionImage].message}</>
          </FormHelperText>
        )}
      </FormControl>

      <Controller
        control={control}
        name={CompetitionFormFieldsNames.format}
        render={({ field: { onChange, value, name }, fieldState: { error } }) => (
          <FormControl error={!!error} fullWidth margin='normal'>
            <FormFormLabel>Format</FormFormLabel>
            <Select size={'small'} labelId={`${name}-label`} id={name} value={value} onChange={onChange} displayEmpty>
              <MenuItem value={''}>None</MenuItem>
              {formats}
            </Select>
            {error && <FormHelperText error>{error.message}</FormHelperText>}
          </FormControl>
        )}
      />
      <Controller
        control={control}
        name={CompetitionFormFieldsNames.gender}
        render={({ field: { onChange, value, name }, fieldState: { error } }) => (
          <FormControl error={!!error} fullWidth margin='normal'>
            <FormFormLabel>Gender</FormFormLabel>
            <Select size={'small'} labelId={`${name}-label`} id={name} value={value} onChange={onChange} displayEmpty>
              <MenuItem value={''}>None</MenuItem>
              {genders}
            </Select>
            {error && <FormHelperText error>{error.message}</FormHelperText>}
          </FormControl>
        )}
      />

      <Controller
        control={control}
        name={CompetitionFormFieldsNames.region}
        render={({ field: { onChange, value, name }, fieldState: { error } }) => (
          <FormControl error={!!error} fullWidth margin='normal'>
            <FormFormLabel>Region</FormFormLabel>
            <Select size={'small'} labelId={`${name}-label`} id={name} value={value} onChange={onChange} displayEmpty>
              <MenuItem value={''}>None</MenuItem>
              {regions}
            </Select>
            {error && <FormHelperText error>{error.message}</FormHelperText>}
          </FormControl>
        )}
      />

      <Controller
        control={control}
        name={CompetitionFormFieldsNames.teamType}
        render={({ field: { onChange, value, name }, fieldState: { error } }) => (
          <FormControl error={!!error} fullWidth margin='normal'>
            <FormFormLabel>Team type</FormFormLabel>
            <Select size={'small'} labelId={`${name}-label`} id={name} value={value} onChange={onChange} displayEmpty>
              <MenuItem value={''}>None</MenuItem>
              {teamTypes}
            </Select>
            {error && <FormHelperText error>{error.message}</FormHelperText>}
          </FormControl>
        )}
      />

      <FormControl fullWidth margin='normal'>
        <FormFormLabel>Tier</FormFormLabel>
        <TextField
          size='small'
          type='number'
          {...register(CompetitionFormFieldsNames.tier)}
          error={!!errors[CompetitionFormFieldsNames.tier]}
          helperText={errors[CompetitionFormFieldsNames.tier] && <>{errors[CompetitionFormFieldsNames.tier].message}</>}
        />
      </FormControl>

      <Controller
        control={control}
        name={CompetitionFormFieldsNames.organizationId}
        render={({ field: { onChange, value }, fieldState: { error } }) => (
          <FormControl error={!!error} fullWidth margin='normal'>
            <FormFormLabel>Organization</FormFormLabel>
            <SelectOrganization onChange={onChange} defaultSelectedId={value} />
            {error && <FormHelperText error>{error.message}</FormHelperText>}
          </FormControl>
        )}
      />
    </>
  );
}
