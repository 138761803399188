import { Breadcrumbs, Typography, Button, Box, breadcrumbsClasses } from '@mui/material';
import { IconChevronRight } from 'kognia-ui/icons/IconChevronRight';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import {
  useCompetitionsState,
  useSetCompetitionsState,
  useResetCompetitionsState,
} from 'entities/competitions/store/steps';
import { CompetitionFormat } from 'pages/backoffice/types/competitions';
import { invariant } from 'shared/utils/invariant';

const MIN_FIRST_BREADCRUMB_WIDTH = '50px';
const MIN_BREADCRUMB_WIDTH = '30px';
const FIRST_BREADCRUMB_INDEX = 'first-competitions-breadcrumb';

interface BreadcrumbsData {
  name: string;
  onClick?: () => void;
}

export const CompetitionsBreadcrumbsFeature = () => {
  const { competition, team, step } = useCompetitionsState();
  const resetCompetitionsState = useResetCompetitionsState();
  const setCompetitionsState = useSetCompetitionsState();
  const { t } = useTranslation('explore');

  const breadcrumbs: BreadcrumbsData[] = useMemo(() => {
    if (step === 'competitions') {
      return [];
    }
    invariant(competition?.name);

    const crumbFormatLabel =
      competition.format === CompetitionFormat.LEAGUE ? t('competitions.leagues') : t('competitions.cups');
    const crumbs: BreadcrumbsData[] = [
      { name: crumbFormatLabel, onClick: resetCompetitionsState },
      {
        name: competition.name,
        onClick: () =>
          setCompetitionsState((prev) => {
            return {
              ...prev,
              step: 'teams',
            };
          }),
      },
    ];

    if (step === 'players') {
      invariant(team?.name);

      crumbs.push({ name: team.name });
    }

    return crumbs;
  }, [step, competition, t, resetCompetitionsState, setCompetitionsState, team?.name]);

  if (step === 'competitions') {
    return null;
  }

  return (
    <Breadcrumbs
      separator={<IconChevronRight size='small' />}
      sx={{
        [`& .${breadcrumbsClasses.li}`]: {
          minWidth: MIN_BREADCRUMB_WIDTH,
        },
        [`& .${breadcrumbsClasses.li}:has(#${FIRST_BREADCRUMB_INDEX})`]: {
          minWidth: MIN_FIRST_BREADCRUMB_WIDTH,
        },
      }}
    >
      {breadcrumbs.map(({ name, onClick }, index) => {
        const isLastIndex = index === breadcrumbs.length - 1;

        if (isLastIndex) {
          return (
            <Box key={name} whiteSpace='nowrap' textOverflow='ellipsis' overflow='hidden'>
              <Typography title={name} fontWeight='fontWeightMedium' variant='caption' component='span'>
                {name}
              </Typography>
            </Box>
          );
        }

        return (
          <Button
            id={index === 0 ? FIRST_BREADCRUMB_INDEX : undefined}
            key={name}
            variant='text'
            onClick={onClick}
            color='inherit'
            sx={{
              p: 0,
              minWidth: index === 0 ? MIN_FIRST_BREADCRUMB_WIDTH : MIN_BREADCRUMB_WIDTH,
              maxWidth: '100%',
            }}
          >
            <Typography
              title={name}
              variant='caption'
              component='span'
              whiteSpace='nowrap'
              textOverflow='ellipsis'
              overflow='hidden'
            >
              {name}
            </Typography>
          </Button>
        );
      })}
    </Breadcrumbs>
  );
};
