import { Alert, Stack } from '@mui/material';
import { IconOverlays } from 'kognia-ui/icons/IconOverlays';

import { useTacticalAnalyses } from 'api/tactical-analysis/useTacticalAnalyses';
import { TacticalAnalysesList } from 'pages/backoffice/pages/fixtures/components/metadata-fixture-item/tactical-analyses-dialog/ui/TacticalAnalysesList';
import { DialogNew } from 'shared/components/dialog-new/DialogNew';
import Spinner from 'shared/components/spinner/Spinner';

interface Props {
  open: boolean;
  recordingIds: string[];
  onClose: () => void;
}

export const TacticalAnalysesDialog = ({ open, recordingIds, onClose }: Props) => {
  const { data, isPending } = useTacticalAnalyses({ recordingIds });

  return (
    <DialogNew
      maxWidth='md'
      fullWidth
      title='Toggle overlays'
      icon={<IconOverlays size='small' />}
      onCancel={onClose}
      onClose={onClose}
      buttonSubmitText={'Apply'}
      open={open}
    >
      <>
        {isPending ? (
          <Stack justifyContent='center' alignItems='center'>
            <Spinner size='medium' />
          </Stack>
        ) : (
          <>
            {!data || !data.length ? (
              <Alert variant={'outlined'} severity={'warning'} sx={{ display: 'flex', justifyContent: 'center' }}>
                No tactical analyses
              </Alert>
            ) : (
              <TacticalAnalysesList data={data} />
            )}
          </>
        )}
      </>
    </DialogNew>
  );
};
