import { array, boolean, nativeEnum, number, object, string } from 'zod';

import { CompetitionFormat } from 'pages/backoffice/types/competitions';

export const competitionSchema = object({
  id: string(),
  name: string(),
  country: string().nullable(),
  format: nativeEnum(CompetitionFormat).nullable(),
  logoUrl: string().nullable().optional(),
  seasons: array(
    object({
      id: string(),
      year: number(),
      crossesYear: boolean(),
      name: string(),
      crossYearValue: string().optional(),
    }),
  ),
  organization: object({
    id: string(),
    name: string(),
    logoUrl: string().nullable(),
  }).nullable(),
});
