import { List, ListItem, Typography } from '@mui/material';
import { useMemo, useState } from 'react';

import { useUpdateTacticalAnalysisOverlays } from 'api/tactical-analysis/useUpdateTacticalAnalysisOverlays';
import { TacticalAnalysesTable } from 'pages/backoffice/pages/fixtures/components/metadata-fixture-item/tactical-analyses-dialog/ui/TacticalAnalysesTable';
import ConfirmDialog from 'shared/components/confirm-dialog/ConfirmDialog';
import { TacticalAnalysis } from 'shared/types/recording/types';

interface Props {
  data: TacticalAnalysis[];
}

const createGroupedData = (data: TacticalAnalysis[]) => {
  const groupedData = data.reduce<Record<string, TacticalAnalysis[]>>((acc, item) => {
    if (!acc[item.recordingId]) {
      acc[item.recordingId] = [];
    }

    acc[item.recordingId].push(item);
    return acc;
  }, {});

  return Object.entries(groupedData);
};

export const TacticalAnalysesList = ({ data }: Props) => {
  const [tacticAnalysisForChange, setTacticAnalysisForChange] = useState<TacticalAnalysis | null>(null);
  const groupedTacticalAnalysesByRecordingId = useMemo(() => createGroupedData(data), [data]);
  const { toggleOverlays } = useUpdateTacticalAnalysisOverlays();

  const handleCloseConfirmDialog = () => {
    setTacticAnalysisForChange(null);
  };

  const handleChangeTacticAnalysis = () => {
    if (!tacticAnalysisForChange) {
      return;
    }

    toggleOverlays({
      tacticalAnalysisId: tacticAnalysisForChange.id,
      showOverlays: tacticAnalysisForChange.showOverlays,
    });
  };

  return (
    <>
      <List sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
        {groupedTacticalAnalysesByRecordingId.map(([group, groupData]) => (
          <ListItem key={group} sx={{ display: 'flex', flexDirection: 'column', alignItems: 'start', gap: 1 }}>
            <Typography variant='h6'>{group}</Typography>
            <List disablePadding sx={{ display: 'flex', flexDirection: 'column', gap: 2, width: '100%' }}>
              {groupData.map((item) => (
                <ListItem key={item.id} disablePadding>
                  <TacticalAnalysesTable data={item} setTacticalAnalysis={setTacticAnalysisForChange} />
                </ListItem>
              ))}
            </List>
          </ListItem>
        ))}
      </List>

      <ConfirmDialog
        type={'warning'}
        title={`You sure you want to ${
          tacticAnalysisForChange?.showOverlays ? 'show' : 'hide'
        } overlays for ${tacticAnalysisForChange?.id}`}
        cancelLabel={'Cancel'}
        confirmLabel={'Confirm'}
        onConfirm={handleChangeTacticAnalysis}
        isOpen={!!tacticAnalysisForChange}
        setIsOpen={handleCloseConfirmDialog}
      />
    </>
  );
};
