import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';

import { useResetPlaylistsFilters } from 'api/playlist/useFetchPlaylists/atoms';
import { playlistPageQueryParams, routes } from 'kognia/router/routes';
import { CopyPlaylist } from 'pages/playlists/components/copy-playlist';
import { PlaylistsList } from 'pages/playlists/components/playlists-list';
import { usePlaylistsWithQueryStringState } from 'pages/playlists/components/playlists-list/hooks/usePlaylistsWithQueryStringState';
import PlaylistsSkeleton from 'pages/playlists/components/playlists-skeleton';
import Container from 'shared/components/container/Container';
import { useBranding } from 'shared/hooks/use-branding/useBranding';
import { useQueryParams } from 'shared/hooks/use-query-params';

export const PlaylistsContainer = () => {
  const { t } = useTranslation();
  const query = useQueryParams();
  const branding = useBranding();
  const resetPlaylistsFilters = useResetPlaylistsFilters(routes.PLAYLISTS);
  const location = useLocation();
  const { isPending } = usePlaylistsWithQueryStringState();

  const copyPlaylistQueryParam: string | null = query.get(playlistPageQueryParams.copyPlaylist);

  useEffect(() => {
    document.title = t('common:metas.title.playlists', { clientDisplayName: branding.displayName });
  }, [branding.displayName, t]);

  useEffect(() => {
    if (location.search === '') {
      resetPlaylistsFilters();
    }
  }, [location.search, resetPlaylistsFilters]);

  return (
    <Container>
      {isPending ? (
        <PlaylistsSkeleton />
      ) : (
        <>
          <PlaylistsList />
          {copyPlaylistQueryParam ? <CopyPlaylist playlistId={copyPlaylistQueryParam} /> : null}
        </>
      )}
    </Container>
  );
};
