import { InfinityQueryDataResult } from 'api/hooks/useInfinityQuery/types';
import { Venue, VenueApi, VenuesApiResponse } from 'pages/backoffice/api/venues/use-venues/types';
import { chooseNextCursorValue } from 'shared/utils/choose-next-cursor-value';

const transformData = (items: VenueApi[]): Venue[] => {
  return items.map((item) => ({
    id: item.uuid,
    name: item.name,
    city: item.city,
    countryCode: item.country_code,
    mappings: item.mappings.map((mapping) => ({
      provider: mapping.provider,
      idProvider: mapping.venue_id_provider,
    })),
  }));
};

export const transformVenues = (response: VenuesApiResponse): InfinityQueryDataResult<Venue> => {
  return {
    data: {
      items: transformData(response.data),
      page: response.page,
    },
    nextCursor: chooseNextCursorValue(response.page.totalPages, response.page.number),
  };
};
