import { Checkbox } from '@mui/material';
import classNames from 'classnames';
import React from 'react';
import { useTranslation } from 'react-i18next';

import styles from 'shared/components/filter-player/ui/filter-players-list/FilterPlayersList.module.scss';
import { MatchTeam } from 'shared/types';

export type PlayersListProps = {
  team: MatchTeam;
  values: string[];
  filteredPlayers?: Set<string>;
  handleChange: (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => void;
};

export const PlayersList = ({ team, values, handleChange, filteredPlayers }: PlayersListProps) => {
  const { t } = useTranslation();

  return (
    <>
      <div key={`all-${team.id}`} className={styles.listItem}>
        <Checkbox id={`all-${team.id}`} checked={values.length === 0} onChange={handleChange} />
        <div className={styles.name}>{t('recordings:filters:players:all-option')}</div>
      </div>

      {team.players.map((player) => {
        const isPlayerSelected = values.includes(player.id);
        const isPlayerDisabled = filteredPlayers && !filteredPlayers.has(player.id);
        const isPlayerSelectionInvalid = isPlayerSelected && isPlayerDisabled;

        return (
          <div
            key={player.id}
            className={classNames(styles.listItem, {
              [styles.disabled]: isPlayerDisabled,
              [styles.invalid]: isPlayerSelectionInvalid,
            })}
          >
            <Checkbox
              id={player.id}
              checked={isPlayerSelected}
              onChange={handleChange}
              color={isPlayerSelectionInvalid ? 'error' : 'primary'}
            />
            <div className={styles.name}>
              {player.number && `${player.number}.`}
              {player.name}
            </div>
          </div>
        );
      })}
    </>
  );
};
