import { zodResolver } from '@hookform/resolvers/zod';
import { AccordionActions, AccordionDetails } from '@mui/material';
import React, { useCallback } from 'react';
import { useForm } from 'react-hook-form';

import { useLabelsTask } from 'pages/backoffice/api/operations/useLabelsTask';
import { AnnotationFormActions } from 'pages/backoffice/pages/annotation/ui/AnnotationFormActions';
import { AnnotationToolField } from 'pages/backoffice/pages/annotation/ui/form-fields/AnnotationToolField';
import { IndexTypeGroup } from 'pages/backoffice/pages/annotation/ui/form-fields/IndexTypeGroup';
import { RecordingIdField } from 'pages/backoffice/pages/annotation/ui/form-fields/RecordingIdField';
import { TaskTypeField } from 'pages/backoffice/pages/annotation/ui/form-fields/TaskTypeField';
import { labelsTaskFormSchema } from 'pages/backoffice/pages/annotation/utils/form-fields-validation';
import { LabelsForm, LabelsTaskFormSchema } from 'pages/backoffice/pages/annotation/utils/form-fields-validation/types';

export const LabelsTask = () => {
  const { labelsTask } = useLabelsTask();
  const {
    register,
    formState: { errors },
    reset,
    resetField,
    handleSubmit: handleFormSubmit,
  } = useForm<LabelsTaskFormSchema>({
    resolver: zodResolver(labelsTaskFormSchema),
  });

  const handleSubmit = useCallback(
    (data: LabelsForm) => {
      labelsTask({ data });
    },
    [labelsTask],
  );

  return (
    <form onSubmit={handleFormSubmit(handleSubmit)}>
      <AccordionDetails>
        <RecordingIdField errors={errors} register={register} />
        <TaskTypeField errors={errors} register={register} />
        <AnnotationToolField errors={errors} register={register} />
        <IndexTypeGroup register={register} errors={errors} resetField={resetField} />
      </AccordionDetails>
      <AccordionActions>
        <AnnotationFormActions reset={() => reset()} />
      </AccordionActions>
    </form>
  );
};
