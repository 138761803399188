import { useMutationRequest } from 'api/hooks/useMutationRequest';
import { HTTPMethod } from 'api/types';
import { annotationApiUrls } from 'pages/backoffice/api/routes';
import { ValidateTaskApiParams } from 'pages/backoffice/api/types';
import { useAllowedBackofficeOrigin } from 'pages/backoffice/api/useAllowedBackofficeOrigin';
import { metadataApiErrorParser, MetadataApiErrorResponse } from 'pages/backoffice/api/utils';
import { getQueryParams } from 'pages/backoffice/utils/getQueryParams';

interface ValidateTaskParams {
  data: ValidateTaskApiParams;
}

export const useValidateTask = () => {
  const { getIsAllowedBackofficeOrigin } = useAllowedBackofficeOrigin();
  const mutationRequest = useMutationRequest<unknown, unknown, MetadataApiErrorResponse>({
    type: HTTPMethod.POST,
    successMessage: 'Validation task success',
    errorMessage: (data) => metadataApiErrorParser(data, 'Validation task error'),
  });

  const validateTask = ({ data }: ValidateTaskParams) => {
    const queryParams = getQueryParams<ValidateTaskApiParams>(data);
    if (!queryParams) return;
    if (!getIsAllowedBackofficeOrigin()) return;

    mutationRequest.mutate({ url: `${annotationApiUrls.VALIDATE_TASK}?${queryParams}` });
  };

  return { ...mutationRequest, validateTask };
};
