import { TypeOf } from 'zod';

import { seasonClientsFormSchema } from 'pages/backoffice/pages/associate-season-with-clients/ui/season-clients-form/form';
import { Client } from 'shared/types/user/types';

export enum SeasonClientsFormFieldsNames {
  id = 'id',
  originalClient = 'originalClient',
  seasonId = 'seasonId',
  clientIds = 'clientIds',
  seasonName = 'seasonName',
}

export interface SeasonClientsForm {
  [SeasonClientsFormFieldsNames.id]?: string;
  [SeasonClientsFormFieldsNames.originalClient]: Client;
  [SeasonClientsFormFieldsNames.seasonName]?: string;
  [SeasonClientsFormFieldsNames.seasonId]?: string;
  [SeasonClientsFormFieldsNames.clientIds]: string[];
}

export type SeasonClientsFormSchema = TypeOf<typeof seasonClientsFormSchema>;
