import { List } from '@mui/material';

import { SeasonClientsItem } from 'pages/backoffice/pages/associate-season-with-clients/ui/season-clients-item/SeasonClientsItem';
import { SeasonClients } from 'shared/types/user/types';

interface Props {
  seasonClientsList: SeasonClients[];
}

export const SeasonClientsList = ({ seasonClientsList }: Props) => {
  const seasonClientsItems = seasonClientsList.map((seasonClients) => {
    return <SeasonClientsItem key={seasonClients.id} seasonClients={seasonClients} />;
  });

  return <List>{seasonClientsItems}</List>;
};
