import { Box, Stack, Tab, Tabs } from '@mui/material';
import { SyntheticEvent, useCallback, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useCompetitionsInfinityQuery } from 'api/competitions/use-competitions-infinity-query/useCompetitionsInfinityQuery';
import { useSetCompetitionsState } from 'entities/competitions/store/steps';
import { CompetitionRow } from 'features/competitions/competitions-step/ui/competition-row/CompetitionRow';
import { CompetitionFormat, CompetitionRegion } from 'pages/backoffice/types/competitions';
import { DashboardMenuItem } from 'shared/components/DashboardMenuItem';
import { DashboardMenuList } from 'shared/components/DashboardMenuList';
import Spinner from 'shared/components/spinner/Spinner';
import { useIntersectionObserver } from 'shared/hooks/use-intersection-observer/useIntersectionObserver';
import { Competition } from 'shared/types/competition/competition';

function a11yProps(index: string) {
  return {
    id: `competition-tab-${index}`,
    'aria-controls': `competitions-tab-panel-${index}`,
  };
}

type SelectedTabValue = CompetitionFormat.LEAGUE | CompetitionFormat.CUP | CompetitionRegion.INTERNATIONAL;

export const CompetitionsStepFeature = () => {
  const { t } = useTranslation('explore');
  const [selectedTab, setSelectedTab] = useState<SelectedTabValue>(CompetitionFormat.LEAGUE);
  const {
    items: leagueItems,
    isLoading: leagueIsLoading,
    fetchNextPage: leagueFetchNextPage,
    hasNextPage: leagueHasNextPage,
  } = useCompetitionsInfinityQuery({ initialFilters: { format: CompetitionFormat.LEAGUE } });
  const {
    items: cupItems,
    isLoading: cupIsLoading,
    fetchNextPage: cupFetchNextPage,
    hasNextPage: cupHasNextPage,
  } = useCompetitionsInfinityQuery({
    initialFilters: { format: CompetitionFormat.CUP },
  });
  const {
    items: internationalItems,
    isLoading: internationalIsLoading,
    fetchNextPage: internationalFetchNextPage,
    hasNextPage: internationalHasNextPage,
  } = useCompetitionsInfinityQuery({
    initialFilters: { region: CompetitionRegion.INTERNATIONAL },
  });
  const lastItemRef = useRef<HTMLLIElement | null>(null);
  const setCompetitionsState = useSetCompetitionsState();
  const handleChange = (_event: SyntheticEvent, newValue: SelectedTabValue) => {
    setSelectedTab(newValue);
  };

  const hasNextPage = useMemo(() => {
    switch (selectedTab) {
      case CompetitionFormat.LEAGUE:
        return leagueHasNextPage;
      case CompetitionFormat.CUP:
        return cupHasNextPage;
      case CompetitionRegion.INTERNATIONAL:
        return internationalHasNextPage;
      default:
        return leagueHasNextPage;
    }
  }, [cupHasNextPage, internationalHasNextPage, leagueHasNextPage, selectedTab]);

  const isLoading = useMemo(
    () => leagueIsLoading || cupIsLoading || internationalIsLoading,
    [cupIsLoading, internationalIsLoading, leagueIsLoading],
  );

  const fetchNextPage = useMemo(() => {
    switch (selectedTab) {
      case CompetitionFormat.LEAGUE:
        return leagueFetchNextPage;
      case CompetitionFormat.CUP:
        return cupFetchNextPage;
      case CompetitionRegion.INTERNATIONAL:
        return internationalFetchNextPage;
      default:
        return leagueFetchNextPage;
    }
  }, [cupFetchNextPage, internationalFetchNextPage, leagueFetchNextPage, selectedTab]);
  const items = useMemo(() => {
    switch (selectedTab) {
      case CompetitionFormat.LEAGUE:
        return leagueItems;
      case CompetitionFormat.CUP:
        return cupItems;
      case CompetitionRegion.INTERNATIONAL:
        return internationalItems;
      default:
        return leagueItems;
    }
  }, [cupItems, internationalItems, leagueItems, selectedTab]);

  const handleObserver = useCallback(() => {
    if (!hasNextPage || isLoading) {
      return;
    }

    fetchNextPage();
  }, [fetchNextPage, hasNextPage, isLoading]);

  useIntersectionObserver(handleObserver, lastItemRef);

  const handleGotToTeams = (competition: Competition) => {
    const defaultSeason = competition.seasons.toSorted((a, b) => b.year - a.year)[0];

    setCompetitionsState({
      step: 'teams',
      competition,
      season: defaultSeason,
    });
  };

  if (isLoading) {
    return (
      <Stack alignItems={'center'}>
        <Spinner />
      </Stack>
    );
  }

  return (
    <Stack overflow={'hidden'}>
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs value={selectedTab} onChange={handleChange} aria-label='competitions tabs'>
          {leagueItems.length > 0 && (
            <Tab
              value={CompetitionFormat.LEAGUE}
              label={t('competitions.leagues')}
              {...a11yProps(CompetitionFormat.LEAGUE)}
            />
          )}
          {cupItems.length > 0 && (
            <Tab value={CompetitionFormat.CUP} label={t('competitions.cups')} {...a11yProps(CompetitionFormat.CUP)} />
          )}

          {internationalItems.length > 0 && (
            <Tab
              value={CompetitionRegion.INTERNATIONAL}
              label={t('competitions.international')}
              {...a11yProps(CompetitionRegion.INTERNATIONAL)}
            />
          )}
        </Tabs>
      </Box>
      <Box
        role={'tabpanel'}
        id={`competitions-tab-panel-${selectedTab}`}
        aria-labelledby={`competition-tab-${selectedTab}`}
        py={2}
        overflow={'hidden'}
      >
        <DashboardMenuList>
          {items.map((competition, index) => (
            <DashboardMenuItem
              key={competition.id}
              ref={index === items.length - 1 ? lastItemRef : null}
              onClick={() => handleGotToTeams(competition)}
            >
              <CompetitionRow
                countryCode={competition.country}
                name={competition.name}
                logoUrl={competition.logoUrl}
                organizationLogoUrl={competition?.organization?.logoUrl}
                type={selectedTab === CompetitionRegion.INTERNATIONAL ? 'international' : 'domestic'}
              />
            </DashboardMenuItem>
          ))}
        </DashboardMenuList>
      </Box>
    </Stack>
  );
};
