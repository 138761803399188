import { useQuery } from '@tanstack/react-query';
import { useMemo } from 'react';
import { useSetRecoilState } from 'recoil';

import { useMatchWithEpisodes } from 'api/match/useMatchWithEpisodes';
import { useTacticalAnalysisFilteredItems } from 'api/recording/use-tactical-analysis-filtered-items';
import { TIMELINE_DEFAULT_FILTERS } from 'api/recording/useEpisodesWithFilters/useTacticalAnalysesPlaylistItems';
import { useFetchTaggingEvents } from 'api/tagging-tool/use-fetch-tagging-events';
import { useResetTimelineSelectionAtoms } from 'pages/tactical-analysis/api/use-tactical-analysis-data/generate-timeline-rows/atoms';
import { generateTimelineRows } from 'pages/tactical-analysis/api/use-tactical-analysis-data/generate-timeline-rows/generateTimelineRows';
import { Clip } from 'pages/tactical-analysis/api/use-tactical-analysis-data/generate-timeline-rows/types/clip';
import { Row } from 'pages/tactical-analysis/api/use-tactical-analysis-data/generate-timeline-rows/types/row';
import { Timeline } from 'pages/tactical-analysis/api/use-tactical-analysis-data/generate-timeline-rows/types/timeline';
import { UseTacticalAnalysisData } from 'pages/tactical-analysis/api/use-tactical-analysis-data/types';
import { useTacticalAnalysisPresets } from 'pages/tactical-analysis/api/use-tactical-analysis-data/use-tactical-analysis-presets';
import { timelineMatchAtoms } from 'pages/tactical-analysis/atoms';
import { usePresetPlayingMode } from 'pages/tactical-analysis/hooks/use-preset-playing-mode';
import { useTacticalAnalysisAppliedFilters } from 'pages/tactical-analysis/hooks/use-tactical-analysis-applied-filters';
import { useSetTacticalAnalysisAvailableFilters } from 'pages/tactical-analysis/hooks/use-tactical-analysis-available-filters';
import { useSetTacticalAnalysisEpisodes } from 'pages/tactical-analysis/hooks/use-tactical-analysis-episodes';
import {
  useSetTacticalAnalysisFiltersResults,
  useTacticalAnalysisFilteredEpisodes,
} from 'pages/tactical-analysis/hooks/use-tactical-analysis-filters-results';
import {
  useSetTacticalAnalysisId,
  useTacticalAnalysisId,
} from 'pages/tactical-analysis/hooks/use-tactical-analysis-id';
import { useSetTacticalAnalysisMatch } from 'pages/tactical-analysis/hooks/use-tactical-analysis-match';
import { useSetTacticalAnalysisMatchSegments } from 'pages/tactical-analysis/hooks/use-tactical-analysis-match-segments';
import { useSetTacticalAnalysisMode } from 'pages/tactical-analysis/hooks/use-tactical-analysis-mode';
import { useTacticalAnalysisSelectedTactics } from 'pages/tactical-analysis/hooks/use-tactical-analysis-selected-tactics';
import { useSetTacticalAnalysisTeams } from 'pages/tactical-analysis/hooks/use-tactical-analysis-teams';
import { useSetTimelineTableData } from 'pages/tactical-analysis/hooks/use-timeline-table-data';
import { TacticalAnalysisPlayingMode } from 'pages/tactical-analysis/types/tactical-analysis-playing-mode';
import { useGenerateTacticalAnalysisPlaylistItems } from 'pages/tactical-analysis/utils/generate-tactical-analysis-playlist-items';
import {
  EPISODES_PLAYING_MODE,
  TACTICAL_CAMERA_WITH_OVERLAYS_PLAYING_MODE,
} from 'shared/components/video-player/defaultPlayingModes';
import { Episode, MatchWithEpisodes, TaggingEvents } from 'shared/types';
import { RecordingsFilters } from 'shared/types/recording/types';

const extractClipsFromParentClip = (clips: Clip[]): Clip[] => {
  return clips
    .map((clip) => {
      if (clip.type !== 'parent-clip') return [];
      if (!clip?.clips || clip.clips.length === 0) return [];

      return clip.clips;
    })
    .flat();
};

export const extractClipsFromTimelineTableData = (rows: Row[]): Clip[] => {
  return rows
    .map((row) =>
      row.clips
        .map((rowClip) => {
          const clips: Clip[] = [];

          if (rowClip.clips && rowClip.clips.length > 0) {
            const parentClips = extractClipsFromParentClip(rowClip.clips);

            if (parentClips.length > 0) {
              clips.push(...parentClips);
            }

            clips.push(...rowClip.clips);
          }

          return clips;
        })
        .flat(),
    )
    .flat();
};

function generateTimelineRowsQuery(
  matchWithEpisodes: MatchWithEpisodes | undefined,
  taggingEvents: TaggingEvents | undefined,
  filteredEpisodes: Episode[],
  appliedFilters: RecordingsFilters,
) {
  return new Promise((resolve) => {
    if (!matchWithEpisodes || !taggingEvents) {
      return;
    }

    resolve(
      generateTimelineRows(
        matchWithEpisodes.episodes,
        taggingEvents.tags,
        matchWithEpisodes.match.defaultVideoSource.duration,
        appliedFilters,
        filteredEpisodes,
        matchWithEpisodes.match.id,
      ),
    );
  });
}

export const useTacticalAnalysisData = (recordingId: string): UseTacticalAnalysisData => {
  const { isSuccess, isLoading, isFetching } = useTacticalAnalysisPresets(recordingId);
  const { appliedFilters, areFiltersApplied } = useTacticalAnalysisAppliedFilters(recordingId);
  const filteredEpisodes = useTacticalAnalysisFilteredEpisodes(recordingId);
  const setTimelineTableData = useSetTimelineTableData(recordingId);
  const selectedTactics = useTacticalAnalysisSelectedTactics(recordingId);
  const presetPlayingMode = usePresetPlayingMode(recordingId);
  const setTacticalAnalysisTeams = useSetTacticalAnalysisTeams(recordingId);
  const setCurrentTacticalAnalysisId = useSetTacticalAnalysisId(recordingId);
  const setTacticalAnalysisAvailableFilters = useSetTacticalAnalysisAvailableFilters(recordingId);
  const setTacticalAnalysisMatch = useSetTacticalAnalysisMatch(recordingId);
  const setTacticalAnalysisEpisodes = useSetTacticalAnalysisEpisodes(recordingId);
  const setMatchSegments = useSetTacticalAnalysisMatchSegments(recordingId);
  const setTimelineTeamIdFocus = useSetRecoilState(timelineMatchAtoms.teamIdFocus(recordingId));
  const setTimelineVideoSource = useSetRecoilState(timelineMatchAtoms.matchVideoSource(recordingId));
  const resetTimelineSelectionAtoms = useResetTimelineSelectionAtoms();
  const setTimelineMode = useSetTacticalAnalysisMode(recordingId);
  const tacticalAnalysisId = useTacticalAnalysisId(recordingId);
  const { getFilters } = useTacticalAnalysisFilteredItems();
  const setTacticalAnalysisFiltersResults = useSetTacticalAnalysisFiltersResults(recordingId);

  const fetchMatchWithEpisodes = useMatchWithEpisodes({
    recordingId,
    onSuccess: (matchWithEpisodes: MatchWithEpisodes) => {
      setTacticalAnalysisMatch(matchWithEpisodes.match);
      setTacticalAnalysisAvailableFilters(matchWithEpisodes.filters ?? TIMELINE_DEFAULT_FILTERS);
      setTacticalAnalysisEpisodes(matchWithEpisodes.episodes);
      setCurrentTacticalAnalysisId(matchWithEpisodes?.episodes[0]?.tacticalAnalysisId ?? '');
      setTacticalAnalysisTeams(matchWithEpisodes.match.teams);
      setTimelineVideoSource(matchWithEpisodes.match.defaultVideoSource);
      setMatchSegments(matchWithEpisodes.matchSegments);
      setTimelineTeamIdFocus((focusTeamId) => (focusTeamId ? focusTeamId : matchWithEpisodes.match.teams.homeTeam?.id));
    },
    enabled: isSuccess,
    prefix: 'team-centered-timeline',
  });
  const fetchTaggingEvents = useFetchTaggingEvents(recordingId);
  const hasSource = Boolean(fetchMatchWithEpisodes.data?.match?.defaultVideoSource?.src);

  const timelineRowsQuery = useQuery({
    queryKey: ['timeline-rows', recordingId],
    queryFn: () =>
      generateTimelineRowsQuery(fetchMatchWithEpisodes.data, fetchTaggingEvents.data, filteredEpisodes, appliedFilters),
    gcTime: 0,
    enabled: fetchMatchWithEpisodes.isSuccess && fetchTaggingEvents.isSuccess,
    meta: {
      onSuccess: (data: Timeline) => {
        resetTimelineSelectionAtoms();
        setTimelineMode(TacticalAnalysisPlayingMode.default);
        setTimelineTableData(data);

        appliedFilters &&
          areFiltersApplied &&
          tacticalAnalysisId &&
          getFilters(tacticalAnalysisId, appliedFilters, (episodes: Episode[]) =>
            setTacticalAnalysisFiltersResults(episodes, appliedFilters, data),
          );
      },
    },
  });

  const playlistItems = useGenerateTacticalAnalysisPlaylistItems({
    recordingId,
    matchWithEpisodes: fetchMatchWithEpisodes.data,
    selectedTactics,
    selectedPlayingMode: presetPlayingMode,
    areTimelineOverlaysEnabled: true,
  });

  const initialPlayingMode = useMemo(() => {
    if (presetPlayingMode) return presetPlayingMode;

    return fetchMatchWithEpisodes.data &&
      fetchMatchWithEpisodes.data.episodes.length > 0 &&
      !fetchMatchWithEpisodes.data.match.hasHomographies
      ? EPISODES_PLAYING_MODE
      : TACTICAL_CAMERA_WITH_OVERLAYS_PLAYING_MODE;
  }, [presetPlayingMode, fetchMatchWithEpisodes.data]);

  return {
    data: {
      matchWithEpisodes: fetchMatchWithEpisodes.data,
      taggingEvents: fetchTaggingEvents.data,
      playlistItems: playlistItems.data ?? [],
      initialPlayingMode,
    },
    isError:
      (fetchTaggingEvents.isSuccess && fetchMatchWithEpisodes.isSuccess && !hasSource) ||
      fetchMatchWithEpisodes.isError ||
      fetchTaggingEvents.isError ||
      timelineRowsQuery.isError,
    isSuccess:
      fetchMatchWithEpisodes.isSuccess &&
      fetchTaggingEvents.isSuccess &&
      Boolean(playlistItems) &&
      timelineRowsQuery.isSuccess &&
      isSuccess &&
      hasSource,
    isFetching:
      fetchMatchWithEpisodes.isFetching || fetchTaggingEvents.isFetching || isFetching || timelineRowsQuery.isFetching,
    isPending:
      fetchMatchWithEpisodes.isPending || fetchTaggingEvents.isPending || isLoading || timelineRowsQuery.isPending,
    isMissingData: !fetchMatchWithEpisodes?.data?.match?.defaultVideoSource,
  };
};
