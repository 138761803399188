import { TypeOf } from 'zod';

import {
  competitionEditFormSchema,
  competitionFormSchema,
} from 'pages/backoffice/pages/competitions/ui/competition-form/form';

export enum CompetitionFormFieldsNames {
  competitionImage = 'competitionImage',
  id = 'id',
  name = 'name',
  country = 'country',
  format = 'format',
  teamType = 'team_type',
  gender = 'gender',
  tier = 'tier',
  region = 'region',
  organizationId = 'organization_uuid',
  mappings = 'mappings',
}

export type CompetitionFormSchema = TypeOf<typeof competitionFormSchema>;
export type UpdateMetadataCompetitionApiParams = Partial<TypeOf<typeof competitionEditFormSchema>>;
