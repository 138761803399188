import { List } from '@mui/material';

import { Venue } from 'pages/backoffice/api/venues/use-venues/types';
import { VenueItem } from 'pages/backoffice/pages/venues/components/venue-item/VenueItem';

interface Props {
  venues: Venue[];
}

export const VenuesList = ({ venues }: Props) => {
  const venuesItems = venues.map((venue) => {
    return <VenueItem key={venue.id} venue={venue} />;
  });

  return <List>{venuesItems}</List>;
};
