import { Stack, Tab, Tabs, Typography } from '@mui/material';
import { RefObject, SyntheticEvent, UIEvent, useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { EXPLORE_SEARCH_ID, tabs } from 'entities/dashboard/constants/dashboardSearch';
import { useSelectResultHandler } from 'entities/dashboard/hooks/useSelectResultHandler';
import { SearchResult, SearchType } from 'entities/dashboard/types/dashboardSearch';
import { focusOnFirstListElement } from 'entities/dashboard/utils/focusOnFirstListElement';
import { getFocusedListElementIndex } from 'entities/dashboard/utils/getFocusedListElementIndex';
import { searchResultsTabsKeys } from 'features/dashboard/dashboard-search-results-tabs/constants/dashboardSearchResultsTabs';
import { DashboardSearchOption } from 'features/dashboard/dashboard-search-results-tabs/DashboardSearchOption';
import { DashboardMenuList } from 'shared/components/DashboardMenuList';
import { TabPanel } from 'shared/components/tabs/ui/TabPanel';

type Props = {
  searchResults: readonly SearchResult[];
  onSelect?: (data: SearchResult) => void;
  noResultsMessage: string;
  onEscape?: () => void;
  onScroll?: (event: UIEvent<HTMLElement>) => void;
  title: string;
  totals: {
    [SearchType.ALL]: number;
    [SearchType.PLAYER]: number;
    [SearchType.TEAM]: number;
  };
  searchInputRef: RefObject<HTMLInputElement>;
  searchResultsRef: RefObject<HTMLUListElement>;
};

export const DashboardSearchResultsTabsFeature = ({
  searchInputRef,
  searchResultsRef,
  noResultsMessage,
  onEscape,
  onScroll,
  onSelect,
  searchResults,
  title,
  totals,
}: Props) => {
  const [selectedTab, setSelectedTab] = useState<number>(0);
  const selectResultHandler = useSelectResultHandler();
  const { t } = useTranslation('explore');

  const handleChangeTab = useCallback((newValue: number) => {
    setSelectedTab(newValue);
  }, []);

  const handleFocusOnFirstListElement = useCallback(() => {
    focusOnFirstListElement(searchResultsRef?.current);
  }, [searchResultsRef]);

  const handleGetFocusedElementIndex = useCallback(() => {
    return getFocusedListElementIndex(searchResultsRef?.current);
  }, [searchResultsRef]);

  useEffect(() => {
    if (searchInputRef.current === document.activeElement) return;

    handleFocusOnFirstListElement();
  }, [selectedTab, searchInputRef, handleFocusOnFirstListElement]);

  const handleSelectPreviousTab = useCallback(() => {
    handleChangeTab(selectedTab > 0 ? selectedTab - 1 : tabs.length - 1);
  }, [selectedTab, handleChangeTab]);

  const handleSelectNextTab = useCallback(() => {
    handleChangeTab(selectedTab < tabs.length - 1 ? selectedTab + 1 : 0);
  }, [selectedTab, handleChangeTab]);

  const tabsSearchResults = useMemo(() => {
    return tabs.map((tab) => searchResults.filter((result) => tab === SearchType.ALL || result.type === tab));
  }, [searchResults]);

  const handleChangeTabs = (_: SyntheticEvent, newValue: number) => {
    handleChangeTab(newValue);
  };

  const handleSelect = useCallback(
    (result: SearchResult) => {
      selectResultHandler(result);
      onSelect && onSelect(result);
    },
    [onSelect, selectResultHandler],
  );

  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      const tabSearchResults = tabsSearchResults[selectedTab];

      switch (event.key) {
        case 'ArrowUp':
          if (getFocusedListElementIndex(searchResultsRef.current) === 0) {
            event.preventDefault();
            event.stopPropagation();
            searchInputRef.current?.focus();
          }
          break;
        case 'ArrowLeft':
          if ((event.target as HTMLElement).getAttribute('role') === 'tab') return;

          event.preventDefault();
          event.stopPropagation();
          handleSelectPreviousTab();
          break;
        case 'ArrowRight':
          if ((event.target as HTMLElement).getAttribute('role') === 'tab') return;

          event.preventDefault();
          event.stopPropagation();
          handleSelectNextTab();
          break;
        case 'ArrowDown':
          if ((event.target as HTMLElement).getAttribute('role') === 'tab' || handleGetFocusedElementIndex() === -1) {
            event.preventDefault();
            event.stopPropagation();
            handleFocusOnFirstListElement();
          }

          const listLength = searchResultsRef.current?.children.length || 0;
          if (getFocusedListElementIndex(searchResultsRef.current) === listLength - 1) {
            event.preventDefault();
            event.stopPropagation();
          }
          break;
        case 'Escape':
          event.preventDefault();

          searchInputRef.current?.focus();
          onEscape && onEscape();
          break;
        case 'Enter':
          event.preventDefault();
          if (!tabSearchResults.length) return;
          if (!tabSearchResults[handleGetFocusedElementIndex()]) return;

          handleSelect(tabSearchResults[handleGetFocusedElementIndex()]);
          break;
        default:
          break;
      }
    };
    document.addEventListener('keydown', handleKeyDown, true);
    return () => {
      document.removeEventListener('keydown', handleKeyDown, true);
    };
  }, [
    searchResultsRef,
    searchInputRef,
    handleFocusOnFirstListElement,
    handleGetFocusedElementIndex,
    handleSelectNextTab,
    handleSelectPreviousTab,
    handleSelect,
    selectedTab,
    tabsSearchResults,
    onEscape,
  ]);

  const tabSearchResults = useMemo(() => tabsSearchResults[selectedTab], [tabsSearchResults, selectedTab]);

  if (searchResults.length === 0)
    return (
      <Stack height={'56px'} justifyContent={'center'} pl={2}>
        <Typography variant={'caption'} fontWeight={'fontWeightMedium'}>
          {noResultsMessage}
        </Typography>
      </Stack>
    );

  return (
    <>
      <Tabs value={selectedTab} onChange={handleChangeTabs}>
        {tabs.map((tab, index) => (
          <Tab
            key={tab}
            onFocus={() => handleChangeTab(index)}
            data-index={index}
            label={
              <Stack direction={'row'} alignItems={'center'} gap={1}>
                {t(searchResultsTabsKeys[tab])}
                <Typography component={'span'} variant={'caption'}>
                  ({totals[tab]})
                </Typography>
              </Stack>
            }
          />
        ))}
      </Tabs>
      <Stack direction={'row'} alignItems={'center'} minHeight={'48px'}>
        {tabSearchResults.length > 0 ? (
          <Typography variant={'caption'} pl={2} fontWeight={'fontWeightMedium'}>
            {title}
          </Typography>
        ) : (
          <Typography variant={'caption'} pl={2} fontWeight={'fontWeightMedium'}>
            {noResultsMessage}
          </Typography>
        )}
      </Stack>
      {tabs.map((tab, index) => {
        const tabSearchResults = tabsSearchResults[index];
        const filteredSearchResults = tabSearchResults.filter(
          (result) => tab === SearchType.ALL || result.type === tab,
        );
        return (
          <TabPanel
            key={tab}
            value={selectedTab}
            index={index}
            sx={{
              flexGrow: 1,
              maxHeight: 168,
            }}
          >
            <DashboardMenuList aria-labelledby={EXPLORE_SEARCH_ID} onScroll={onScroll} ref={searchResultsRef}>
              {filteredSearchResults.map((option) => (
                <DashboardSearchOption key={option.value} option={option} onSelect={onSelect} />
              ))}
            </DashboardMenuList>
          </TabPanel>
        );
      })}
    </>
  );
};
