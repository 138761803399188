/* eslint-disable react/display-name */
import { Typography } from '@mui/material';
import { Colors, fontSizes, fontWeight } from 'kognia-ui';
import React from 'react';

import {
  SectionChild,
  SectionContainer,
  SectionContent,
  SectionGroup,
} from 'pages/tagging-tool-tag-recording/components/keypads-panel/components/keypad-section/styled';
import { TagButtonProps } from 'pages/tagging-tool-tag-recording/components/tag-button/TagButtonBase';

type SectionChildren<T> = React.ReactElement<T> | Array<React.ReactElement<T>>;

type SectionColProps = {
  children: React.ReactElement<TagButtonProps>;
};

type SectionRowProps = {
  children?: SectionChildren<SectionColProps>;
};

type SectionProps = {
  children?: SectionChildren<SectionRowProps>;
  title: string;
};

export const KeypadSection = (props: SectionProps) => {
  const rowChildrenCounterMap: Array<number> = [];
  const colMap: Array<Array<number>> = [];
  let childrenCounter = 0;
  React.Children.forEach(props.children, (row, rowIdx) => {
    let currentRowCounter = 0;
    colMap[rowIdx] = [];

    React.Children.forEach(row?.props.children, (col) => {
      React.Children.forEach(col?.props.children, (tag) => {
        const inc = tag !== undefined && tag.props.data.isEnabled ? 1 : 0;
        childrenCounter += inc;
        currentRowCounter += inc;
        colMap[rowIdx].push(inc);
      });
    });
    rowChildrenCounterMap.push(currentRowCounter);
  });

  if (childrenCounter === 0) {
    return null;
  }

  return (
    <>
      <KeypadSection.Title title={props.title} />
      <SectionContainer>
        {React.Children.map(props.children, (row, rowIdx) => {
          if (rowChildrenCounterMap[rowIdx] === 0) {
            return null;
          }

          const cols = React.Children.count(row?.props.children);
          let col_a: React.ReactNode;
          let col_b: React.ReactNode;
          let col_c: React.ReactNode;
          let col_d: React.ReactNode;
          React.Children.forEach(row?.props.children, (col, colIdx) => {
            const nextCol: React.ReactNode = colMap[rowIdx][colIdx] === 1 ? col?.props.children : <SectionChild />;

            if (colIdx === 0) {
              col_a = nextCol;
            } else if (colIdx === 1) {
              if (cols === 4) {
                col_b = nextCol;
              } else if (cols === 2) {
                col_c = nextCol;
              }
            } else if (colIdx === 2) {
              col_c = nextCol;
            } else if (colIdx === 3) {
              col_d = nextCol;
            }
          });

          return (
            <SectionContent>
              <SectionGroup>
                {col_a}
                {col_b}
              </SectionGroup>
              <SectionGroup>
                {col_c}
                {col_d}
              </SectionGroup>
            </SectionContent>
          );
        })}
      </SectionContainer>
    </>
  );
};

KeypadSection.Row = (_props: SectionRowProps) => {
  return null;
};

KeypadSection.Col = (_props: SectionColProps) => {
  return null;
};

KeypadSection.Title = (props: { title: string }) => {
  return (
    <Typography
      fontSize={fontSizes.small}
      color={Colors.storm}
      marginTop={2}
      textTransform='uppercase'
      fontWeight={fontWeight['500']}
    >
      {props.title}
    </Typography>
  );
};
