import { Checkbox, FormControl, FormHelperText, Stack, Typography } from '@mui/material';
import { Colors } from 'kognia-ui';
import { Control, Controller, UseFormRegister, FieldErrors } from 'react-hook-form';

import {
  SeasonCompetitionFormFieldsNames,
  SeasonFormSchema,
} from 'pages/backoffice/pages/season-competitions/components/season-competition-form/types';
import { SelectCompetition } from 'pages/backoffice/pages/season-competitions/components/select-competition/SelectCompetition';
import { getYears } from 'pages/backoffice/utils/years';
import { FormInputField } from 'shared/components/form/ui/FormInputField';
import { FormSelectField } from 'shared/components/form/ui/FormSelectField';
import { getTypeOptions } from 'shared/components/form/utils/getTypeOptions';

interface Props {
  register: UseFormRegister<SeasonFormSchema>;
  errors: FieldErrors<SeasonFormSchema>;
  control: Control<SeasonFormSchema>;
}

export function SeasonCompetitionsForm({ register, errors, control }: Props) {
  return (
    <>
      <FormControl fullWidth margin='normal'>
        <Stack direction='row' spacing={1} alignItems='center'>
          <Typography variant={'body1'}>Year</Typography>
          <FormSelectField
            id={SeasonCompetitionFormFieldsNames.year}
            options={getTypeOptions({ options: getYears(10).map((year) => ({ value: year })) })}
            error={!!errors[SeasonCompetitionFormFieldsNames.year]}
            helperText={
              errors[SeasonCompetitionFormFieldsNames.year] && (
                <>{errors[SeasonCompetitionFormFieldsNames.year]?.message}</>
              )
            }
            defaultValue={new Date().getFullYear()}
            fullWidth={false}
            {...register(SeasonCompetitionFormFieldsNames.year)}
          />
        </Stack>
      </FormControl>
      <Controller
        render={({ field: { onChange, value } }) => (
          <Stack direction='row' spacing={1} alignItems='center'>
            <Typography variant={'body1'}>Crosses year</Typography>
            <Checkbox id={SeasonCompetitionFormFieldsNames.crossesYear} checked={value} onChange={onChange} />
          </Stack>
        )}
        name={SeasonCompetitionFormFieldsNames.crossesYear}
        control={control}
      />
      <FormInputField
        type={'date'}
        id={SeasonCompetitionFormFieldsNames.startDate}
        label={'Start date'}
        helperText={
          errors[SeasonCompetitionFormFieldsNames.startDate] && (
            <FormHelperText error>{errors[SeasonCompetitionFormFieldsNames.startDate]?.message}</FormHelperText>
          )
        }
        {...register(SeasonCompetitionFormFieldsNames.startDate)}
      />
      <FormInputField
        type={'date'}
        id={SeasonCompetitionFormFieldsNames.endDate}
        label={'End date'}
        helperText={
          errors[SeasonCompetitionFormFieldsNames.endDate] && (
            <FormHelperText error>{errors[SeasonCompetitionFormFieldsNames.endDate]?.message}</FormHelperText>
          )
        }
        {...register(SeasonCompetitionFormFieldsNames.endDate)}
      />
      <Controller
        render={({ field: { onChange, value }, fieldState: { error } }) => (
          <>
            <SelectCompetition
              setCompetitionOnChange={(competition) => onChange(competition?.id)}
              defaultSelectedCompetitionId={value}
            />
            {error && <Typography color={Colors.red}>{error.message}</Typography>}
          </>
        )}
        name={SeasonCompetitionFormFieldsNames.competitionId}
        control={control}
      />
    </>
  );
}
