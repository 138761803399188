import { TypeOf } from 'zod';

import { seasonCompetitionFormSchema } from 'pages/backoffice/pages/season-competitions/components/season-competition-form/form';

export enum SeasonCompetitionFormFieldsNames {
  id = 'id',
  year = 'year',
  crossesYear = 'crosses_year',
  competitionId = 'competition_uuid',
  startDate = 'start_date',
  endDate = 'end_date',
}

export type SeasonFormSchema = TypeOf<typeof seasonCompetitionFormSchema>;
