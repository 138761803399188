import { zodResolver } from '@hookform/resolvers/zod';
import { AccordionActions, AccordionDetails } from '@mui/material';
import React, { useCallback } from 'react';
import { useForm } from 'react-hook-form';

import { useValidateTask } from 'pages/backoffice/api/operations/useValidateTask';
import { AnnotationFormActions } from 'pages/backoffice/pages/annotation/ui/AnnotationFormActions';
import { AnnotationToolField } from 'pages/backoffice/pages/annotation/ui/form-fields/AnnotationToolField';
import { DryRunField } from 'pages/backoffice/pages/annotation/ui/form-fields/DryRunField';
import { PipelineTypeField } from 'pages/backoffice/pages/annotation/ui/form-fields/PipelineTypeField';
import { RecordingIdField } from 'pages/backoffice/pages/annotation/ui/form-fields/RecordingIdField';
import { validationTaskFormSchema } from 'pages/backoffice/pages/annotation/utils/form-fields-validation';
import {
  ValidateTaskForm,
  ValidationTaskFormSchema,
} from 'pages/backoffice/pages/annotation/utils/form-fields-validation/types';

export const ValidateTask = () => {
  const { validateTask } = useValidateTask();

  const {
    register,
    formState: { errors },
    reset,
    handleSubmit: handleFormSubmit,
  } = useForm<ValidationTaskFormSchema>({
    resolver: zodResolver(validationTaskFormSchema),
  });

  const handleSubmit = useCallback(
    (data: ValidateTaskForm) => {
      validateTask({ data });
    },
    [validateTask],
  );

  return (
    <form onSubmit={handleFormSubmit(handleSubmit)}>
      <AccordionDetails>
        <RecordingIdField errors={errors} register={register} />
        <PipelineTypeField errors={errors} register={register} />
        <AnnotationToolField errors={errors} register={register} />
        <DryRunField register={register} />
      </AccordionDetails>
      <AccordionActions>
        <AnnotationFormActions reset={() => reset()} />
      </AccordionActions>
    </form>
  );
};
