import React, { memo, useEffect, useState } from 'react';

import { ZoomLevelsType } from 'shared/types/zoom-range/zoomLevels';

interface Props {
  max: ZoomLevelsType;
  min: ZoomLevelsType;
  onChange: (value: ZoomLevelsType) => void;
  step: 1;
  value?: ZoomLevelsType;
}

export const RangeSlider = memo(({ min, max, step, value, onChange }: Props) => {
  const [sliderValue, setSliderValue] = useState<ZoomLevelsType>(value ?? min);

  const changeCallback = (event: React.FormEvent<HTMLInputElement>) => {
    const target = event.target as HTMLTextAreaElement;
    const targetValue = parseInt(target.value) as ZoomLevelsType;

    onChange(targetValue);
    setSliderValue(targetValue);
  };

  useEffect(() => {
    if (value !== undefined && value >= 0) {
      setSliderValue(value);
    }
  }, [value]);

  return (
    <input
      max={max}
      min={min}
      onChange={changeCallback}
      step={step}
      type='range'
      value={sliderValue}
      style={{ height: '16px' }}
    />
  );
});

RangeSlider.displayName = 'RangeSlider';
