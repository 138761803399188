import { Typography } from '@mui/material';
import { Colors, fontSizes, fontWeight } from 'kognia-ui';
import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { TaggingEvent } from 'api/tagging-tool/types';
// eslint-disable-next-line import/no-restricted-paths
import { usePlaylistMenuItems } from 'pages/tactical-analysis/components/tactical-analysis/add-to-playlist-menu/add-to-playlist-menu-state/hooks';
import { TaggingEventClip } from 'pages/tagging-tool-tag-recording/components/tag-event-clip/TagEventClip';
import { TagEventsListSkeleton } from 'pages/tagging-tool-tag-recording/components/tag-events-list-skeleton/TagEventsListSkeleton';
import {
  TaggingEventsListContainer,
  TaggingEventsListNotFoundImg,
} from 'pages/tagging-tool-tag-recording/components/tagging-events-list/styled';
import NotFoundImg from 'shared/assets/cameras.svg';
import { useDuration } from 'shared/components/video-player';

export type TaggingEventsPanelProps = {
  children?: React.ReactNode;
  hasVideo: boolean;
  data: Array<TaggingEvent>;
  isMultiselectModeActive?: boolean;
  onPlayClip: (event: TaggingEvent) => void;
  onDelete: (id: string) => void;
  onRename: (event: TaggingEvent) => void;
  onTrim: (event: TaggingEvent) => void;
};

export const TaggingEventsList = (props: TaggingEventsPanelProps) => {
  const duration = useDuration();
  const { t } = useTranslation();
  const { data } = props;
  const selectedPlaylistItems = usePlaylistMenuItems();

  const handlePlay = useCallback(
    (event: TaggingEvent) => {
      props.onPlayClip(event);
    },
    [props],
  );

  return (
    <TaggingEventsListContainer>
      {data.length === 0 && (
        <TagEventsListSkeleton>
          <TaggingEventsListNotFoundImg src={NotFoundImg} alt='' />
          <Typography fontSize={fontSizes.default} fontWeight={fontWeight['500']} marginTop={2}>
            {t('tagging-tool:no-video-available')}
          </Typography>
          <Typography fontSize={fontSizes.default} color={Colors.storm} marginTop={1}>
            {t('tagging-tool:recording-clips.nothing-to-show')}
          </Typography>
        </TagEventsListSkeleton>
      )}
      {data.map((event, idx) => {
        return (
          <TaggingEventClip
            key={event.id}
            taggingEvent={event}
            counter={idx}
            hasVideo={props.hasVideo}
            disabled={event.time < 0 || event.time > (duration || Number.MAX_SAFE_INTEGER)}
            selected={selectedPlaylistItems.findIndex((item) => event.id === item.id) !== -1}
            isMultiselectModeActive={props.isMultiselectModeActive}
            onPlay={handlePlay}
            onDelete={props.onDelete}
            onRename={props.onRename}
            onTrim={props.onTrim}
          />
        );
      })}
    </TaggingEventsListContainer>
  );
};
