import { zodResolver } from '@hookform/resolvers/zod';
import { AccordionActions, AccordionDetails } from '@mui/material';
import { useCallback } from 'react';
import { useForm } from 'react-hook-form';

import { useProcessEpisode } from 'pages/backoffice/api/operations/useProcessEpisode';
import { AnnotationFormActions } from 'pages/backoffice/pages/annotation/ui/AnnotationFormActions';
import { RecordingIdField } from 'pages/backoffice/pages/annotation/ui/form-fields/RecordingIdField';
import { processEpisodeFormSchema } from 'pages/backoffice/pages/annotation/ui/process-episode/form';
import {
  ProcessEpisodeForm,
  ProcessEpisodeFormSchema,
} from 'pages/backoffice/pages/annotation/ui/process-episode/types';

export const ProcessEpisode = () => {
  const { processEpisode } = useProcessEpisode();
  const {
    register,
    formState: { errors },
    reset,
    handleSubmit: handleFormSubmit,
  } = useForm<ProcessEpisodeFormSchema>({
    resolver: zodResolver(processEpisodeFormSchema),
  });

  const handleSubmit = useCallback(
    (data: ProcessEpisodeForm) => {
      processEpisode({ data });
    },
    [processEpisode],
  );

  return (
    <form onSubmit={handleFormSubmit(handleSubmit)}>
      <AccordionDetails>
        <RecordingIdField errors={errors} register={register} />
      </AccordionDetails>
      <AccordionActions>
        <AnnotationFormActions reset={() => reset()} />
      </AccordionActions>
    </form>
  );
};
