import { FormFieldProps } from 'pages/backoffice/pages/annotation/ui/form-fields/types';
import { AnnotationFormLabels } from 'pages/backoffice/pages/annotation/utils/form-fields-validation';
import { AnnotationFormFieldsNames } from 'pages/backoffice/pages/annotation/utils/form-fields-validation/types';
import { FormSwitchField } from 'shared/components/form/ui/FormSwitchField';

export const LoadAnnotationsField = ({ register }: FormFieldProps) => {
  return (
    <FormSwitchField
      id={AnnotationFormFieldsNames.LOAD_ALL}
      defaultChecked
      label={AnnotationFormLabels[AnnotationFormFieldsNames.LOAD_ALL]}
      {...register(AnnotationFormFieldsNames.LOAD_ALL)}
    />
  );
};
