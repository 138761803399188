import { z } from 'zod';

import { SeasonCompetitionFormFieldsNames } from 'pages/backoffice/pages/season-competitions/components/season-competition-form/types';

export const seasonCompetitionFormSchema = z.object({
  [SeasonCompetitionFormFieldsNames.id]: z.string().optional(),
  [SeasonCompetitionFormFieldsNames.year]: z.number().min(1970),
  [SeasonCompetitionFormFieldsNames.crossesYear]: z.boolean(),
  [SeasonCompetitionFormFieldsNames.startDate]: z.string().date(),
  [SeasonCompetitionFormFieldsNames.endDate]: z.string().date(),
  [SeasonCompetitionFormFieldsNames.competitionId]: z.string(),
});
