import { useMutationRequest } from 'api/hooks/useMutationRequest';
import { HTTPMethod } from 'api/types';
import { transformMetadataFixture } from 'pages/backoffice/api/fixtures/transformers';
import { FixtureApi } from 'pages/backoffice/api/fixtures/types';
import { useInvalidateMetadataFixtures } from 'pages/backoffice/api/fixtures/use-metadata-fixtures/useMetadataFixtures';
import { UpdateMetadataFixtureApiParams } from 'pages/backoffice/api/fixtures/use-update-metadata-fixture/types';
import { annotationApiUrls } from 'pages/backoffice/api/routes';
import { useAllowedBackofficeOrigin } from 'pages/backoffice/api/useAllowedBackofficeOrigin';
import { metadataApiErrorParser, MetadataApiErrorResponse } from 'pages/backoffice/api/utils';
import { MetadataFixture } from 'pages/backoffice/types/fixture';

interface RequestData {
  data: UpdateMetadataFixtureApiParams;
  id: string;
  onSuccess?: () => void;
}

export const useUpdateMetadataFixture = () => {
  const { getIsAllowedBackofficeOrigin } = useAllowedBackofficeOrigin();
  const invalidateMetadataFixtures = useInvalidateMetadataFixtures();
  const mutationRequest = useMutationRequest<FixtureApi, MetadataFixture, MetadataApiErrorResponse>({
    type: HTTPMethod.PATCH,
    successMessage: 'Fixture updated',
    errorMessage: (data) => metadataApiErrorParser(data, 'Fixture update error'),
    onSuccess: invalidateMetadataFixtures,
    transformer: transformMetadataFixture,
  });

  const update = ({ data, id, onSuccess }: RequestData) => {
    if (!getIsAllowedBackofficeOrigin()) return;

    mutationRequest.mutate(
      {
        url: `${annotationApiUrls.FIXTURES}/${id}`,
        data,
      },
      {
        onSuccess,
      },
    );
  };

  return { ...mutationRequest, update };
};
