import { alpha, createTheme, type SimplePaletteColorOptions, SxProps, Theme } from '@mui/material'; /*
  Overriding the theme
  https://mui.com/material-ui/customization/theming/#typescript

  You need to import the module in order to extend the theme. Otherwise, you will get a typescript error.
  It is probably related to module augmentation and how it works.
*/
// eslint-disable-next-line @typescript-eslint/no-unused-vars, unused-imports/no-unused-imports
import styles from '@mui/material/styles';
// eslint-disable-next-line @typescript-eslint/no-unused-vars, unused-imports/no-unused-imports
import Button from '@mui/material/Button';
// eslint-disable-next-line @typescript-eslint/no-unused-vars, unused-imports/no-unused-imports
import Checkbox from '@mui/material/Checkbox';
// eslint-disable-next-line @typescript-eslint/no-unused-vars, unused-imports/no-unused-imports
import SvgIcon from '@mui/material/SvgIcon';

import { Colors } from './colors';

declare module '@mui/material/styles' {
  interface Theme {
    chartPalette: {
      primary: string[];
      secondary: string[];
      secondaryHighlight: string;
    };
  }

  interface ThemeOptions {
    chartPalette?: {
      primary: string[];
      secondary: string[];
      secondaryHighlight: string;
    };
  }

  interface Palette {
    tertiary: SimplePaletteColorOptions;
    quaternary: SimplePaletteColorOptions;
  }

  interface PaletteOptions {
    tertiary?: SimplePaletteColorOptions;
    quaternary?: SimplePaletteColorOptions;
  }
}

declare module '@mui/material/Button' {
  interface ButtonPropsVariantOverrides {
    underlined: true;
  }
}

declare module '@mui/material/Checkbox' {
  interface CheckboxPropsColorOverrides {
    tertiary: true;
    quaternary: true;
  }
}

declare module '@mui/material/SvgIcon' {
  interface SvgIconPropsColorOverrides {
    tertiary: true;
    quaternary: true;
  }
}

const borderRadius = 4;
export const themeBaseSpacing = 8;
export const themeZIndexes = {
  mobileStepper: 1000,
  speedDial: 1050,
  appBar: 1100,
  drawer: 1200,
  modal: 1300,
  popper: 6000,
  snackbar: 9001,
  tooltip: 9002,
};

export const defaultFontFamily = "'Rubik', sans-serif";

export enum Breakpoint {
  XS = 'xs',
  SM = 'sm',
  MD = 'md',
  LG = 'lg',
  XL = 'xl',
}

const breakPointValues = {
  [Breakpoint.XS]: 0,
  [Breakpoint.SM]: 576,
  [Breakpoint.MD]: 768,
  [Breakpoint.LG]: 992,
  [Breakpoint.XL]: 1200,
};

const breakpointList = Object.values(Breakpoint);

export const generateContainerQueries = (
  styles: Partial<Record<Breakpoint, SxProps<Theme>>>,
): Record<string, SxProps<Theme>> => {
  return breakpointList.reduce<Record<string, SxProps<Theme>>>((acc, breakpoint) => {
    const style = styles[breakpoint];
    if (!style) return acc;
    return {
      ...acc,
      [`@container (min-width: ${breakPointValues[breakpoint]}px)`]: style,
    };
  }, {});
};

export const theme = createTheme({
  spacing: themeBaseSpacing,
  breakpoints: {
    values: breakPointValues,
  },
  palette: {
    mode: 'light',
    primary: {
      main: Colors.primary,
      light: Colors.lavender,
    },
    secondary: {
      main: Colors.storm,
      light: Colors.iron,
    },
    tertiary: {
      main: Colors.bluePastel,
      light: Colors.cloud,
    },
    quaternary: {
      main: Colors.fountain,
      light: Colors.fresco,
    },
    warning: {
      main: Colors.orange,
      light: Colors.dawn,
    },
    error: {
      main: Colors.red,
      light: Colors.strawberry,
    },
    info: {
      main: Colors.blue,
      light: Colors.sky,
    },
    success: {
      main: Colors.green,
      light: Colors.fresco,
      contrastText: Colors.white,
    },
    text: {
      primary: Colors.night,
      secondary: Colors.shark,
      disabled: Colors.iron,
    },
    divider: Colors.titanPurple,
    grey: {
      200: Colors.athens,
    },
  },
  shape: {
    borderRadius: borderRadius,
  },
  zIndex: themeZIndexes,
  typography: {
    fontFamily: defaultFontFamily,
  },
  chartPalette: {
    primary: [Colors.primary, Colors.lavender, Colors.bluePastel, Colors.fountain],
    secondary: ['#35A64E', '#BAD6A9', '#FFDDBE', '#FFA697', '#FB8877'],
    secondaryHighlight: '#4b5588',
  },
  components: {
    MuiInputBase: {
      styleOverrides: {
        sizeSmall: {
          input: {
            height: '24px',
          },
        },
      },
    },
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          fontFamily: defaultFontFamily,
          lineHeight: `${themeBaseSpacing * 3}px`,
          backgroundColor: Colors.night,
          padding: `${themeBaseSpacing}px ${themeBaseSpacing * 2}px`,
          fontSize: 14,
          color: Colors.white,
        },
      },
    },
    MuiButton: {
      variants: [
        {
          props: { variant: 'underlined' },
          style: {
            fontWeight: 'normal',
            borderBottom: `1px solid ${Colors.night}`,
            textTransform: 'none',
            boxShadow: 'none',
            minWidth: 0,
            borderRadius: `${borderRadius}px ${borderRadius}px 0 0`,
            padding: `${themeBaseSpacing / 2}px ${themeBaseSpacing / 2}px`,
            '&:hover': {
              backgroundColor: alpha(Colors.lavender, 0.1),
            },
          },
        },
        {
          props: { variant: 'underlined', color: 'primary' },
          style: {
            color: Colors.primary,
            borderBottomColor: Colors.primary,
            '&:hover': {
              backgroundColor: alpha(Colors.primary, 0.1),
            },
          },
        },
      ],
      styleOverrides: {
        root: ({ ownerState }) => ({
          textTransform: 'none',
          boxShadow: 'none',
          borderRadius: 2,

          ...(ownerState.variant === 'outlined' && {
            backgroundColor: '#FFF',

            ...(ownerState.color === 'secondary' && {
              color: Colors.night,

              '&:hover': {
                backgroundColor: alpha('#FFF', 0.88),
              },
            }),
          }),
        }),
      },
    },
    MuiCheckbox: {
      defaultProps: {
        disableRipple: true,
      },
      styleOverrides: {
        root: {
          color: Colors.storm,
          '&.Mui-disabled': {
            color: Colors.iron,
          },
        },
      },
    },
    MuiTabs: {
      styleOverrides: {
        indicator: ({ theme }) => ({
          backgroundColor: theme.palette.primary.main,
          height: 4,
        }),
        flexContainer: {
          borderBottom: `1px solid ${Colors.iron}`,
        },
      },
    },
    MuiTab: {
      styleOverrides: {
        root: ({ theme }) => ({
          padding: theme.spacing(1.2, 2),
          color: theme.palette.secondary.main,
          lineHeight: 1.71429,
          textTransform: 'none',
          fontWeight: theme.typography.fontWeightMedium,

          '&.Mui-selected': {
            color: theme.palette.text.primary,
          },

          '&:hover': {
            color: theme.palette.text.primary,
          },
        }),
      },
    },
    MuiBreadcrumbs: {
      styleOverrides: {
        root: {
          minWidth: 0,
        },
        ol: {
          flexWrap: 'nowrap',
        },
        separator: {
          margin: 0,
        },
      },
    },
    MuiSkeleton: {
      styleOverrides: {
        root: {
          animationDuration: `2s`,
        },
      },
    },
    MuiMenuItem: {
      styleOverrides: {
        root: {
          '&.Mui-focusVisible': {
            backgroundColor: alpha(Colors.primary, 0.08),
          },
        },
      },
    },
    MuiFormControlLabel: {
      styleOverrides: {
        root: {
          '&.Mui-disabled': {
            color: Colors.iron,
          },
        },
      },
    },
    MuiTableCell: {
      styleOverrides: {
        head: {
          color: Colors.storm,
        },
        root: {
          borderBottom: `1px solid ${Colors.athens}`,
        },
      },
    },
    MuiTableHead: {
      styleOverrides: {
        root: {
          backgroundColor: Colors.athens,
          th: {
            backgroundColor: Colors.athens,
            whiteSpace: 'wrap',
          },
        },
      },
    },
  },
});
