import { Box } from '@mui/material';
import isEmpty from 'lodash/isEmpty';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { RecordingsFiltersList, useRecordings } from 'api/recording/useFetchRecordings';
import { AnnotationTypeFilter } from 'pages/recordings-list/components/recordings-list-page/filters-bar/annotation-type-filter';
import { RecordingListWithFilters } from 'pages/recordings-list/components/recordings-list-page/recording-list-with-filters';
import {
  RecordingTypesTabsAndFilters,
  selectInitialCompetitionIndex,
} from 'pages/recordings-list/components/recordings-list-page/recording-types-tabs-and-filters';
import styles from 'pages/recordings-list/components/recordings-list-page/RecordingsListPage.module.scss';
import { FILTERS_RECORDINGS_LISTS_KEY } from 'pages/recordings-list/constants/filtersKey';
import { POSSIBLE_FILTERS } from 'pages/recordings-list/constants/possibleFilters';
import { useRecordingsListFilters } from 'pages/recordings-list/hooks/useRecordingsListFilters';
import { TabType } from 'pages/recordings-list/RecordingsListPageContainer';
import { RecordingFilters } from 'pages/recordings-list/types/recording';
import Container from 'shared/components/container/Container';
import Pagination from 'shared/components/pagination/Pagination';
import { useUrlFilters } from 'shared/hooks/use-url-filters/useUrlFilters';
import { FilterOptions, FiltersFromUrl } from 'shared/types/filters/types';
import { entriesFromObject } from 'shared/utils/objectToArray';

interface Props {
  defaultFilters: RecordingsFiltersList;
  competitionsTabs: TabType[];
}

const generateInitialFilters = (
  initialFilters: Partial<FiltersFromUrl<RecordingsFiltersList>>,
  competitionsTabs: TabType[],
  defaultFilters: RecordingsFiltersList,
): Partial<RecordingsFiltersList> => {
  if (!initialFilters.competition) {
    const initialCompetition = selectInitialCompetitionIndex(initialFilters.competition, competitionsTabs);
    initialFilters.competition = competitionsTabs[initialCompetition]?.value[0];
  }

  return Object.fromEntries(
    entriesFromObject(defaultFilters).map(([key, value]) => {
      const urlFilterValue = initialFilters[key];

      if (urlFilterValue) {
        const urlFilterValueArray = urlFilterValue.split(',');
        const options = entriesFromObject(value.options).reduce<FilterOptions>(
          (optionAcc, [optionKey, optionValue]) => {
            optionAcc[optionKey] = {
              ...optionValue,
              isApplied: urlFilterValueArray.includes(optionKey),
            };
            return optionAcc;
          },
          {},
        );

        return [key, { ...value, options }];
      }

      return [key, value];
    }),
  );
};

export const RecordingsListPage = ({ defaultFilters, competitionsTabs }: Props): JSX.Element | null => {
  const { t } = useTranslation();
  const { getUrlFilters } = useRecordingsListFilters();

  const getInitialFilters = useCallback(
    () => generateInitialFilters(getUrlFilters(), competitionsTabs, defaultFilters),
    [competitionsTabs, defaultFilters, getUrlFilters],
  );

  const {
    data,
    totalElements,
    setRecordingsFilters,
    recordingsFilters,
    fetchNextPage,
    isFetching,
    isFetchingNextPage,
    RecordingsStateContext,
  } = useRecordings({ initialFilters: getInitialFilters });

  useUrlFilters({
    initialFilters: recordingsFilters,
    possibleFilters: POSSIBLE_FILTERS,
    sendFilters: setRecordingsFilters,
    stateId: FILTERS_RECORDINGS_LISTS_KEY,
  });

  return (
    <Container>
      <div className={styles['recordings-list-page']}>
        <div className={styles['container recordings-list-page__content']}>
          <Box sx={{ minHeight: 165 }}>
            <Box sx={{ marginBottom: 3 }}>
              <AnnotationTypeFilter
                annotationFilter={
                  isEmpty(recordingsFilters[RecordingFilters.ANNOTATION_TYPE])
                    ? defaultFilters[RecordingFilters.ANNOTATION_TYPE]
                    : recordingsFilters[RecordingFilters.ANNOTATION_TYPE]
                }
              />

              {competitionsTabs.length > 0 && (
                <RecordingTypesTabsAndFilters
                  appliedFilters={recordingsFilters}
                  competitionsTabs={competitionsTabs}
                  filters={defaultFilters}
                />
              )}
            </Box>
          </Box>
          <RecordingsStateContext>
            <RecordingListWithFilters isLoading={data.length === 0 && isFetching} recordings={data} />
          </RecordingsStateContext>
          {data.length ? (
            <Pagination
              total={totalElements}
              displayed={data.length}
              getStatsText={(displayed, total) =>
                t('recordings-list:pagination.total', { displayed, total, count: total })
              }
              onShowMore={fetchNextPage}
              loading={isFetchingNextPage || Boolean(data.length && isFetching)}
            />
          ) : null}
        </div>
      </div>
    </Container>
  );
};
