import { Typography } from '@mui/material';
import { fontSizes } from 'kognia-ui';

import { CoachPhoto } from 'pages/backoffice/pages/coaches/ui/coach-photo/CoachPhoto';
import {
  CoachPhotoContainer,
  CoachPhotoPreviewContainer,
} from 'pages/backoffice/pages/coaches/ui/coach-photo-preview/styled';
import { CoachPhotoSkeleton } from 'pages/backoffice/pages/coaches/ui/coach-photo-skeleton/CoachPhotoSkeleton';

interface Props {
  photoUrl?: string | null;
}

export const CoachPhotoPreview = ({ photoUrl }: Props) => {
  return (
    <CoachPhotoPreviewContainer>
      <CoachPhotoContainer>
        {photoUrl ? <CoachPhoto fullSize src={photoUrl} /> : <CoachPhotoSkeleton />}
      </CoachPhotoContainer>
      <Typography fontSize={fontSizes.small} marginTop={1}>
        Image preview
      </Typography>
    </CoachPhotoPreviewContainer>
  );
};
