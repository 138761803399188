import { Typography } from '@mui/material';
import { fontWeight } from 'kognia-ui';
import { IconDelete } from 'kognia-ui/icons/IconDelete';
import { IconEdit } from 'kognia-ui/icons/IconEdit';
import React, { useCallback, useState } from 'react';

import { useDeleteSeasonClients } from 'pages/backoffice/api/season-clients/useDeleteSeasonClients';
import { SeasonClientsFormFieldsNames } from 'pages/backoffice/pages/associate-season-with-clients/ui/season-clients-form/types';
import { SeasonClientsListItem } from 'pages/backoffice/pages/associate-season-with-clients/ui/season-clients-item/styled';
import { SeasonClientsModal } from 'pages/backoffice/pages/associate-season-with-clients/ui/SeasonClientsModal';
import ConfirmDialog from 'shared/components/confirm-dialog/ConfirmDialog';
import KebabMenu, { MenuListOption } from 'shared/components/kebab-menu/KebabMenu';
import { SeasonClients } from 'shared/types/user/types';

interface Props {
  seasonClients: SeasonClients;
}

export const SeasonClientsItem = ({ seasonClients }: Props) => {
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [isConfirmDeleteDialogOpen, setIsConfirmDeleteDialogOpen] = useState(false);
  const { deleteSeasonClients } = useDeleteSeasonClients();

  const handleEditModalOpen = () => setIsEditModalOpen(true);
  const handleEditModalClose = () => setIsEditModalOpen(false);

  const handleDeleteSeasonClients = useCallback(() => {
    deleteSeasonClients({ id: seasonClients.id });
  }, [seasonClients.id, deleteSeasonClients]);

  const options: MenuListOption[] = [
    {
      displayText: 'Edit',
      icon: <IconEdit size='small' color='secondary' />,
      onClick: handleEditModalOpen,
    },
    {
      displayText: 'Delete',
      icon: <IconDelete size='small' color='secondary' />,
      onClick: () => setIsConfirmDeleteDialogOpen(true),
    },
  ];

  const defaultValues = {
    [SeasonClientsFormFieldsNames.id]: seasonClients.id,
    [SeasonClientsFormFieldsNames.originalClient]: seasonClients.originalClient,
    [SeasonClientsFormFieldsNames.seasonId]: undefined,
    [SeasonClientsFormFieldsNames.clientIds]: seasonClients.clients.map((client) => client.id),
    [SeasonClientsFormFieldsNames.seasonName]: seasonClients.seasonName,
  };

  return (
    <>
      <SeasonClientsListItem key={seasonClients.id}>
        <Typography fontWeight={fontWeight['500']}>{seasonClients.originalClient.name}</Typography>
        <Typography>{seasonClients.seasonName}</Typography>
        <Typography>{seasonClients.clients.map((client) => client.name).join(', ')}</Typography>
        <KebabMenu options={options} />
      </SeasonClientsListItem>

      {isEditModalOpen ? (
        <SeasonClientsModal open={isEditModalOpen} onClose={handleEditModalClose} defaultValues={defaultValues} />
      ) : null}

      <ConfirmDialog
        type={'warning'}
        title={`You sure you want delete association from season "${seasonClients.seasonName}" and client "${seasonClients.originalClient.name}"?`}
        cancelLabel={'Cancel'}
        confirmLabel={'Delete'}
        onConfirm={handleDeleteSeasonClients}
        isOpen={isConfirmDeleteDialogOpen}
        setIsOpen={setIsConfirmDeleteDialogOpen}
      />
    </>
  );
};
