import Konva from 'konva';
import { IFrame } from 'konva/lib/types';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { OverlayGenerator, Quality } from '../main';
import { FrameInfo } from '../store';
import { OverlayElementsMetaData, RecordingOverlayData } from '../utils/loaders';

interface UseOverlayGeneratorParams {
  id?: string;
  recordingData?: RecordingOverlayData;
  overlayElementsMetaData?: OverlayElementsMetaData;
  containerRef?: React.RefObject<HTMLDivElement>;
}

export const useOverlayGenerator = ({ recordingData, overlayElementsMetaData }: UseOverlayGeneratorParams = {}) => {
  const overlayGenerator = useMemo(
    () =>
      new OverlayGenerator({
        imageInterface: Image,
        fetchInterface: fetch,
      }),
    [],
  );

  const [isReady, setIsReady] = useState<boolean>(overlayGenerator.store.store.getState().status.isReady);
  const [frameRate, setFrameRate] = useState<number>(overlayGenerator.store.store.getState().metaData.video.frameRate);
  const [frameInfo, setIsFrameInfo] = useState<FrameInfo>(overlayGenerator.store.store.getState().frameInfo);

  useEffect(() => {
    const unsubscribe = overlayGenerator.store.store.subscribe((newState) => {
      setIsReady(newState.status.isReady);
      setFrameRate(newState.metaData.video.frameRate);
      setIsFrameInfo(newState.frameInfo);
    });

    return () => {
      unsubscribe();
    };
  }, [overlayGenerator.store.store]);

  useEffect(() => {
    recordingData && overlayGenerator.setRecordingData(recordingData);
    overlayElementsMetaData && overlayGenerator.setOverlayElementsMetaData(overlayElementsMetaData);

    return () => {
      overlayGenerator.reset();
    };
  }, [overlayGenerator, recordingData, overlayElementsMetaData]);

  const createAnimation = useCallback(
    (callback: (frame?: IFrame) => void) => {
      return new Konva.Animation((frame) => {
        return callback(frame);
      }, overlayGenerator.overlayRendered.stage);
    },
    [overlayGenerator.overlayRendered.stage],
  );

  const changeQuality = useCallback(
    (quality: Quality) => {
      if (!quality) return;

      overlayGenerator.setQuality(quality);
    },
    [overlayGenerator],
  );

  return {
    overlayGenerator,
    isReady,
    frameRate,
    frameInfo,
    changeQuality,
    createAnimation,
  };
};
