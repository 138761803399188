import { useCallback, useEffect, useReducer, useState } from 'react';

import { Keypad, KeypadSimple } from 'api/tagging-tool/types';
import { useFetchKeypad } from 'api/tagging-tool/use-fetch-keypad';
import { useFetchKeypads } from 'api/tagging-tool/use-fetch-keypads';

export type UseKeyPads = {
  keypadsData?: KeypadSimple[];
  loadingKeypad?: boolean;
  keypadData?: Keypad;
};

export enum ActionType {
  SET_KEYPADS_DATA,
  SET_KEYPAD,
  SET_LOADING_KEYPAD,
}

export type Action =
  | {
      type: ActionType.SET_KEYPADS_DATA;
      keypads: KeypadSimple[];
      keypad: Keypad;
    }
  | {
      type: ActionType.SET_KEYPAD;
      keypad: Keypad;
    }
  | {
      type: ActionType.SET_LOADING_KEYPAD;
      loading: boolean;
    };

export type Dispatch = {
  handleChangeKeypad: (keypad: KeypadSimple) => void;
};

const reducer = (state: UseKeyPads, action: Action): UseKeyPads => {
  switch (action.type) {
    case ActionType.SET_KEYPADS_DATA:
      const { keypads, keypad } = action;
      return {
        ...state,
        keypadsData: keypads,
        keypadData: keypad,
        loadingKeypad: false,
      };

    case ActionType.SET_KEYPAD:
      return {
        ...state,
        keypadData: action.keypad,
        loadingKeypad: false,
      };

    case ActionType.SET_LOADING_KEYPAD:
      const { loading: loadingKeypad } = action;
      return {
        ...state,
        loadingKeypad,
      };

    default:
      return state;
  }
};

interface Params {
  recordingId: string;
  isLive: boolean;
}

export const useKeypads = ({ recordingId }: Params): [UseKeyPads, Dispatch] => {
  const [keypadId, setKeypadId] = useState('');
  const { data: keypads } = useFetchKeypads(recordingId);
  const { data: keypad, isError: isKeypadFetchError, refetch } = useFetchKeypad([keypadId]);
  const [state, dispatch] = useReducer(reducer, {});

  useEffect(() => {
    // CREATE KEYPAD IF IT DOES NOT EXIST
    if (!keypads?.length) {
      console.error('Did not find default keypad');
      return;
    }

    const defaultKeypad = keypads.filter((kp) => kp.isDefault);

    const { id } = defaultKeypad[0];
    setKeypadId(id);
  }, [keypads]);

  useEffect(() => {
    if (keypadId && refetch) {
      refetch();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [keypadId]);

  useEffect(() => {
    if (isKeypadFetchError) {
      dispatch({ type: ActionType.SET_LOADING_KEYPAD, loading: false });
      return;
    }

    if (keypad && keypads) {
      dispatch({ type: ActionType.SET_KEYPADS_DATA, keypads, keypad });
    }
  }, [keypad, isKeypadFetchError, keypads]);

  const handleChangeKeypad = useCallback((keypad: KeypadSimple) => {
    dispatch({ type: ActionType.SET_LOADING_KEYPAD, loading: true });

    const { id } = keypad;
    setKeypadId(id);
  }, []);

  return [
    state,
    {
      handleChangeKeypad,
    },
  ];
};
