export enum MappingProvider {
  KOGNIA = 'kognia',
  CHYRONHEGO = 'chyronhego',
  SECOND_SPECTRUM = 'second-spectrum',
  HAWKEYE = 'hawkeye',
  SIFNALITY = 'signality',
  SIFNALITY_KOGNIA = 'signality-kognia',
  SPORT_LOGIQ = 'sportlogiq',
  OPTA = 'opta',
  BUNDES_LIGA = 'bundesliga',
}
