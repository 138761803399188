import { useMutationRequest } from 'api/hooks/useMutationRequest';
import { HTTPMethod } from 'api/types';
import { CoachApi } from 'pages/backoffice/api/coaches/types';
import { annotationApiUrls } from 'pages/backoffice/api/routes';
import { useAllowedBackofficeOrigin } from 'pages/backoffice/api/useAllowedBackofficeOrigin';
import { metadataApiErrorParser, MetadataApiErrorResponse } from 'pages/backoffice/api/utils';

type CreateCoachParams = Pick<CoachApi, 'name'> & { image?: File };

interface CreateCoachData {
  data: CreateCoachParams;
  onSuccess?: () => void;
}

export const useCreateCoach = () => {
  const { getIsAllowedBackofficeOrigin } = useAllowedBackofficeOrigin();
  const mutationRequest = useMutationRequest<unknown, unknown, MetadataApiErrorResponse>({
    type: HTTPMethod.POST,
    successMessage: 'Coach created',
    errorMessage: (data) => metadataApiErrorParser(data, 'Coach creation error'),
  });

  const create = ({ data, onSuccess }: CreateCoachData) => {
    if (!getIsAllowedBackofficeOrigin()) return;

    const formData = new FormData();
    formData.append('coach', JSON.stringify({ name: data.name }));
    data.image && formData.append('coach_image', data.image);

    mutationRequest.mutate(
      {
        url: annotationApiUrls.COACHES,
        data: formData,
      },
      {
        onSuccess,
      },
    );
  };

  return { ...mutationRequest, create };
};
