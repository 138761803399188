import { CoachImageContainer, CoachImageElement } from 'pages/backoffice/pages/coaches/ui/coach-photo/styled';

interface Props {
  src: string;
  fullSize?: boolean;
}

export const CoachPhoto = ({ src, fullSize = false }: Props) => {
  return (
    <CoachImageContainer>
      <CoachImageElement src={src} alt='' fullSize={fullSize} />
    </CoachImageContainer>
  );
};
