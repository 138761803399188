import { useTranslation } from 'react-i18next';

import ExportModal, {
  ExportVideoTypes,
} from 'pages/tactical-analysis/components/tactical-analysis/filters-bar/export-button/export-modal/ExportModal';
import { ExportModals } from 'pages/tactical-analysis/components/tactical-analysis/filters-bar/export-button/ExportButton';

type ExportVideoModalProps = {
  tacticalAnalysisId: string | undefined;
  recordingId: string;
  onClose: () => void;
  fullMatchDownloadUrl?: string;
};

const ExportVideoModal = ({
  tacticalAnalysisId,
  recordingId,
  onClose,
  fullMatchDownloadUrl,
}: ExportVideoModalProps) => {
  const { t } = useTranslation();

  const options = [
    {
      type: ExportVideoTypes.FULL_MATCH,
      displayValue: t('timeline:actions.export.video.export-full-match-video'),
      downloadUrl: fullMatchDownloadUrl,
      enabled: !!fullMatchDownloadUrl,
    },
    {
      type: ExportVideoTypes.EFFECTIVE_TIME,
      displayValue: t('timeline:actions.export.video.export-effective-time-video'),
      enabled: tacticalAnalysisId !== undefined,
    },
  ];

  return (
    <ExportModal
      recordingId={recordingId}
      tacticalAnalysisId={tacticalAnalysisId}
      onClose={onClose}
      modalTitle={t('timeline:actions.export.video.export-video')}
      options={options}
      type={ExportModals.VIDEO}
    />
  );
};

export default ExportVideoModal;
