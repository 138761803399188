import { forwardRef } from 'react';

import KebabMenu, { MenuListOption } from 'shared/components/kebab-menu/KebabMenu';
import { useRecordingValidationOption } from 'shared/hooks/use-recording-validation-option';
import { Recording } from 'shared/types/recording/types';

type Props = {
  options?: MenuListOption[];
  extraOptions?: MenuListOption[];
  recording: Recording;
};

const KebabMenuRecordings = forwardRef<HTMLDivElement, Props>(({ options = [], extraOptions = [], recording }, ref) => {
  const validationOption = useRecordingValidationOption(recording.tacticalAnalysis);

  return <KebabMenu ref={ref} options={[...options, ...validationOption, ...extraOptions]} id={recording.id} />;
});

KebabMenuRecordings.displayName = 'KebabMenuRecordings';

export default KebabMenuRecordings;
