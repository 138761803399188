import { useCallback } from 'react';

import { useCurrentPlaylistItem, useVideoPlayerActions } from 'shared/components/video-player/hooks';
import { PlaylistItemType } from 'shared/components/video-player/types';

export const useHandleSetPlaylistItem = (playlistItem: PlaylistItemType) => {
  const actions = useVideoPlayerActions();
  const currentPlaylistItem = useCurrentPlaylistItem();

  return useCallback(
    (autoPlay = true) => {
      if (!autoPlay && currentPlaylistItem?.id === playlistItem.id) return;

      actions.setPlaylistItem(playlistItem.id, autoPlay);
    },
    [currentPlaylistItem, actions, playlistItem.id],
  );
};
