import classNames from 'classnames';
import { IconAnalysis } from 'kognia-ui/icons/IconAnalysis';
import { IconTime } from 'kognia-ui/icons/IconTime';
import { useTranslation } from 'react-i18next';

import styles from 'pages/recordings-list/components/vod-recording-card/recording-states/RecordingStates.module.scss';
import Spinner from 'shared/components/spinner/Spinner';
import { useUser } from 'shared/contexts/app-state/hooks/useUser';
import { TacticalAnalysis, TacticalAnalysisStates } from 'shared/types/recording/types';
import { User, UserAuthority } from 'shared/types/user/types';

interface Props {
  tacticalAnalysis: TacticalAnalysis | null;
  hidden: boolean;
}

const getTacticalAnalysisStateIcon = (user: User, tacticalAnalysisState?: TacticalAnalysisStates) => {
  if (tacticalAnalysisState === TacticalAnalysisStates.VALIDATED) {
    return <IconAnalysis size='small' color='quaternary' />;
  }

  if (
    tacticalAnalysisState === TacticalAnalysisStates.FINISHED &&
    user.authorities.includes(UserAuthority.ROLE_ADMIN)
  ) {
    return <IconTime size='small' color='quaternary' />;
  }

  if (
    tacticalAnalysisState === TacticalAnalysisStates.STARTED ||
    (tacticalAnalysisState === TacticalAnalysisStates.FINISHED && !user.authorities.includes(UserAuthority.ROLE_ADMIN))
  ) {
    return <Spinner color='quaternary' size='small' />;
  }

  return <IconAnalysis size='small' color='disabled' />;
};

const StateAnalysis = ({ tacticalAnalysis, hidden = false }: Props) => {
  const { t } = useTranslation();
  const user = useUser();

  return (
    <span className={classNames(tacticalAnalysis ? styles.active : styles.inactive)}>
      <div className={classNames(styles.stateBlock, { [styles.stateInfoHidden]: hidden })}>
        {getTacticalAnalysisStateIcon(user, tacticalAnalysis?.state)}
        <div>{t('recording:states.analysis')}</div>
      </div>
    </span>
  );
};

export default StateAnalysis;
