import { FieldErrors, UseFormRegister } from 'react-hook-form';

import { processFixtureFormSchema } from 'pages/backoffice/widgets/process-fixture/ProcessFixtureForm/types/processFixtureForm.types';
import { FilesHawkeyeFieldsNames } from 'pages/backoffice/widgets/process-fixture/ProcessFixtureForm/types/processFixtureFormSubforms.types';
import { FormInputField } from 'shared/components/form/ui/FormInputField';

interface Props {
  register: UseFormRegister<processFixtureFormSchema>;
  errors: FieldErrors<processFixtureFormSchema>;
}

export function HawkeyeForm({ errors, register }: Props) {
  const textFieldsList = [
    {
      id: FilesHawkeyeFieldsNames.ballFolder,
      label: 'Ball (folder)',
    },
    {
      id: FilesHawkeyeFieldsNames.centroidsFolder,
      label: 'Centroids (folder)',
    },
  ];

  return (
    <div>
      {textFieldsList.map((textField) => (
        <FormInputField
          key={textField.id}
          id={textField.id}
          label={textField.label}
          error={!!errors[textField.id]}
          fullWidth
          helperText={errors[textField.id] && <>{errors[textField.id]?.message}</>}
          {...register(textField.id)}
        />
      ))}
    </div>
  );
}
