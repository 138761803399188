import { List } from '@mui/material';

import { PlayerItem } from 'pages/backoffice/pages/players/components/player-item/PlayerItem';
import { Player } from 'shared/types/player/player';

interface Props {
  players: Player[];
}

export const PlayersList = ({ players }: Props) => {
  const playersItems = players.map((player) => {
    return <PlayerItem key={player.id} player={player} />;
  });

  return <List>{playersItems}</List>;
};
