import React from 'react';

import { FormFieldProps } from 'pages/backoffice/pages/annotation/ui/form-fields/types';
import { AnnotationFormLabels } from 'pages/backoffice/pages/annotation/utils/form-fields-validation';
import { AnnotationFormFieldsNames } from 'pages/backoffice/pages/annotation/utils/form-fields-validation/types';
import { FormInputField } from 'shared/components/form/ui/FormInputField';

export const RecordingIdField = ({ errors = {}, register }: FormFieldProps) => {
  return (
    <FormInputField
      id={AnnotationFormFieldsNames.RECORDING_ID}
      label={AnnotationFormLabels[AnnotationFormFieldsNames.RECORDING_ID]}
      error={!!errors[AnnotationFormFieldsNames.RECORDING_ID]}
      fullWidth
      helperText={
        errors[AnnotationFormFieldsNames.RECORDING_ID] && <>{errors[AnnotationFormFieldsNames.RECORDING_ID]?.message}</>
      }
      {...register(AnnotationFormFieldsNames.RECORDING_ID)}
    />
  );
};
