import { Box, styled } from '@mui/material';
import { Colors } from 'kognia-ui';
import { useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useParams } from 'react-router-dom';

import { UseTimelineMatchTaggingData } from 'pages/tactical-analysis/api/use-tactical-analysis-data/types';
import { AddToPlaylistMenuStateProvider } from 'pages/tactical-analysis/components/tactical-analysis/add-to-playlist-menu/add-to-playlist-menu-state';
import { TacticalAnalysisPageSkeleton } from 'pages/tactical-analysis/components/tactical-analysis/tactical-analysis-page-skeleton';
import { TacticalAnalysis } from 'pages/tactical-analysis/components/tactical-analysis/TacticalAnalysis';
import { useTacticalAnalysisPage } from 'pages/tactical-analysis/hooks/use-tactical-analysis-page';
import { useTimelineInitialTime } from 'pages/tactical-analysis/hooks/use-timeline-initial-time';
import Container from 'shared/components/container/Container';
import NotFound from 'shared/components/not-found/NotFound';
import { SwitchEnvironment } from 'shared/components/switch-environment/SwitchEnvironment';
import { VideoPlayerStateProvider } from 'shared/components/video-player';
import { useBranding } from 'shared/hooks/use-branding/useBranding';
import usePageStateMachine from 'shared/hooks/use-page-state-machine';
import { groupTagsByType } from 'shared/utils/group-tags-by-type';
import { invariant } from 'shared/utils/invariant';

const NotFoundContainer = styled(Box)(() => ({
  height: '100%',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  backgroundColor: Colors.background,
}));

export const TacticalAnalysisContainer = () => {
  const { t } = useTranslation();
  const { state } = useLocation();
  const { id } = useParams();
  invariant(id);
  const initialTimePreset = useTimelineInitialTime(id);
  const branding = useBranding();

  useEffect(() => {
    document.title = t('common:metas.title.tactical-analysis', { clientDisplayName: branding.displayName });
  }, [branding.displayName, t]);

  const { current, data, PAGE_STATES, isInvalidClient } = usePageStateMachine<UseTimelineMatchTaggingData>(() =>
    useTacticalAnalysisPage(id),
  );

  const initialStartTimeFromClip = useMemo(
    () => state?.initialStartTimeFromClip || initialTimePreset,
    [initialTimePreset, state?.initialStartTimeFromClip],
  );

  if (isInvalidClient && data?.matchWithEpisodes?.match.clientIds) {
    return <SwitchEnvironment resourceClientsIds={data.matchWithEpisodes.match.clientIds} />;
  }

  return (
    <Container fullScreen>
      {current.matches(PAGE_STATES.LOADING) && <TacticalAnalysisPageSkeleton />}
      {current.matches(PAGE_STATES.MISSING_DATA) ||
        (current.matches(PAGE_STATES.ERROR) && (
          <NotFoundContainer>
            <NotFound header={t('timeline:not-found')} showContactLink />
          </NotFoundContainer>
        ))}
      {current.matches(PAGE_STATES.READY) && data?.matchWithEpisodes && data?.taggingEvents && data?.playlistItems && (
        <VideoPlayerStateProvider
          playerId={`timeline-${data.matchWithEpisodes.match.id}`}
          playlistItems={data.playlistItems}
          playingMode={data.initialPlayingMode}
          initialStartTime={initialStartTimeFromClip}
        >
          <AddToPlaylistMenuStateProvider id={'timeline-playlist-menu'}>
            <TacticalAnalysis
              match={data.matchWithEpisodes.match}
              recordingId={id}
              groupedTags={groupTagsByType(data.taggingEvents.tags)}
            />
          </AddToPlaylistMenuStateProvider>
        </VideoPlayerStateProvider>
      )}
    </Container>
  );
};
