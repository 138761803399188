import { EnvironmentFormField } from 'pages/backoffice/features/environment-form/types/environmentFormField';
import { EnvironmentFormFieldsNames } from 'pages/backoffice/features/environment-form/utils/environmentFormFieldsNames';
import { getTypeOptions } from 'shared/components/form/utils/getTypeOptions';
import { Countries } from 'shared/utils/countries';

interface Props {
  isEdit: boolean;
}

export const generateEnvironmentFormFields = ({ isEdit }: Props): EnvironmentFormField[] => [
  {
    id: EnvironmentFormFieldsNames.ID,
    component: 'input',
    fullWidth: true,
    disabled: isEdit,
  },
  {
    id: EnvironmentFormFieldsNames.NAME,
    component: 'input',
    fullWidth: true,
  },
  {
    id: EnvironmentFormFieldsNames.CITY,
    component: 'input',
    fullWidth: true,
  },
  {
    id: EnvironmentFormFieldsNames.COUNTRY,
    component: 'select',
    options: getTypeOptions({
      options: Countries.map((country) => ({
        value: country.code,
        label: country.name,
      })),
    }),
  },
];
