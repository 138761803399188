import { Box, Button, Stack, Typography } from '@mui/material';
import { fontSizes } from 'kognia-ui';
import { IconPlus } from 'kognia-ui/icons/IconPlus';
import { useCallback, useState } from 'react';

import { useSeasonClients } from 'pages/backoffice/api/season-clients/useSeasonClients';
import { SeasonClientsListHeaderGrid } from 'pages/backoffice/pages/associate-season-with-clients/styled';
import { SeasonClientsList } from 'pages/backoffice/pages/associate-season-with-clients/ui/SeasonClientsList';
import { SeasonClientsModal } from 'pages/backoffice/pages/associate-season-with-clients/ui/SeasonClientsModal';
import { BackofficeContentContainer } from 'pages/backoffice/ui/BackofficeContentContainer';
import { ListSkeleton } from 'pages/backoffice/ui/ListSkeleton';
import { ListHeader } from 'shared/components/list/ui/list-header/ListHeader';
import NotFound from 'shared/components/not-found/NotFound';
import Pagination from 'shared/components/pagination/Pagination';

export const SeasonClients = () => {
  const [isCreateModalOpen, setIsCreateModalOpen] = useState(false);
  const { items, page, fetchNextPage, isFetching } = useSeasonClients();

  const handleCreateModalOpen = useCallback(() => setIsCreateModalOpen(true), []);
  const handleCreateModalClose = useCallback(() => setIsCreateModalOpen(false), []);

  return (
    <BackofficeContentContainer>
      <Stack direction='row' alignItems='center' justifyContent='space-between' marginBottom={2}>
        <Typography variant='h4'>Associations season clients</Typography>
        <Button
          variant='contained'
          size='large'
          startIcon={<IconPlus size='small' sx={{ color: 'common.white' }} />}
          onClick={handleCreateModalOpen}
        >
          New association season clients
        </Button>
      </Stack>
      <ListHeader>
        <SeasonClientsListHeaderGrid>
          <Typography variant='body1' fontSize={fontSizes.default} fontWeight={'fontWeightMedium'}>
            Original client
          </Typography>
          <Typography variant='body1' fontSize={fontSizes.default} fontWeight={'fontWeightMedium'}>
            Competition
          </Typography>
          <Typography variant='body1' fontSize={fontSizes.default} fontWeight={'fontWeightMedium'}>
            Clients
          </Typography>
          <div>&nbsp;</div>
        </SeasonClientsListHeaderGrid>
      </ListHeader>
      {!isFetching && items.length === 0 ? (
        <Box marginTop={4}>
          <NotFound header={'No results'} />
        </Box>
      ) : null}
      {isFetching ? <ListSkeleton /> : <SeasonClientsList seasonClientsList={items} />}
      {page ? (
        <Pagination
          total={page.totalElements}
          displayed={items.length}
          onShowMore={fetchNextPage}
          getStatsText={(displayed, total) => `${displayed} of ${total} venues`}
        />
      ) : null}
      <SeasonClientsModal open={isCreateModalOpen} onClose={handleCreateModalClose} />
    </BackofficeContentContainer>
  );
};
