import { Stack } from '@mui/material';
import { ReactNode, useCallback, useState } from 'react';

import { PlaylistActions } from 'entities/playlist/ui/PlaylistActions';
import { PlaylistContainerGrid } from 'entities/playlist/ui/PlaylistContainerGrid';
import { PlaylistDetails } from 'entities/playlist/ui/PlaylistDetails';
import { PlaylistHeaderGrid } from 'entities/playlist/ui/PlaylistHeaderGrid';
import { PlaylistTimelineContainer } from 'entities/playlist/ui/PlaylistTimelineContainer';
import { PlaylistTimelineHeader } from 'entities/playlist/ui/PlaylistTimelineHeader';
import { PlaylistVideoPlayerContainer } from 'entities/playlist/ui/PlaylistVideoPlayerContainer';
import { DownloadCurrentPlaylistItemModal } from 'features/playlist/DownloadCurrentPlaylistItemModal';
import { EnableBulkModeButton } from 'features/playlist/EnableBulkModeButton';
import { PlaylistOverlaysSelectorPanelFeature } from 'features/playlist/playlist-overlay-selector-panel/PlaylistOverlaySelectorPanel.feature';
import { useCurrentPlaylistItem, useVideoPlayerActions } from 'shared/components/video-player';
import VideoPlayerComponent from 'shared/components/video-player/video-player-component';
import { Playlist } from 'shared/types';

type Props = {
  actionsListComponent?: ReactNode;
  itemsListComponent: ReactNode;
  playlist: Playlist;
  timelineActionsComponent?: ReactNode;
  titleComponent: ReactNode;
  readyOnly?: boolean;
  messageComponent?: ReactNode;
};

export const PlaylistLayoutWidget = ({
  actionsListComponent,
  titleComponent,
  itemsListComponent,
  playlist,
  timelineActionsComponent,
  messageComponent,
  readyOnly,
}: Props) => {
  const actions = useVideoPlayerActions();
  const playlistItem = useCurrentPlaylistItem();

  const [isCurrentItemDownloadModalOpen, setIsCurrentItemDownloadModalOpen] = useState(false);

  const handleOpenDownloadCurrentItemModal = useCallback(() => {
    actions.handleStandBy();
    setIsCurrentItemDownloadModalOpen(true);
  }, [actions]);

  const handleCloseDownloadCurrentItemModal = useCallback(() => {
    actions.resumeStandBy();
    setIsCurrentItemDownloadModalOpen(false);
  }, [actions]);

  return (
    <PlaylistContainerGrid container>
      <PlaylistHeaderGrid xs={12} item>
        <Stack direction={'row'} alignItems={'center'} justifyContent={'space-between'} flex={1}>
          <Stack direction={'row'} alignItems={'center'} gap={1}>
            {titleComponent}
          </Stack>
          {actionsListComponent ? (
            <Stack direction={'row'} spacing={2}>
              {actionsListComponent}
            </Stack>
          ) : null}
        </Stack>
      </PlaylistHeaderGrid>
      <PlaylistVideoPlayerContainer display='flex' item xs={12}>
        <VideoPlayerComponent
          onDownloadSource={handleOpenDownloadCurrentItemModal}
          showVideoPlayerBar
          disabledTacticDrawings={!playlistItem.showOverlays}
        />
        {playlistItem.hasHomographies && playlistItem.showOverlays ? (
          <PlaylistOverlaysSelectorPanelFeature key={playlistItem.id} playlistId={playlist.id} readOnly={readyOnly} />
        ) : null}
      </PlaylistVideoPlayerContainer>
      <PlaylistTimelineContainer item xs={12}>
        <PlaylistTimelineHeader>
          <Stack direction={'row'} gap={2}>
            <PlaylistDetails playlist={playlist} />
            {messageComponent ? messageComponent : null}
          </Stack>
          <PlaylistActions>
            <EnableBulkModeButton playlistId={playlist.id} />
            {timelineActionsComponent}
          </PlaylistActions>
        </PlaylistTimelineHeader>
        {itemsListComponent}
      </PlaylistTimelineContainer>

      <DownloadCurrentPlaylistItemModal
        playlist={playlist}
        isOpen={isCurrentItemDownloadModalOpen}
        onClose={handleCloseDownloadCurrentItemModal}
      />
    </PlaylistContainerGrid>
  );
};
