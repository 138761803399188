import { Box, ClickAwayListener, ListItem } from '@mui/material';
import React, { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useTeams } from 'pages/backoffice/api/teams/use-teams/useTeams';
import { MetadataTeam } from 'pages/backoffice/types/fixture';
import { RenderSelectOption } from 'pages/backoffice/ui/RenderSelectOption';
import { Autocomplete } from 'shared/components/autocomplete/Autocomplete';
import { AutocompleteDropdownButton } from 'shared/components/autocomplete-multi-select/ui/AutocompleteDropdownButton';
import {
  AutocompletePopper,
  AutocompletePopperContent,
} from 'shared/components/autocomplete-multi-select/ui/AutocompletePopperContent';
import { AutocompletePopperWrapper } from 'shared/components/autocomplete-multi-select/ui/AutocompletePopperWrapper';

export type TeamsFilters = {
  name: string;
};

const INITIAL_TEAMS_FILTERS: TeamsFilters = {
  name: '',
};

interface SearchResultsProps {
  open: boolean;
  children: React.ReactNode;
}

export const SearchResults = ({ open, children }: SearchResultsProps) => {
  if (!open) return null;

  return <Box>{children}</Box>;
};

interface Props {
  setTeamOnChange: (team: MetadataTeam | undefined) => void;
  team: MetadataTeam;
  disabled?: boolean;
}

const AUTOCOMPLETE_WIDTH = 400;

export const SelectMetadataTeam = ({ team, setTeamOnChange, disabled = false }: Props) => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [autocompleteValue, setAutocompleteValue] = useState<MetadataTeam | undefined>();
  const { items: teamsResults, isLoading, setFilters, fetchNextPage } = useTeams();
  const { t } = useTranslation();

  const isOpen = Boolean(anchorEl);

  const open = useCallback(
    (event: React.MouseEvent<HTMLElement>) => {
      setAutocompleteValue(team);
      setAnchorEl(event.currentTarget);
    },
    [team],
  );

  const close = useCallback(() => {
    setFilters(INITIAL_TEAMS_FILTERS);
    setAnchorEl(null);
  }, [setFilters]);

  const handleDropdownClick = useCallback(
    (event: React.MouseEvent<HTMLElement>) => {
      isOpen ? close() : open(event);
    },
    [isOpen, close, open],
  );

  const handleSelectTeam = useCallback(() => {
    setAutocompleteValue((autocompleteValue) => {
      setTeamOnChange(autocompleteValue);
      return autocompleteValue;
    });
    close();
  }, [close, setTeamOnChange]);

  const handleSetName = useCallback(
    (name: string) => {
      setFilters({ name: name });
    },
    [setFilters],
  );

  const renderOption = useCallback((props: React.ComponentProps<typeof ListItem>, option: MetadataTeam) => {
    return <RenderSelectOption {...props} key={option.id} name={option.name} />;
  }, []);

  const paperComponent = useCallback(
    (props: any) => (
      <SearchResults {...props} open={isOpen}>
        {props.children}
      </SearchResults>
    ),
    [isOpen],
  );

  const handleUpdateValue = useCallback(
    (item: MetadataTeam | null) => {
      if (item === null) return;
      setAutocompleteValue(item);
      setTeamOnChange(item);
      close();
    },
    [setAutocompleteValue, setTeamOnChange, close],
  );

  const isOptionEqual = useCallback((option: MetadataTeam, value: MetadataTeam) => option.id === value.id, []);
  const getOptionName = useCallback((option: MetadataTeam) => option.name, []);

  return (
    <Box>
      <AutocompleteDropdownButton
        isOpen={isOpen}
        fullWidth
        onClick={handleDropdownClick}
        isSelected={Boolean(team)}
        label={team ? team.name : 'Select team'}
        disabled={disabled}
      />
      {anchorEl && (
        <ClickAwayListener onClickAway={handleSelectTeam}>
          <AutocompletePopper anchorEl={anchorEl} open={isOpen} placement='bottom-start'>
            <AutocompletePopperContent elevation={8}>
              <Autocomplete
                autoFocus
                PaperComponent={paperComponent}
                PopperComponent={AutocompletePopperWrapper}
                fetchNextPage={fetchNextPage}
                getItemLabel={getOptionName}
                inputWidth={AUTOCOMPLETE_WIDTH}
                isLoading={isLoading}
                isOptionEqualToValue={isOptionEqual}
                listWidth={AUTOCOMPLETE_WIDTH}
                multiple={false}
                onChange={handleSetName}
                open
                options={teamsResults}
                renderOption={renderOption}
                placeholder={t('common:actions.search')}
                resultsHeight={260}
                updateValue={handleUpdateValue}
                value={autocompleteValue}
              />
            </AutocompletePopperContent>
          </AutocompletePopper>
        </ClickAwayListener>
      )}
    </Box>
  );
};
