import { Accordion, AccordionDetails, AccordionSummary, Stack, Typography } from '@mui/material';
import { IconChevronDown } from 'kognia-ui/icons/IconChevronDown';
import { FieldErrors, UseFormRegister } from 'react-hook-form';

import { processFixtureFormSchema } from 'pages/backoffice/widgets/process-fixture/ProcessFixtureForm/types/processFixtureForm.types';
import { FilesOptaFieldsNames } from 'pages/backoffice/widgets/process-fixture/ProcessFixtureForm/types/processFixtureFormSubforms.types';
import { FormInputField } from 'shared/components/form/ui/FormInputField';

const textFieldsList = [
  {
    id: FilesOptaFieldsNames.f7Xml,
    label: 'f7 data (.xml)',
  },
  {
    id: FilesOptaFieldsNames.f24Xml,
    label: 'f24 data (.xml)',
  },
];

interface Props {
  register: UseFormRegister<processFixtureFormSchema>;
  errors: FieldErrors<processFixtureFormSchema>;
}

export function OptaForm({ errors, register }: Props) {
  return (
    <Accordion sx={{ marginBottom: 2 }}>
      <AccordionSummary expandIcon={<IconChevronDown size='small' />}>
        <Stack direction={'row'} alignItems={'center'} gap={1}>
          XML Files <Typography variant={'caption'}>(if available)</Typography>
        </Stack>
      </AccordionSummary>
      <AccordionDetails>
        {textFieldsList.map((textField) => (
          <FormInputField
            key={textField.id}
            id={textField.id}
            label={textField.label}
            error={!!errors[textField.id]}
            fullWidth
            helperText={errors[textField.id] && <>{errors[textField.id]?.message}</>}
            {...register(textField.id)}
          />
        ))}
      </AccordionDetails>
    </Accordion>
  );
}
