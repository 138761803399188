import { Button, Stack, Typography } from '@mui/material';
import { IconPlus } from 'kognia-ui/icons/IconPlus';

import { CreateUserModal } from 'pages/backoffice/features/users/create-user-modal/CreateUserModal';
import { AddUsersToEnvironmentsModal } from 'pages/backoffice/widgets/users/user-actions-header/ui/AddUsersToEnvironmentsModal';
import { useModalState } from 'shared/hooks/use-modal-state/useModalState';

export const UserActionsHeaderWidget = () => {
  const {
    isOpenModal: isOpenCreateUserModal,
    openModal: handleOpenCreateUserModal,
    closeModal: handleCloseCreateUserModal,
  } = useModalState();
  const {
    isOpenModal: isOpenAddUsersToEnvironmentsModal,
    openModal: handleOpenAddUsersToEnvironmentsModal,
    closeModal: handleCloseAddUsersToEnvironmentsModal,
  } = useModalState();

  return (
    <Stack direction='row' alignItems='center' justifyContent='space-between' marginBottom={2}>
      <Typography variant='h4'>Users</Typography>
      <Stack direction='row' gap={2}>
        <Button
          variant='contained'
          size='large'
          startIcon={<IconPlus size='small' sx={{ color: 'common.white' }} />}
          onClick={handleOpenAddUsersToEnvironmentsModal}
        >
          Add users to clients
        </Button>

        <Button
          variant='contained'
          size='large'
          startIcon={<IconPlus size='small' sx={{ color: 'common.white' }} />}
          onClick={handleOpenCreateUserModal}
        >
          New User
        </Button>
      </Stack>

      <CreateUserModal open={isOpenCreateUserModal} onClose={handleCloseCreateUserModal} />
      {isOpenAddUsersToEnvironmentsModal && (
        <AddUsersToEnvironmentsModal
          open={isOpenAddUsersToEnvironmentsModal}
          onClose={handleCloseAddUsersToEnvironmentsModal}
        />
      )}
    </Stack>
  );
};
