import { zodResolver } from '@hookform/resolvers/zod';
import { Stack, Typography } from '@mui/material';
import { Colors, fontSizes } from 'kognia-ui';
import { IconTrim } from 'kognia-ui/icons/IconTrim';
import { useCallback, useRef } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { createTrimItemsPayload, useBatchUpdatePlaylistItems } from 'api/playlist/useBatchUpdatePlaylistItems';
import { invalidatePlaylistQuery } from 'api/playlist/usePlaylist';
import { useMapVideos } from 'entities/playlist/hooks/use-map-videos/useMapVideos';
import { useSetIsBulkModeActive } from 'entities/playlist/hooks/useIsBulkModeActive';
import {
  TrimFormFieldsNames,
  trimFormId,
  TrimFormSchema,
} from 'features/playlist/user-playlist-items-list/ui/trim-modal/trimForm';
import { trimFormSchema } from 'features/playlist/user-playlist-items-list/ui/trim-modal/trimFormSchema';
import { DialogNew } from 'shared/components/dialog-new/DialogNew';
import { InputAdornment } from 'shared/components/input-pill-adornment/InputPillAdornment';
import { NumberField } from 'shared/components/number-field/NumberField';
import { useVideoPlayerActions } from 'shared/components/video-player';

interface Props {
  onClose: () => void;
  itemsToTrim: string[];
  playlistId: string;
}

const MINIMUM_SECONDS = -99;
const MAXIMUM_SECONDS = 99;
const SECONDS_INPUT_CONTAINER_WIDTH = 112;

export const TrimModal = ({ onClose, itemsToTrim, playlistId }: Props) => {
  const { t } = useTranslation();
  const formRef = useRef<HTMLFormElement>(null);
  const videoPlayerActions = useVideoPlayerActions();
  const mapVideos = useMapVideos();
  const setEnabledBulkMode = useSetIsBulkModeActive(playlistId);
  const {
    handleSubmit,
    control,
    formState: { isDirty },
  } = useForm<TrimFormSchema>({
    resolver: zodResolver(trimFormSchema),
  });

  const { updateBatchPlaylistItems } = useBatchUpdatePlaylistItems(playlistId);

  const onSubmit = useCallback(
    (data: TrimFormSchema) => {
      const payload = createTrimItemsPayload({
        playlistItemIds: itemsToTrim,
        before: data.startTime,
        after: data.endTime,
      });

      updateBatchPlaylistItems(payload, (playlist) => {
        invalidatePlaylistQuery && invalidatePlaylistQuery();
        videoPlayerActions.replacePlaylistItems(mapVideos(playlist));
        setEnabledBulkMode(false);
        onClose();
      });
    },
    [itemsToTrim, mapVideos, onClose, setEnabledBulkMode, updateBatchPlaylistItems, videoPlayerActions],
  );

  return (
    <DialogNew
      open={true}
      onClose={onClose}
      title={t('playlist-detail:trim-clips.title')}
      icon={<IconTrim size='small' />}
      fullWidth
      maxWidth={'xs'}
      onCancel={onClose}
      buttonFormId={trimFormId}
      submitDisabled={!isDirty}
    >
      <form id={trimFormId} onSubmit={handleSubmit(onSubmit)} ref={formRef}>
        <Stack justifyContent='center' direction='column' alignItems='center' sx={{ maxWidth: 280, mx: 'auto' }}>
          <Stack
            spacing={2}
            direction='row'
            alignItems='center'
            justifyContent='space-between'
            sx={{ borderBottom: `1px solid ${Colors.athens}`, pb: 2, width: '100%' }}
          >
            <Typography
              fontSize={fontSizes.default}
              variant='body1'
              fontWeight='fontWeightMedium'
              sx={{ flexShrink: 0 }}
            >
              {t('playlist-detail:trim-clips.start-time')}
            </Typography>
            <Stack flexGrow={0}>
              <Controller
                control={control}
                render={({ field }) => (
                  <NumberField
                    placeholder={'0'}
                    showControls
                    size='small'
                    value={field.value}
                    onChange={field.onChange}
                    inputProps={{
                      min: MINIMUM_SECONDS,
                      max: MAXIMUM_SECONDS,
                      'data-testid': 'trim-clips-start-time-input',
                    }}
                    InputProps={{
                      endAdornment: <InputAdornment>{t('playlist-detail:trim-clips.seconds')}</InputAdornment>,
                      sx: { width: SECONDS_INPUT_CONTAINER_WIDTH },
                    }}
                  />
                )}
                name={TrimFormFieldsNames.startTime}
              />
            </Stack>
          </Stack>
          <Stack
            justifyContent='space-between'
            spacing={2}
            direction='row'
            alignItems='center'
            sx={{ pt: 2, width: '100%' }}
          >
            <Typography
              fontSize={fontSizes.default}
              variant='body1'
              fontWeight='fontWeightMedium'
              sx={{ flexShrink: 0 }}
            >
              {t('playlist-detail:trim-clips.end-time')}
            </Typography>
            <Stack flexGrow={0}>
              <Controller
                control={control}
                render={({ field }) => (
                  <NumberField
                    placeholder={'0'}
                    showControls
                    size='small'
                    value={field.value}
                    onChange={field.onChange}
                    inputProps={{
                      min: MINIMUM_SECONDS,
                      max: MAXIMUM_SECONDS,
                      'data-testid': 'trim-clips-end-time-input',
                    }}
                    InputProps={{
                      endAdornment: <InputAdornment>{t('playlist-detail:trim-clips.seconds')}</InputAdornment>,
                      sx: { width: SECONDS_INPUT_CONTAINER_WIDTH },
                    }}
                  />
                )}
                name={TrimFormFieldsNames.endTime}
              />
            </Stack>
          </Stack>
        </Stack>
      </form>
    </DialogNew>
  );
};
