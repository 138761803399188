import { Button, DialogActions, DialogContent, DialogContentText, DialogTitle, Typography } from '@mui/material';
import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { TaggingEventsFilters, TaggingEventsFilterType } from 'api/tagging-tool/types';
import { TaggingEventsFilterOptions } from 'api/tagging-tool/use-fetch-tagging-events/utils';
import { TagFiltersContent } from 'pages/tagging-tool-tag-recording/components/tag-filters-dialog/styled';
import { TagFilterType } from 'pages/tagging-tool-tag-recording/components/tag-filters-dialog/tag-filter-type';
import { Dialog } from 'shared/components/dialog/Dialog';
import { TypeOfPlay } from 'shared/types/type-of-play/types';

export type TagFiltersModalProps = {
  show: boolean;
  resetFilters: () => TaggingEventsFilters;
  onClose: (reset?: boolean) => void;
  filterOptions: TaggingEventsFilterOptions;
  appliedFilters: TaggingEventsFilters;
  applyFilters: (value: TaggingEventsFilters) => void;
};

export const TagFiltersModal = ({
  show,
  onClose,
  filterOptions,
  appliedFilters,
  resetFilters,
  applyFilters,
}: TagFiltersModalProps) => {
  const [filters, setFilters] = useState<TaggingEventsFilters>(appliedFilters);
  const { t } = useTranslation();

  const handelApplyFilters = useCallback(() => {
    applyFilters(filters);
    onClose();
  }, [applyFilters, onClose, filters]);

  const handleResetFilters = useCallback(() => {
    const initialFilters = resetFilters();
    setFilters(initialFilters);
    onClose();
  }, [onClose, resetFilters]);

  const updateFilterType = useCallback((filterType: keyof TaggingEventsFilters, value: TaggingEventsFilterType) => {
    setFilters((filters) => ({ ...filters, [filterType]: value }));
  }, []);

  return (
    <Dialog keepMounted={false} open={show} maxWidth='lg' fullWidth onClose={() => onClose()}>
      <DialogTitle>
        <DialogContentText>
          <Typography textTransform='uppercase' component='span'>{`${t(
            'tagging-tool:tagging-recording.filter-by',
          )}: `}</Typography>
          <Typography component='span'>{t('tagging-tool:tagging-recording.filter-tags-type')}</Typography>
        </DialogContentText>
      </DialogTitle>
      <DialogContent>
        <TagFiltersContent>
          <TagFilterType
            filterOptions={filterOptions.offensive}
            filterType={TypeOfPlay.Offense}
            onChange={updateFilterType}
            selectedOptions={filters[TypeOfPlay.Offense]}
            title={t('tagging-tool:tagging-recording.filters-offensive')}
          />
          <TagFilterType
            filterOptions={filterOptions.defensive}
            filterType={TypeOfPlay.Defense}
            onChange={updateFilterType}
            selectedOptions={filters[TypeOfPlay.Defense]}
            title={t('tagging-tool:tagging-recording.filters-defensive')}
          />
          <TagFilterType
            filterOptions={filterOptions.transitions}
            filterType={TypeOfPlay.Transition}
            onChange={updateFilterType}
            selectedOptions={filters[TypeOfPlay.Transition]}
            title={t('tagging-tool:tagging-recording.filter-transitions')}
          />
          <TagFilterType
            filterOptions={filterOptions.imported}
            filterType={TypeOfPlay.imported}
            onChange={updateFilterType}
            selectedOptions={filters[TypeOfPlay.imported]}
            title={t('tagging-tool:tagging-recording.filter-imported')}
          />
          <TagFilterType
            filterOptions={filterOptions.user}
            filterType={'user'}
            onChange={updateFilterType}
            selectedOptions={filters.user}
            title={t('tagging-tool:tagging-recording.filter-user')}
          />
        </TagFiltersContent>
      </DialogContent>
      <DialogActions>
        <Button variant='contained' color='secondary' size='large' onClick={handleResetFilters}>
          {t('common:actions.reset')}
        </Button>
        <Button variant='contained' size='large' onClick={handelApplyFilters}>
          {t('common:actions.apply')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
