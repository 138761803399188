import { useMutationRequest } from 'api/hooks/useMutationRequest';
import { HTTPMethod } from 'api/types';
import { annotationApiUrls } from 'pages/backoffice/api/routes';
import { useAllowedBackofficeOrigin } from 'pages/backoffice/api/useAllowedBackofficeOrigin';
import { metadataApiErrorParser, MetadataApiErrorResponse } from 'pages/backoffice/api/utils';
import { CreateVenueApiParams } from 'pages/backoffice/api/venues/use-create-venue/types';
import { useInvalidateVenues } from 'pages/backoffice/api/venues/use-venues/useVenues';

interface RequestData {
  data: CreateVenueApiParams;
  onSuccess?: () => void;
}

export const useCreateVenue = () => {
  const { getIsAllowedBackofficeOrigin } = useAllowedBackofficeOrigin();
  const invalidateVenues = useInvalidateVenues();
  const mutationRequest = useMutationRequest<unknown, unknown, MetadataApiErrorResponse>({
    type: HTTPMethod.POST,
    successMessage: 'Venue created',
    errorMessage: (data) => metadataApiErrorParser(data, 'Venue creation error'),
    onSuccess: invalidateVenues,
  });

  const create = ({ data, onSuccess }: RequestData) => {
    if (!getIsAllowedBackofficeOrigin()) return;

    mutationRequest.mutate(
      {
        url: annotationApiUrls.VENUES,
        data,
      },
      {
        onSuccess,
      },
    );
  };

  return { ...mutationRequest, create };
};
