import { useQueryClient } from '@tanstack/react-query';

import { useInfinityQuery } from 'api/hooks/useInfinityQuery';
import { InfinityQueryResult } from 'api/hooks/useInfinityQuery/types';
import { getSeasonsUrl } from 'pages/backoffice/api/routes';
import { transformSeasons } from 'pages/backoffice/api/seasons/transformers';
import { SeasonsApiFilters, SeasonsApiResponse } from 'pages/backoffice/api/seasons/use-seasons/types';
import { useAllowedBackofficeOrigin } from 'pages/backoffice/api/useAllowedBackofficeOrigin';
import { Season } from 'pages/backoffice/types/seasons';
import { SortDirection } from 'shared/types/filters/types';

const QUERY_KEY = 'seasons';

export const useSeasons = (): InfinityQueryResult<Season, SeasonsApiFilters> => {
  const { isAllowedBackofficeOrigin } = useAllowedBackofficeOrigin();

  return useInfinityQuery<Season, SeasonsApiFilters, SeasonsApiResponse>({
    generateUrl: getSeasonsUrl,
    key: QUERY_KEY,
    options: {
      size: 10,
      sort: 'name',
      sortDirection: SortDirection.DESC,
    },
    transformer: transformSeasons,
    queryOptions: {
      enabled: isAllowedBackofficeOrigin,
    },
  });
};

export const useInvalidateSeasons = () => {
  const queryClient = useQueryClient();

  return () => queryClient.invalidateQueries({ queryKey: [QUERY_KEY] });
};
