import { IconUser } from 'kognia-ui/icons/IconUser';
import { useCallback, useMemo, useRef, useState } from 'react';

import { useEditUser } from 'api/backoffice/user/use-edit-user';
import { KogniaUser } from 'api/backoffice/user/use-kognia-users/types';
import { UserForm, UserFormRef } from 'pages/backoffice/features/users/user-form/types/userForm';
import { UserFormFeature } from 'pages/backoffice/features/users/user-form/UserForm.feature';
import { DialogNew } from 'shared/components/dialog-new/DialogNew';

interface Props {
  isOpen: boolean;
  onClose: () => void;
  user: KogniaUser;
}

export const EditUserModal = ({ isOpen, onClose, user }: Props) => {
  const userFormRef = useRef<UserFormRef>(null);
  const [isFormDirty, setIsFormDirty] = useState(false);
  const { editUser } = useEditUser();

  const defaultValues = useMemo(
    () => ({
      firstName: user.firstName,
      lastName: user.lastName,
      locale: user.locale,
      clientIds: user.clientIds,
      email: user.email,
    }),
    [user.clientIds, user.email, user.firstName, user.lastName, user.locale],
  );

  const handleDirtyChange = useCallback((isDirty: boolean) => {
    setIsFormDirty(isDirty);
  }, []);

  const handleEditUser = useCallback(
    (data: UserForm) => {
      editUser({
        data,
        userId: user.id,
        onSuccess: onClose,
      });
    },
    [editUser, onClose, user.id],
  );

  const handleSubmit = useCallback(() => {
    userFormRef.current?.requestSubmit();
  }, []);

  return (
    <DialogNew
      maxWidth='sm'
      fullWidth
      title='Edit user'
      icon={<IconUser size='small' />}
      onCancel={onClose}
      onClose={onClose}
      onSubmit={handleSubmit}
      buttonSubmitText={'Save'}
      open={isOpen}
      submitDisabled={!isFormDirty}
    >
      <UserFormFeature
        handleOnSubmit={handleEditUser}
        ref={userFormRef}
        defaultValues={defaultValues}
        isEdit={true}
        onDirtyChange={handleDirtyChange}
      />
    </DialogNew>
  );
};
