import { Box, Button, Stack, TextField, Typography } from '@mui/material';
import { IconPlus } from 'kognia-ui/icons/IconPlus';
import { fontSizes } from 'kognia-ui/theme/typography';
import { ChangeEvent, useState } from 'react';

import { useOrganizations } from 'pages/backoffice/api/organizations/useOrganizations';
import { OrganizationFormModalFeature } from 'pages/backoffice/features/OrganizationFormModal.feature';
import { OrganizationsList } from 'pages/backoffice/features/organizations-list/OrganizationsList';
import { BackofficeContentContainer } from 'pages/backoffice/ui/BackofficeContentContainer';
import { ListSkeleton } from 'pages/backoffice/ui/ListSkeleton';
import { ListHeader } from 'shared/components/list/ui/list-header/ListHeader';
import NotFound from 'shared/components/not-found/NotFound';
import Pagination from 'shared/components/pagination/Pagination';
import { useDebounce } from 'shared/hooks/use-debounce/useDebounce';

export const OrganizationsPage = () => {
  const { items, isFetching, isLoading, page, fetchNextPage, setFilters } = useOrganizations();
  const [isCreateModalOpen, setIsCreateModalOpen] = useState(false);

  const handleSearchChange = useDebounce((event: ChangeEvent<HTMLInputElement>) => {
    setFilters({ name: event.target.value });
  });

  return (
    <BackofficeContentContainer>
      <Stack direction='row' alignItems='center' justifyContent='space-between' marginBottom={2}>
        <Typography variant='h4'>Organizations</Typography>
        <Button
          startIcon={<IconPlus color={'inherit'} />}
          variant='contained'
          size={'large'}
          onClick={() => setIsCreateModalOpen(true)}
        >
          Add organization
        </Button>
      </Stack>
      <Stack mb={2} justifyContent={'flex-end'} direction={'row'}>
        <TextField size='small' placeholder='Search organizations' onChange={handleSearchChange} />
      </Stack>
      <ListHeader>
        <Box display='grid' gridTemplateColumns='1fr 1fr 32px' alignItems={'center'}>
          <Typography variant='body1' fontSize={fontSizes.default} fontWeight={'fontWeightMedium'}>
            Name
          </Typography>
          <div>&nbsp;</div>
        </Box>
      </ListHeader>
      {!isLoading && items.length === 0 ? (
        <Box marginTop={4}>
          <NotFound header={'No results'} />
        </Box>
      ) : null}
      {isLoading ? <ListSkeleton /> : <OrganizationsList organizations={items} />}
      {page ? (
        <Pagination
          total={page.totalElements}
          displayed={items.length}
          onShowMore={fetchNextPage}
          loading={isFetching}
          getStatsText={(displayed, total) => `${displayed} of ${total} organizations`}
        />
      ) : null}

      {isCreateModalOpen && (
        <OrganizationFormModalFeature open={isCreateModalOpen} onClose={() => setIsCreateModalOpen(false)} />
      )}
    </BackofficeContentContainer>
  );
};
