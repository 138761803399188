import { Box, IconButton, ListItem, Stack, Tooltip } from '@mui/material';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import { IconClose } from 'kognia-ui/icons/IconClose';
import React, { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useOrganization } from 'pages/backoffice/api/organizations/useOrganization';
import { useOrganizations } from 'pages/backoffice/api/organizations/useOrganizations';
import { SearchResults } from 'pages/backoffice/pages/season-competitions/components/search-results';
import { Organization } from 'pages/backoffice/types/organizations';
import { Autocomplete } from 'shared/components/autocomplete/Autocomplete';
import { AutocompleteDropdownButton } from 'shared/components/autocomplete-multi-select/ui/AutocompleteDropdownButton';
import {
  AutocompletePopper,
  AutocompletePopperContent,
} from 'shared/components/autocomplete-multi-select/ui/AutocompletePopperContent';
import { AutocompletePopperWrapper } from 'shared/components/autocomplete-multi-select/ui/AutocompletePopperWrapper';

interface Props {
  onChange: (id: string | undefined) => void;
  defaultSelectedId?: string | null;
}

const AUTOCOMPLETE_WIDTH = 400;

export const SelectOrganization = ({ onChange, defaultSelectedId }: Props) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [autocompleteValue, setAutocompleteValue] = useState<Organization | undefined>();
  const { items, isLoading, setFilters, fetchNextPage } = useOrganizations();
  const { data: defaultSelected } = useOrganization({ id: defaultSelectedId ?? undefined });
  const { t } = useTranslation();

  const isOpen = Boolean(anchorEl);

  const open = useCallback(
    (event: React.MouseEvent<HTMLElement>) => {
      setAutocompleteValue(defaultSelectedId ? defaultSelected : undefined);
      setAnchorEl(event.currentTarget);
    },
    [defaultSelected, defaultSelectedId],
  );

  const close = useCallback(() => {
    setFilters({});
    setAnchorEl(null);
  }, [setFilters]);

  const handleDropdownClick = useCallback(
    (event: React.MouseEvent<HTMLElement>) => {
      isOpen ? close() : open(event);
    },
    [isOpen, close, open],
  );

  const handleSelect = useCallback(() => {
    setAutocompleteValue((autocompleteValue) => {
      onChange(autocompleteValue ? autocompleteValue.id : undefined);
      return autocompleteValue;
    });
    close();
  }, [close, onChange]);

  const handleSetNameFilter = useCallback(
    (name: string) => {
      setFilters({ name: name });
    },
    [setFilters],
  );

  const renderOption = useCallback((props: React.ComponentProps<typeof ListItem>, option: Organization) => {
    return (
      <ListItem {...props} key={option.id}>
        <Box px={1} py={0.5}>
          {option.name}
        </Box>
      </ListItem>
    );
  }, []);

  const paperComponent = useCallback(
    (props: any) => (
      <SearchResults {...props} open={isOpen}>
        {props.children}
      </SearchResults>
    ),
    [isOpen],
  );

  const handleUpdateValue = useCallback(
    (item: Organization | null) => {
      if (item === null) return;
      setAutocompleteValue(item);
      onChange(item ? item.id : undefined);
      close();
    },
    [setAutocompleteValue, onChange, close],
  );

  const handleClearOrganizationValue = useCallback(() => {
    setAutocompleteValue(undefined);
    onChange('');
  }, [setAutocompleteValue, onChange]);

  const isOptionEqual = useCallback((option: Organization, value: Organization) => option.id === value.id, []);
  const getOptionName = useCallback((option: Organization) => option.name, []);
  const selectedItem = defaultSelectedId && defaultSelected ? defaultSelected : undefined;

  return (
    <Stack direction={'column'} spacing={1}>
      <Box>
        <AutocompleteDropdownButton
          isOpen={isOpen}
          onClick={handleDropdownClick}
          isSelected={Boolean(defaultSelectedId && defaultSelected)}
          label={defaultSelectedId && defaultSelected ? defaultSelected.name : 'Select organization'}
        />
        {selectedItem ? (
          <Tooltip title={'Clear organization'}>
            <IconButton sx={{ ml: 1 }} size={'small'} onClick={handleClearOrganizationValue}>
              <IconClose />
            </IconButton>
          </Tooltip>
        ) : null}
        {anchorEl && (
          <ClickAwayListener onClickAway={handleSelect}>
            <AutocompletePopper anchorEl={anchorEl} open={isOpen} placement='bottom-start'>
              <AutocompletePopperContent elevation={8}>
                <Autocomplete
                  autoFocus
                  PaperComponent={paperComponent}
                  PopperComponent={AutocompletePopperWrapper}
                  fetchNextPage={fetchNextPage}
                  getItemLabel={getOptionName}
                  inputWidth={AUTOCOMPLETE_WIDTH}
                  isLoading={isLoading}
                  isOptionEqualToValue={isOptionEqual}
                  listWidth={AUTOCOMPLETE_WIDTH}
                  multiple={false}
                  onChange={handleSetNameFilter}
                  open
                  options={items}
                  renderOption={renderOption}
                  placeholder={t('common:actions.search')}
                  resultsHeight={260}
                  updateValue={handleUpdateValue}
                  value={autocompleteValue}
                />
              </AutocompletePopperContent>
            </AutocompletePopper>
          </ClickAwayListener>
        )}
      </Box>
    </Stack>
  );
};
