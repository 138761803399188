import { useCallback, useState } from 'react';

import { useFetchRequest } from 'api/hooks/useFetchRequest';
import { exportTaggingEventUrl } from 'api/routes';
import { downloadFileFromBlob } from 'shared/utils/downloadFileFromBlob';

export const useDownloadRecordingManualTaggingEvents = () => {
  const [date, setDate] = useState<Date>(new Date());
  const [recordingId, setRecordingId] = useState('');

  useFetchRequest({
    queryRef: ['download-manual-tags', recordingId, date.toString()],
    url: exportTaggingEventUrl(recordingId, false),
    transformer: (res: Blob) => downloadFileFromBlob(res, `${recordingId}-manual-tags.xml`, 'text/xml'),
    options: {
      enabled: Boolean(recordingId),
    },
  });

  return {
    downloadManualTaggingEventsXML: useCallback((recordingId: string) => {
      setRecordingId(recordingId);
      setDate(new Date());
    }, []),
  };
};
