import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import AccountForm from 'pages/account/ui/AccountForm';
import Container from 'shared/components/container/Container';
import { useUser } from 'shared/contexts/app-state/hooks/useUser';
import { useBranding } from 'shared/hooks/use-branding/useBranding';

const AccountPageContainer = () => {
  const user = useUser();
  const { t } = useTranslation();
  const branding = useBranding();

  useEffect(() => {
    document.title = t('common:metas.title.account', { clientDisplayName: branding.displayName });
  }, [branding.displayName, t]);

  return (
    <Container>
      <AccountForm user={user} />
    </Container>
  );
};

export default AccountPageContainer;
