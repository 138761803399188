import { useTranslation } from 'react-i18next';

import PlaylistsGrid from 'pages/recording-playlists/components/playlists-grid';
import styles from 'pages/recording-playlists/components/recording-playlists-page/RercordingPlaylistsPage.module.scss';
import NotFound from 'shared/components/not-found/NotFound';
import { PageTitle } from 'shared/components/page-title/PageTitle';
import { Playlist } from 'shared/types';

interface Props {
  playlists: Playlist[];
  matchId: string;
}
const RecordingPlaylistsPage = ({ playlists, matchId }: Props) => {
  const { t } = useTranslation();

  return (
    <>
      <div className={styles.heading}>
        <PageTitle>{t('recording-playlists:title')}</PageTitle>
      </div>
      {playlists.length === 0 && (
        <NotFound
          header={t('recording-playlists:not-found.header')}
          description={t('recording-playlists:not-found.description')}
        />
      )}
      {playlists.length > 0 && <PlaylistsGrid recordingId={matchId} playlists={playlists} />}
    </>
  );
};

export default RecordingPlaylistsPage;
