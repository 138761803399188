import { IconUser } from 'kognia-ui/icons/IconUser';
import { useRef } from 'react';

import { KogniaUser } from 'api/backoffice/user/use-kognia-users/types';
import { AddUsersToEnvironmentsFormWithConfirm } from 'pages/backoffice/features/users/add-users-to-environments-form-with-confirm/AddUsersToEnvironmentsFormWithConfirm.feature';
import { DialogNew } from 'shared/components/dialog-new/DialogNew';

interface Props {
  open: boolean;
  selectedUsers: KogniaUser[];
  onClose: () => void;
  onSuccess: () => void;
}

export const AddUsersToEnvironmentsModal = ({ open, selectedUsers, onClose, onSuccess }: Props) => {
  const formRef = useRef<HTMLFormElement>(null);

  const handleSubmit = () => {
    formRef.current?.requestSubmit();
  };

  const handleSuccess = () => {
    onSuccess();
    onClose();
  };

  return (
    <DialogNew
      maxWidth='md'
      fullWidth
      title='Add users to environments'
      icon={<IconUser size='small' />}
      onCancel={onClose}
      onClose={onClose}
      onSubmit={handleSubmit}
      buttonSubmitText={'Send'}
      open={open}
    >
      <AddUsersToEnvironmentsFormWithConfirm ref={formRef} onSuccess={handleSuccess} selectedUsers={selectedUsers} />
    </DialogNew>
  );
};
