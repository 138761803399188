import { useQueryClient } from '@tanstack/react-query';

import { useInfinityQuery } from 'api/hooks/useInfinityQuery';
import { InfinityQueryResult } from 'api/hooks/useInfinityQuery/types';
import { transformPaginatedOrganizations } from 'pages/backoffice/api/organizations/transformer';
import { OrganizationsApiResponse, OrganizationsFilters } from 'pages/backoffice/api/organizations/types';
import { getOrganizationsUrl } from 'pages/backoffice/api/routes';
import { useAllowedBackofficeOrigin } from 'pages/backoffice/api/useAllowedBackofficeOrigin';
import { Organization } from 'pages/backoffice/types/organizations';
import { SortDirection } from 'shared/types/filters/types';

const QUERY_KEY = 'organizations';

export const useOrganizations = (): InfinityQueryResult<Organization, OrganizationsFilters> => {
  const { isAllowedBackofficeOrigin } = useAllowedBackofficeOrigin();

  return useInfinityQuery<Organization, OrganizationsFilters, OrganizationsApiResponse>({
    generateUrl: getOrganizationsUrl,
    key: QUERY_KEY,
    options: {
      size: 10,
      sort: 'name',
      sortDirection: SortDirection.DESC,
    },
    transformer: transformPaginatedOrganizations,
    queryOptions: {
      enabled: isAllowedBackofficeOrigin,
    },
  });
};

export const useInvalidateOrganizations = () => {
  const queryClient = useQueryClient();

  return () => queryClient.invalidateQueries({ queryKey: [QUERY_KEY] });
};
