import { useMutationRequest } from 'api/hooks/useMutationRequest';
import { HTTPMethod } from 'api/types';
import { annotationApiUrls } from 'pages/backoffice/api/routes';
import { useInvalidateSeasonClients } from 'pages/backoffice/api/season-clients/useSeasonClients';

interface RequestData {
  data: { client_ids: string[] };
  id: string;
  onSuccess?: () => void;
}

export const useUpdateSeasonClients = () => {
  const invalidateSeasonClients = useInvalidateSeasonClients();
  const mutationRequest = useMutationRequest({
    type: HTTPMethod.PATCH,
    successMessage: 'Association competition clients updated',
    errorMessage: 'Association competition clients update error',
    onSuccess: invalidateSeasonClients,
  });

  const update = ({ data, id, onSuccess }: RequestData) => {
    mutationRequest.mutate({ url: `${annotationApiUrls.SEASON_CLIENTS}/${id}`, data }, { onSuccess });
  };

  return { ...mutationRequest, update };
};
