import { z } from 'zod';

import { Competition } from 'shared/types/competition/competition';
import { Mapping } from 'shared/types/mapping';
import { PlayerMapping } from 'shared/types/player/player';
import { DefaultSeason } from 'shared/types/season/seasonSchema';
import { teamApiResponseSchema } from 'shared/types/team/teamBackendApiSchema';
import { teamMappingSchema } from 'shared/types/team/teamSchema';
import { CountryCode } from 'shared/utils/countries';

export enum TeamTypes {
  CLUB = 'club',
  NATIONAL_TEAM = 'national_team',
}

export enum TeamMappings {
  provider = 'provider',
  teamIdProvider = 'team_id_provider',
}

export enum Mappings {
  provider = 'provider',
  idProvider = 'idProvider',
}

export type TeamMapping = z.infer<typeof teamMappingSchema>;

export type TeamApiBackendResponse = z.infer<typeof teamApiResponseSchema>;

export type TeamApiResponse = z.infer<typeof teamApiResponseSchema>;

export interface TeamFixture {
  name: string;
  duration: number;
  homeTeamScore: number;
  awayTeamScore: number;
  date: Date;
  pitchLength: number;
  pitchWidth: number;
  id: string;
}

export interface Team {
  name: string;
  abbreviation: string;
  logoUrl: string | null;
  teamType: TeamTypes;
  countryCode?: CountryCode;
  id: string;
  fixtures: TeamFixture[];
  mappings?: Mapping[]; // TODO: remove this from APP part when new type for the app is created
  isFavorite: boolean; // TODO: remove this from backoffice part when new type for the app is created
}

export interface TeamPlayer {
  id: string;
  jerseyNumber: number;
  name: string;
  photoUrl: string | null;
  position: number;
  side: number;
  teamId: string;
  playerMappings?: PlayerMapping[];
}

export interface TeamWithPlayers extends Team {
  players: TeamPlayer[];
  teamDefaults?: {
    season: DefaultSeason;
    competition: Competition;
  };
}

export type TeamsFilters = {
  name?: string;
  seasonId?: string;
  sort?: string;
  sortDirection?: string;
  isFavorite?: boolean;
};
