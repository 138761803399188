import { Button, Link, Modal } from '@mui/material';
import { IconExport } from 'kognia-ui/icons/IconExport';
import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useRecordingQuery } from 'api/recording/use-recording-query/useRecordingQuery';
import { useDownloadEffectiveTimeVideos } from 'api/video-sources/use-download-effective-time-videos/useDownloadEffectiveTimeVideos';
import { useDownloadRequestName } from 'entities/download-manager/use-download-request-name/useDownloadRequestName';
import styles from 'pages/tactical-analysis/components/tactical-analysis/filters-bar/export-button/export-modal/ExportModal.module.scss';
import {
  ExportModalContent,
  ExportModalIconContainer,
} from 'pages/tactical-analysis/components/tactical-analysis/filters-bar/export-button/export-modal/ExportModal.styled';
import { ExportModals } from 'pages/tactical-analysis/components/tactical-analysis/filters-bar/export-button/ExportButton';
import RadioButton from 'shared/components/radio-button/RadioButton';
import Spinner from 'shared/components/spinner/Spinner';
import { ActionTypes } from 'shared/streams/actionTypes';
import { publishEvent } from 'shared/streams/eventEmitter';
import { MetricsNames } from 'shared/types/metrics';

type ExportModalProps = {
  tacticalAnalysisId: string | undefined;
  recordingId: string;
  onClose: () => void;
  modalTitle: string;
  options: ExportModalOption[];
  type: ExportModals;
};

type ExportModalOption = {
  type: ExportVideoTypes;
  displayValue: string;
  downloadUrl?: string;
  enabled: boolean;
};

export enum ExportVideoTypes {
  FULL_MATCH = 'full-match',
  EFFECTIVE_TIME = 'effective-time',
}

const ExportModal = ({ tacticalAnalysisId, recordingId, onClose, modalTitle, options, type }: ExportModalProps) => {
  const { t } = useTranslation();
  const { data: recordingData, isPending } = useRecordingQuery({ recordingId });
  const { createEffectiveTimeName } = useDownloadRequestName();
  const findFirstEnabled = (options: ExportModalOption[]): ExportModalOption | undefined => {
    return options.find((option) => option.enabled);
  };

  const [selectedOption, setSelectedOption] = useState<ExportModalOption | undefined>(findFirstEnabled(options));
  const downloadEffectiveTimeVideos = useDownloadEffectiveTimeVideos({ onSettled: onClose });

  const handleSelectOption = (id: string) => {
    const newOption = options.find((o) => o.type === id);
    setSelectedOption(newOption);
  };

  const handleDownloadEffectiveTimeVideo = useCallback(
    (tacticalAnalysisId: string) => {
      let videoName = '';
      if (recordingData) {
        const [awayTeam, homeTeam] = recordingData.name.split(' - ');

        videoName = createEffectiveTimeName({
          awayTeam,
          homeTeam,
          date: recordingData.date,
        });
      }
      downloadEffectiveTimeVideos(tacticalAnalysisId, videoName);
    },
    [recordingData, downloadEffectiveTimeVideos, createEffectiveTimeName],
  );

  const handleExportClick = useCallback(() => {
    if (type === ExportModals.VIDEO) {
      publishEvent({
        type: ActionTypes.EXPORT_TACTICAL_ANALYSIS_VIDEO,
        payload: { name: MetricsNames.TACTICAL_ANALYSIS_EXPORT_VIDEO },
      });

      const isDownloadEffectiveTimeVideo =
        selectedOption?.type === ExportVideoTypes.EFFECTIVE_TIME && tacticalAnalysisId !== undefined;

      isDownloadEffectiveTimeVideo ? handleDownloadEffectiveTimeVideo(tacticalAnalysisId) : onClose();
    }

    if (type === ExportModals.XML) {
      publishEvent({
        type: ActionTypes.EXPORT_TACTICAL_ANALYSIS_XML,
        payload: { name: MetricsNames.TACTICAL_ANALYSIS_EXPORT_XML },
      });

      onClose();
    }
  }, [handleDownloadEffectiveTimeVideo, onClose, type, selectedOption?.type, tacticalAnalysisId]);

  const exportUrl = selectedOption?.downloadUrl;
  const exportButtonProps =
    (type === ExportModals.XML || (type === ExportModals.VIDEO && ExportVideoTypes.FULL_MATCH)) &&
    exportUrl !== undefined
      ? {
          href: exportUrl,
          component: Link,
          download: true,
        }
      : {};

  return (
    <Modal
      open
      onClose={onClose}
      aria-labelledby='export-modal-title'
      aria-describedby='export-modal-description'
      className={styles.modalStyle}
    >
      <ExportModalContent>
        <div className={styles.modalHeader}>
          <ExportModalIconContainer>
            <IconExport size='small' />
          </ExportModalIconContainer>
          {modalTitle.toUpperCase()}
        </div>
        {isPending ? (
          <Spinner />
        ) : (
          <>
            <div className={styles.radioButtonsContainer}>
              {options.map((option) => (
                <RadioButton
                  key={option.type}
                  id={option.type}
                  checked={selectedOption?.type === option.type}
                  onSelect={handleSelectOption}
                  displayValue={option.displayValue}
                  disabled={!option.enabled}
                />
              ))}
            </div>
            <div className={styles.actionButtonsContainer}>
              <Button variant='outlined' color='secondary' onClick={onClose}>
                {t('common:actions.cancel')}
              </Button>
              <Button
                variant='contained'
                disabled={selectedOption === undefined}
                onClick={handleExportClick}
                {...exportButtonProps}
              >
                {t('timeline:actions.export.title')}
              </Button>
            </div>
          </>
        )}
      </ExportModalContent>
    </Modal>
  );
};

export default ExportModal;
