import { FormControl, FormControlLabel, FormLabel, Radio, RadioGroup } from '@mui/material';
import { useState } from 'react';
import { FieldErrors, UseFormRegister } from 'react-hook-form';

import { processFixtureFormSchema } from 'pages/backoffice/widgets/process-fixture/ProcessFixtureForm/types/processFixtureForm.types';
import {
  FilesChyronHegoSetOneFieldsNames,
  FilesChyronHegoSetTwoFieldsNames,
} from 'pages/backoffice/widgets/process-fixture/ProcessFixtureForm/types/processFixtureFormSubforms.types';
import { FormInputField } from 'shared/components/form/ui/FormInputField';

interface Props {
  register: UseFormRegister<processFixtureFormSchema>;
  errors: FieldErrors<processFixtureFormSchema>;
}

export function ChyronHegoForm({ errors, register }: Props) {
  const [set, setSet] = useState(1);

  const textFieldsListSetOne = [
    {
      id: FilesChyronHegoSetOneFieldsNames.metadataXml,
      label: 'Metadata (.xml)',
    },
    {
      id: FilesChyronHegoSetOneFieldsNames.srmlXml,
      label: 'SRML (.xml)',
    },
    {
      id: FilesChyronHegoSetOneFieldsNames.trackingDat,
      label: 'Tracking data (.dat)',
    },
  ];

  const textFieldsListSetTwo = [
    {
      id: FilesChyronHegoSetTwoFieldsNames.positionsXml,
      label: 'Positions (.xml)',
    },
    {
      id: FilesChyronHegoSetTwoFieldsNames.matchInfoXml,
      label: 'Match information (.xml)',
    },
  ];

  const textFieldsSetOne = textFieldsListSetOne.map((textField) => {
    return (
      <FormInputField
        key={textField.id}
        id={textField.id}
        label={textField.label}
        error={!!errors[textField.id]}
        fullWidth
        helperText={errors[textField.id] && <>{errors[textField.id]?.message}</>}
        {...register(textField.id)}
      />
    );
  });

  const textFieldsSetTwo = textFieldsListSetTwo.map((textField) => {
    return (
      <FormInputField
        key={textField.id}
        id={textField.id}
        label={textField.label}
        error={!!errors[textField.id]}
        fullWidth
        helperText={errors[textField.id] && <>{errors[textField.id]?.message}</>}
        {...register(textField.id)}
      />
    );
  });

  return (
    <div>
      <FormControl>
        <FormLabel id='chyronhego-set'>Files set</FormLabel>
        <RadioGroup
          aria-labelledby='chyronhego-set'
          name='chyronhego-set-radio-group'
          value={set}
          onChange={(event) => setSet(Number(event.target.value))}
        >
          <FormControlLabel value={1} control={<Radio />} label='Set one' />
          <FormControlLabel value={2} control={<Radio />} label='Set two' />
        </RadioGroup>
      </FormControl>

      {set === 1 && textFieldsSetOne}
      {set === 2 && textFieldsSetTwo}
    </div>
  );
}
