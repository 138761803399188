import { ClickAwayListener, Grow, ListItemText, Popper } from '@mui/material';
import React, { useCallback, useState } from 'react';

import { useRedirectTo } from 'shared/components/sidebar-layout/ui/sidebar-menu/hooks/use-redirect-to';
import { SidebarMenuItem } from 'shared/components/sidebar-layout/ui/sidebar-menu/sidebar-menu-item';
import {
  MenuItemLink,
  MenuItemParent,
  MenuItemType,
  MenuSubmenuItem,
} from 'shared/components/sidebar-layout/ui/sidebar-menu/SidebarMenu';
import {
  Menu,
  SubMenuButton,
  SubMenuItem,
  SubMenuPaper,
} from 'shared/components/sidebar-layout/ui/sidebar-menu/styled';
import { getMenuItemSelected, ListItemTextStyles } from 'shared/components/sidebar-layout/ui/sidebar-menu/utils';

interface Props {
  item: MenuItemParent;
  isSidebarOpen: boolean;
}

export const SidebarMenuItemWithOptions = ({ item, isSidebarOpen }: Props) => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [popperRef, setPopperRef] = useState<React.RefObject<HTMLDivElement> | null>(null);
  const redirectTo = useRedirectTo();

  const handleOnClick = useCallback((ref: React.RefObject<HTMLDivElement>) => {
    setPopperRef(ref);
    setIsMenuOpen(true);
  }, []);

  const handleOnClose = useCallback(() => {
    setIsMenuOpen(false);
  }, []);

  const subMenuLinks = item.items.filter((item) => item.type === MenuItemType.LINK) as MenuItemLink[];

  const isMenuSelected = getMenuItemSelected(
    location.pathname,
    subMenuLinks.map((item) => item.link),
  );

  const handleSubMenuClick = useCallback(
    (item: MenuSubmenuItem) => {
      if (item.type === MenuItemType.LINK) {
        redirectTo(item.link);
        handleOnClose();
      } else if (item.type === MenuItemType.CUSTOM_CLICK) {
        item.onClick();
        handleOnClose();
      }
    },
    [handleOnClose, redirectTo],
  );

  return (
    <>
      <SidebarMenuItem
        item={item}
        onClick={handleOnClick}
        isSidebarOpen={isSidebarOpen}
        isSelected={isMenuSelected || isMenuOpen}
      />
      <Popper
        open={isMenuOpen}
        anchorEl={popperRef?.current ?? null}
        role={undefined}
        placement='top-start'
        transition
        sx={{ zIndex: 1200 }}
      >
        {({ TransitionProps }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin: 'bottom',
            }}
          >
            <SubMenuPaper elevation={4}>
              <ClickAwayListener onClickAway={handleOnClose}>
                <Menu>
                  {item.items?.map((subMenuItem, index) => (
                    <SubMenuItem key={`${subMenuItem.label}_${index}`} bottomDivider={subMenuItem.bottomDivider}>
                      <SubMenuButton onClick={() => handleSubMenuClick(subMenuItem)}>
                        <ListItemText
                          primary={subMenuItem.label}
                          sx={{ margin: 0 }}
                          primaryTypographyProps={{ sx: ListItemTextStyles }}
                        />
                      </SubMenuButton>
                    </SubMenuItem>
                  ))}
                </Menu>
              </ClickAwayListener>
            </SubMenuPaper>
          </Grow>
        )}
      </Popper>
    </>
  );
};
