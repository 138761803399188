import map from 'lodash/map';
import React, { PropsWithChildren } from 'react';

import { FiltersBar } from 'pages/tactical-analysis/components/tactical-analysis/filters-bar/FiltersBar';
import AddToPlaylistMenuTimeline from 'pages/tactical-analysis/components/timeline/add-to-playlist-menu-timeline';
import { TimelineTable } from 'pages/tactical-analysis/components/timeline/timeline-table/TimelineTable';
import styles from 'pages/tactical-analysis/components/timeline/timeline-table/TimelineTable.module.scss';
import { useTimelineTableData } from 'pages/tactical-analysis/hooks/use-timeline-table-data';
import { Match } from 'shared/types';
import { GroupedTags } from 'shared/types/tagging-events/types';

type Props = {
  match?: Match;
  onTableReady?: () => void;
  recordingId: string;
  tags: GroupedTags;
};

const TimelineContainer = ({ children }: PropsWithChildren) => (
  <div className={styles.timelineContainer}>{children}</div>
);

const TimelineContent = ({ children }: PropsWithChildren) => <div className={styles.timelineContent}>{children}</div>;

export const Timeline = React.memo(({ match, recordingId, tags }: Props) => {
  const timelineTableData = useTimelineTableData(recordingId);

  if (timelineTableData.rowGroups.length === 0 || !match) return null;

  return (
    <TimelineContainer>
      <TimelineContent>
        <FiltersBar
          recordingId={recordingId}
          teams={match.teams}
          videoSources={match.videoSources}
          tags={map(tags, (tag) => tag).flat()}
        />
        <TimelineTable recordingId={recordingId} tags={tags} />
      </TimelineContent>
      <AddToPlaylistMenuTimeline recordingId={recordingId} />
    </TimelineContainer>
  );
});

Timeline.displayName = 'Timeline';
