import { useMutationRequest } from 'api/hooks/useMutationRequest';
import { HTTPMethod } from 'api/types';
import { annotationApiUrls } from 'pages/backoffice/api/routes';
import { CreateTaskApiParams } from 'pages/backoffice/api/types';
import { useAllowedBackofficeOrigin } from 'pages/backoffice/api/useAllowedBackofficeOrigin';
import { metadataApiErrorParser, MetadataApiErrorResponse } from 'pages/backoffice/api/utils';
import { getQueryParams } from 'pages/backoffice/utils/getQueryParams';

interface CreateTaskParams {
  data: CreateTaskApiParams;
}

export const useCreateTask = () => {
  const { getIsAllowedBackofficeOrigin } = useAllowedBackofficeOrigin();
  const mutationRequest = useMutationRequest<unknown, unknown, MetadataApiErrorResponse>({
    type: HTTPMethod.POST,
    successMessage: 'Create task success',
    errorMessage: (data) => metadataApiErrorParser(data, 'Create task error'),
  });

  const createTask = ({ data }: CreateTaskParams) => {
    const queryParams = getQueryParams<CreateTaskApiParams>(data);
    if (!queryParams) return;
    if (!getIsAllowedBackofficeOrigin()) return;

    mutationRequest.mutate({
      url: `${annotationApiUrls.CREATE_TASK}?${queryParams}`,
    });
  };

  return { ...mutationRequest, createTask };
};
