import { useMutationRequest } from 'api/hooks/useMutationRequest';
import { HTTPMethod } from 'api/types';
import { annotationApiUrls } from 'pages/backoffice/api/routes';
import { useInvalidateSeasons } from 'pages/backoffice/api/seasons/use-seasons/useSeasons';
import { UpdateSeasonApiParams } from 'pages/backoffice/api/seasons/use-update-season/types';
import { useAllowedBackofficeOrigin } from 'pages/backoffice/api/useAllowedBackofficeOrigin';
import { metadataApiErrorParser, MetadataApiErrorResponse } from 'pages/backoffice/api/utils';

interface RequestData {
  data: UpdateSeasonApiParams;
  id: string;
  onSuccess?: () => void;
}

export const useUpdateSeason = () => {
  const { getIsAllowedBackofficeOrigin } = useAllowedBackofficeOrigin();
  const invalidateSeasons = useInvalidateSeasons();
  const mutationRequest = useMutationRequest<unknown, unknown, MetadataApiErrorResponse>({
    type: HTTPMethod.PATCH,
    successMessage: 'Season updated',
    errorMessage: (data) => metadataApiErrorParser(data, 'Season update error'),
    onSuccess: invalidateSeasons,
  });

  const update = ({ data, id, onSuccess }: RequestData) => {
    if (!getIsAllowedBackofficeOrigin()) return;

    mutationRequest.mutate(
      {
        url: `${annotationApiUrls.SEASONS}/${id}`,
        data,
      },
      {
        onSuccess,
      },
    );
  };

  return { ...mutationRequest, update };
};
