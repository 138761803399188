import React from 'react';

import { BackofficePipelineType } from 'pages/backoffice/api/types';
import { FormFieldProps } from 'pages/backoffice/pages/annotation/ui/form-fields/types';
import { AnnotationFormLabels } from 'pages/backoffice/pages/annotation/utils/form-fields-validation';
import { AnnotationFormFieldsNames } from 'pages/backoffice/pages/annotation/utils/form-fields-validation/types';
import { FormSelectField } from 'shared/components/form/ui/FormSelectField';
import { getTypeOptions } from 'shared/components/form/utils/getTypeOptions';

export const PipelineTypeField = ({ errors = {}, register }: FormFieldProps) => {
  return (
    <FormSelectField
      id={AnnotationFormFieldsNames.PIPELINE_TYPE}
      label={AnnotationFormLabels[AnnotationFormFieldsNames.PIPELINE_TYPE]}
      options={getTypeOptions({ options: Object.values(BackofficePipelineType).map((option) => ({ value: option })) })}
      error={!!errors[AnnotationFormFieldsNames.PIPELINE_TYPE]}
      helperText={
        errors[AnnotationFormFieldsNames.PIPELINE_TYPE] && (
          <>{errors[AnnotationFormFieldsNames.PIPELINE_TYPE]?.message}</>
        )
      }
      defaultValue={''}
      {...register(AnnotationFormFieldsNames.PIPELINE_TYPE)}
    />
  );
};
