import { FormControl, FormControlLabel, Radio, RadioGroup, Typography } from '@mui/material';
import { fontSizes } from 'kognia-ui';
import { ChangeEvent, useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { DialogNew } from 'shared/components/dialog-new/DialogNew';

interface Params {
  isOpen: boolean;
  onSubmit: (merge: boolean) => void;
  onClose: () => void;
  onOpen: () => void;
}

export const OverlappingClipsModal = ({ isOpen, onSubmit, onClose }: Params) => {
  const { t } = useTranslation();
  const [mergeClips, setMergeClips] = useState<'merge' | 'individual'>('merge');

  const handleChange = useCallback((event: ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    setMergeClips(value as 'merge' | 'individual');
  }, []);

  const handleSubmit = useCallback(() => {
    onSubmit(mergeClips === 'merge');
    onClose();
  }, [mergeClips, onClose, onSubmit]);

  return (
    <DialogNew
      open={isOpen}
      onClose={onClose}
      title={t('timeline:overlapping-clips-modal.title')}
      onSubmit={handleSubmit}
      onCancel={onClose}
      maxWidth='xs'
    >
      <Typography variant='body1' fontSize={fontSizes.default}>
        {t('timeline:overlapping-clips-modal.text')}
      </Typography>
      <FormControl sx={{ mt: 2 }}>
        <RadioGroup
          aria-labelledby='demo-controlled-radio-buttons-group'
          name='controlled-radio-buttons-group'
          value={mergeClips}
          onChange={handleChange}
        >
          <FormControlLabel
            value={'merge'}
            control={<Radio />}
            label={t('timeline:overlapping-clips-modal.options.merge')}
            componentsProps={{ typography: { fontSize: fontSizes.default } }}
          />
          <FormControlLabel
            value={'individual'}
            control={<Radio />}
            label={t('timeline:overlapping-clips-modal.options.separate')}
            componentsProps={{ typography: { fontSize: fontSizes.default } }}
          />
        </RadioGroup>
      </FormControl>
    </DialogNew>
  );
};
