import { CSSProperties, useCallback } from 'react';
import { DraggableProvided, DraggableRubric, DraggableStateSnapshot, DroppableProvided } from 'react-beautiful-dnd';

import { invalidatePlaylistQuery } from 'api/playlist/usePlaylist';
import { useUpdatePlaylistItemOrder } from 'api/playlist/useUpdatePlaylistItemOrder';
import { PLAYLIST_ITEM_GAP } from 'entities/playlist/config/Playlist.config';
import { useBulkSelectedItems, useSetBulkSelectedItems } from 'entities/playlist/hooks/useBulkSelectedItems';
import { useHandleSelect } from 'entities/playlist/hooks/useHandleSelect';
import { useIsBulkModeActive } from 'entities/playlist/hooks/useIsBulkModeActive';
import { PlaylistItemsList, RowProps } from 'features/playlist/PlaylistItemsList';
import { UserPlaylistItem } from 'features/playlist/user-playlist-items-list/ui/user-playlist-item/UserPlaylistItem';
import { SortableItem } from 'shared/components/sortable-item-list/sortable-item/SortableItem';
import SortableItemList from 'shared/components/sortable-item-list/SortableItemList';
import { useVideoPlayerActions } from 'shared/components/video-player';
import { PlaylistItemType } from 'shared/components/video-player/types';
import { Playlist } from 'shared/types/index';

type Props = {
  playlist: Playlist;
  setTrimmingPlaylistItem: (videoId: string) => void;
  trimmingPlaylistItemId?: string;
  playlistItems: PlaylistItemType[];
};

export const SortablePlaylistItemsList = ({
  playlist,
  playlistItems,
  setTrimmingPlaylistItem,
  trimmingPlaylistItemId,
}: Props) => {
  const actions = useVideoPlayerActions();
  const handleSelect = useHandleSelect(playlist.id);
  const enabledBulkMode = useIsBulkModeActive(playlist.id);
  const { updatePlaylistItemOrder } = useUpdatePlaylistItemOrder(playlist.id);
  const onOrderChangeStart = useCallback(() => actions.handleStandBy(), [actions]);
  const onOrderChangeEnd = useCallback(() => actions.resumeStandBy(), [actions]);
  const selectedItems = useBulkSelectedItems(playlist.id);
  const setSelectedItems = useSetBulkSelectedItems(playlist.id);
  const onPlaylistOrderChange = useCallback(
    (currentVideoIndex: number, newVideoIndex: number) => {
      actions.reorder(currentVideoIndex, newVideoIndex);

      actions.resumeStandBy();
    },
    [actions],
  );

  const handleOrderChange = useCallback(
    (playlistItemId: string, currentIndex: number, newIndex: number) => {
      updatePlaylistItemOrder(playlistItemId, newIndex);
      if (onPlaylistOrderChange) onPlaylistOrderChange(currentIndex, newIndex);
    },
    [onPlaylistOrderChange, updatePlaylistItemOrder],
  );

  const handlePlaylistItemDuplicate = useCallback(() => {
    invalidatePlaylistQuery();
  }, []);

  const handlePlaylistItemDelete = useCallback(
    (playlistId: string) => {
      setSelectedItems(selectedItems.filter((item) => item !== playlistId));
    },
    [selectedItems, setSelectedItems],
  );

  const PlaylistItemElement = useCallback(
    (
      draggableProvided: DraggableProvided,
      currentPlaylistItem: PlaylistItemType,
      index: number,
      style?: CSSProperties,
    ) => (
      <div
        ref={draggableProvided.innerRef}
        {...draggableProvided.draggableProps}
        {...draggableProvided.dragHandleProps}
        style={{
          ...style,
          ...draggableProvided.draggableProps.style,
          padding: `0 ${PLAYLIST_ITEM_GAP}px`,
          height: 'auto',
        }}
      >
        <UserPlaylistItem
          isEditing={trimmingPlaylistItemId !== '' && trimmingPlaylistItemId === currentPlaylistItem.id}
          isDisabled={trimmingPlaylistItemId !== '' && trimmingPlaylistItemId !== currentPlaylistItem.id}
          key={`playlist-video-${currentPlaylistItem.id}`}
          playlist={playlist}
          playlistItem={currentPlaylistItem}
          setTrimmingPlaylistItem={setTrimmingPlaylistItem}
          videoIndex={index}
          onSelect={handleSelect}
          enabledBulkMode={enabledBulkMode}
          isChecked={selectedItems.includes(currentPlaylistItem.id)}
          onPlaylistItemDelete={handlePlaylistItemDelete}
          onPlaylistItemDuplicate={handlePlaylistItemDuplicate}
        />
      </div>
    ),
    [
      enabledBulkMode,
      handlePlaylistItemDelete,
      handlePlaylistItemDuplicate,
      handleSelect,
      playlist,
      selectedItems,
      setTrimmingPlaylistItem,
      trimmingPlaylistItemId,
    ],
  );

  const Row = useCallback(
    ({ data, index, style }: RowProps) => {
      const currentPlaylistItem = data[index];
      return (
        <SortableItem
          isDragDisabled={enabledBulkMode}
          index={index}
          id={currentPlaylistItem.id}
          renderChildren={(draggableProvided) =>
            PlaylistItemElement(draggableProvided, currentPlaylistItem, index, style)
          }
          key={currentPlaylistItem.id}
        />
      );
    },
    [PlaylistItemElement, enabledBulkMode],
  );

  const VirtualizedList = useCallback(
    (droppableProvided: DroppableProvided) => (
      <PlaylistItemsList ref={droppableProvided.innerRef} playlistItems={playlistItems} Row={Row} />
    ),
    [Row, playlistItems],
  );

  const handleRenderClone = useCallback(
    (draggableProvided: DraggableProvided, snapshot: DraggableStateSnapshot, rubric: DraggableRubric) =>
      PlaylistItemElement(draggableProvided, playlistItems[rubric.source.index], rubric.source.index),
    [PlaylistItemElement, playlistItems],
  );

  return (
    <SortableItemList
      isDropDisabled={enabledBulkMode}
      onDragStart={onOrderChangeStart}
      onDragEnd={onOrderChangeEnd}
      onOrderChange={handleOrderChange}
      id={'playlist-items-list'}
      items={playlistItems}
      mode='virtual'
      renderClone={handleRenderClone}
      renderChildren={VirtualizedList}
      direction={'horizontal'}
    />
  );
};
