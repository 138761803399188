import { Box, List, ListItem, Typography } from '@mui/material';
import { IconUser } from 'kognia-ui/icons/IconUser';

import { Competition } from 'pages/backoffice/types/competitions';
import { DialogNew } from 'shared/components/dialog-new/DialogNew';

interface Props {
  competition: Competition;
  onClose: () => void;
}

export const CompetitionDetailsModal = ({ competition, onClose }: Props) => {
  return (
    <DialogNew
      maxWidth='sm'
      fullWidth
      title={'Competition Details'}
      icon={<IconUser size='small' />}
      onCancel={onClose}
      onClose={onClose}
      open={true}
    >
      <Box>
        <List>
          <ListItem>
            <Typography variant='body1'>
              Name: <strong>{competition.name}</strong>
            </Typography>
          </ListItem>
          <ListItem>
            <Typography variant='body1'>
              Country: <strong>{competition.country}</strong>
            </Typography>
          </ListItem>
          <ListItem>
            <Typography variant='body1'>Tier: {competition.tier}</Typography>
          </ListItem>
          <ListItem>
            <Typography variant='body1'>Format: {competition.format}</Typography>
          </ListItem>
          <ListItem>
            <Typography variant='body1'>Gender: {competition.gender}</Typography>
          </ListItem>
          <ListItem>
            <Typography variant='body1'>Region: {competition.region}</Typography>
          </ListItem>
          <ListItem>
            <Typography variant='body1'>Team Type: {competition.teamType}</Typography>
          </ListItem>
          <ListItem>
            <Typography variant='body1'>Organization: {competition.organization?.name}</Typography>
          </ListItem>
        </List>
      </Box>
    </DialogNew>
  );
};
