import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import PlaylistDetailSkeleton from 'pages/playlist-detail/components/playlists-detail-skeleton/PlaylistDetailSkeleton';
import { usePlaylistPage } from 'pages/playlist-detail/hooks/usePlaylistPage';
import Container from 'shared/components/container/Container';
import { SwitchEnvironment } from 'shared/components/switch-environment/SwitchEnvironment';
import { useBranding } from 'shared/hooks/use-branding/useBranding';
import { invariant } from 'shared/utils/invariant';
import { UserPlaylistWidget } from 'widgets/playlist/user-playlist/UserPlaylist.widget';

export const PlaylistDetailContainer = () => {
  const branding = useBranding();
  const { playlistId } = useParams();
  invariant(playlistId);
  const { t } = useTranslation();
  const { isLoading, data, isInvalidClient } = usePlaylistPage({ playlistId });

  useEffect(() => {
    document.title = t('common:metas.title.playlist', { clientDisplayName: branding.displayName });
  }, [branding.displayName, t]);

  if (isInvalidClient && data?.clientId) {
    return <SwitchEnvironment resourceClientsIds={[data.clientId]} />;
  }

  return (
    <Container fullScreen>
      {isLoading && <PlaylistDetailSkeleton />}

      {!isLoading && data && <UserPlaylistWidget playlist={data} />}
    </Container>
  );
};
