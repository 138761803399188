import { Stack } from '@mui/material';
import { IconAnalysis } from 'kognia-ui/icons/IconAnalysis';
import { IconBall } from 'kognia-ui/icons/IconBall';
import { IconTag } from 'kognia-ui/icons/IconTag';
import React, { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { generatePath, useNavigate } from 'react-router-dom';

import { routes } from 'kognia/router/routes';
import RecordingCard from 'pages/recordings-list/components/recording-card';
import CompetitionInfo from 'pages/recordings-list/components/vod-recording-card/competition-info';
import DateInfo from 'pages/recordings-list/components/vod-recording-card/date-info';
import MatchDayInfo from 'pages/recordings-list/components/vod-recording-card/match-day-info';
import stylesCard from 'pages/recordings-list/components/vod-recording-card/RecordingCard.module.scss';
import ScoresInfo from 'pages/recordings-list/components/vod-recording-card/scores-info';
import StateInfo from 'pages/recordings-list/components/vod-recording-card/state-info';
import { ListInfo } from 'shared/components/list/ui/list-info/ListInfo';
import { ListItemTitle } from 'shared/components/list/ui/list-item-title/ListItemTitle';
import useIsTaggingTool from 'shared/hooks/is-tagging-tool';
import { Recording, VideoSourceStates } from 'shared/types/recording/types';

interface Props {
  hideActions?: boolean;
  onDeleteSuccess?: (recordingId: string) => void;
  recording: Recording;
}

const VODRecordingCard = React.memo(({ recording, hideActions = false }: Props) => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const taggingToolPath = useMemo(
    () => generatePath(routes.TAGGING_TOOL_TAG, { recordingId: recording.id }),
    [recording.id],
  );

  const { id, name, teams, date, competitionName, matchDay } = recording;
  const isTaggingTool = useIsTaggingTool();
  const cardPath = useMemo(
    () =>
      isTaggingTool
        ? generatePath(routes.TAGGING_TOOL_TAG, { recordingId: id })
        : generatePath(routes.PERFORMANCE_REPORT_TIMELINE, { id }),
    [id, isTaggingTool],
  );

  const analysisPath = useMemo(() => generatePath(routes.PERFORMANCE_REPORT_TIMELINE, { id }), [id]);

  const hasVideoSource =
    recording.videoSourcesStates &&
    recording.videoSourcesStates.some((videoSourceState) => videoSourceState.state === VideoSourceStates.FINISHED);

  const handleLinkToTagging = useCallback(
    (event: React.MouseEvent<HTMLLIElement | HTMLDivElement, MouseEvent>) => {
      event && event.stopPropagation();
      navigate(taggingToolPath);
    },
    [navigate, taggingToolPath],
  );

  const handleLinkToAnalysis = useCallback(
    (event?: React.MouseEvent<HTMLLIElement | HTMLDivElement, MouseEvent>) => {
      if (!hasVideoSource) return false;
      event && event.stopPropagation();
      navigate(analysisPath);
    },
    [hasVideoSource, navigate, analysisPath],
  );

  const handleLinkToCardPath = useCallback(
    (event?: React.MouseEvent<HTMLLIElement | HTMLDivElement, MouseEvent>) => {
      if (!hasVideoSource) return false;
      event && event.stopPropagation();
      navigate(cardPath);
    },
    [hasVideoSource, navigate, cardPath],
  );

  const extraMenuOptions = useMemo(() => {
    if (!hasVideoSource) return [];

    return [
      {
        displayText: '',
      },
      {
        displayText: t('recording:actions.add-tags'),
        icon: <IconTag size='small' color='secondary' />,
        onClick: handleLinkToTagging,
      },
      {
        displayText: t('recording:actions.view-analysis'),
        icon: <IconAnalysis size='small' color='secondary' />,
        onClick: handleLinkToAnalysis,
      },
    ];
  }, [t, hasVideoSource, handleLinkToAnalysis, handleLinkToTagging]);

  return (
    <RecordingCard
      hasVideoSource={hasVideoSource}
      hideActions={hideActions}
      menuOptions={extraMenuOptions}
      onClick={handleLinkToCardPath}
      recording={recording}
      title={
        <ListInfo>
          {!!teams.length ? (
            <div className={stylesCard.recordingScore}>
              <ScoresInfo teams={teams} />
            </div>
          ) : (
            <div className={stylesCard.recordingIcon}>
              <IconBall />
            </div>
          )}
          <ListItemTitle>
            <h2>{name}</h2>
            <CompetitionInfo competitionName={competitionName} />
          </ListItemTitle>
        </ListInfo>
      }
    >
      <div />
      <Stack justifyContent={'center'}>
        <DateInfo date={date} />
        <MatchDayInfo matchDay={matchDay} />
      </Stack>
      <div className={stylesCard.recordingState}>
        <StateInfo hasVideoSource={hasVideoSource} recording={recording} />
      </div>
    </RecordingCard>
  );
});

VODRecordingCard.displayName = 'VODRecordingCard';

export default VODRecordingCard;
