import { Tab, Tabs } from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import withStyles from '@mui/styles/withStyles';

import styles from 'shared/components/filter-player-tab/FilterPlayerTabs.module.scss';

interface FilterPlayerTabsProps {
  value: string;
  onChange: (event: React.ChangeEvent<any>, newValue: string) => void;
  children: React.ReactNode;
}

export const FilterPlayerTabs = withStyles(
  createStyles({
    root: {
      width: '100%',
    },
  }),
)((props: FilterPlayerTabsProps) => (
  <Tabs {...props} className={styles.tabs} TabIndicatorProps={{ children: <div /> }} />
));

interface FilterPlayerTabProps {
  active?: boolean;
  label: string;
  value: string;
}

export const FilterPlayerTab = withStyles(() =>
  createStyles({
    root: {
      textTransform: 'none',
      maxWidth: '100%',
      '&:focus': {
        opacity: 1,
      },
    },
  }),
)((props: FilterPlayerTabProps) => {
  const { active, ...tabProps } = props;
  return (
    <>
      <Tab className={styles.tab} disableRipple {...tabProps} />
    </>
  );
});
