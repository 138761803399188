import React, { useCallback } from 'react';

import {
  useAddClipsAction,
  useAddClipShiftSelection,
  useAddClipsShiftSelection,
  useAddRowClips,
  useCleanSelection,
  useClipsIdsSelection,
  useGetClip,
  useRemoveClipFromSelection,
  useReplaceMultipleClipsAction,
  useRowClips,
  useToggleClipSelection,
  useToggleCtrlClipSelection,
  useToggleClipsSelection,
  useToggleCtrlClipsSelection,
} from 'pages/tactical-analysis/api/use-tactical-analysis-data/generate-timeline-rows/atoms';
import { getScenarioClips } from 'pages/tactical-analysis/components/timeline/timeline-table/components/row-headers-group/util';
import { UseTimelineEventsHandlers } from 'pages/tactical-analysis/components/timeline/timeline-table/hooks/use-timeline-events-handlers/types';
import { useJumpToTimeInMatch } from 'pages/tactical-analysis/components/timeline/timeline-table/hooks/useJumpToTimeInMatch';
import { useSelectionPlaying, useSetSelectedPlayingRow } from 'pages/tactical-analysis/hooks/use-selection-playing';
import { useTacticalAnalysisMode } from 'pages/tactical-analysis/hooks/use-tactical-analysis-mode';
import { useTeamUtils } from 'pages/tactical-analysis/hooks/use-timeline-team-id-focus';
import { TacticalAnalysisPlayingMode } from 'pages/tactical-analysis/types/tactical-analysis-playing-mode';
import { useVideoPlayerActions } from 'shared/components/video-player';
import { ActionTypes } from 'shared/streams/actionTypes';
import { publishEvent } from 'shared/streams/eventEmitter';
import { MetricsNames } from 'shared/types/metrics';

export const useTimelineEventsHandlers = (recordingId: string): UseTimelineEventsHandlers => {
  const actions = useVideoPlayerActions();
  const jumpToTimeInMatch = useJumpToTimeInMatch(recordingId);
  const toggleClipSelection = useToggleClipSelection();
  const toggleCtrlClipsSelection = useToggleCtrlClipsSelection();
  const toggleCtrlClipSelection = useToggleCtrlClipSelection();
  const toggleClipsSelection = useToggleClipsSelection();
  const addClipShift = useAddClipShiftSelection();
  const addClipsShift = useAddClipsShiftSelection();
  const removeClipFromSelection = useRemoveClipFromSelection();
  const addRowClips = useAddRowClips();
  const { playSelection } = useSelectionPlaying(recordingId);
  const clipIdsSelection = useClipsIdsSelection();
  const addMultipleClips = useAddClipsAction();
  const replaceMultipleClips = useReplaceMultipleClipsAction();
  const getRowClips = useRowClips();
  const tacticalAnalysisMode = useTacticalAnalysisMode(recordingId);
  const { playAll } = useSelectionPlaying(recordingId);
  const cleanSelection = useCleanSelection();
  const setSelectedPlayingRow = useSetSelectedPlayingRow(recordingId);
  const getClip = useGetClip();

  const { isHomeTeam } = useTeamUtils(recordingId);

  const handlePlayClip = useCallback(
    (startTime: number) => {
      return jumpToTimeInMatch(startTime);
    },
    [jumpToTimeInMatch],
  );

  const handlePlaySelectedClips = useCallback(() => {
    if (clipIdsSelection.length <= 1 && tacticalAnalysisMode !== 'default') return;
    publishEvent({
      type: ActionTypes.PLAY_TACTICAL_ANALYSIS_SELECTED_CLIPS,
      payload: { name: MetricsNames.TACTICAL_ANALYSIS_PLAY_SELECTED_CLIPS },
    });

    return playSelection();
  }, [clipIdsSelection, playSelection, tacticalAnalysisMode]);

  const handlePlayRowCommon = useCallback(
    () =>
      publishEvent({
        type: ActionTypes.PLAY_TACTICAL_ANALYSIS_ROW,
        payload: { name: MetricsNames.TACTICAL_ANALYSIS_PLAY_ROW },
      }),
    [],
  );

  const handlePlayRow = useCallback(
    (rowId: string) => {
      handlePlayRowCommon();
      return playSelection({ rowId });
    },
    [handlePlayRowCommon, playSelection],
  );

  const handlePlayHomeTeamRow = useCallback(
    (rowId: string) => {
      handlePlayRowCommon();
      return playSelection({ rowId, team: 'home' });
    },
    [handlePlayRowCommon, playSelection],
  );

  const handlePlayOpponentTeamRow = useCallback(
    (rowId: string) => {
      handlePlayRowCommon();
      return playSelection({ rowId, team: 'opponent' });
    },
    [handlePlayRowCommon, playSelection],
  );

  const handleOpponentTeamClick = useCallback(
    (event: React.MouseEvent<HTMLElement, MouseEvent>) => {
      const rowElement: HTMLDivElement | null =
        event.target instanceof HTMLDivElement
          ? event.target.dataset.rowId
            ? event.target
            : event.target.closest('[data-row-id]')
          : null;

      if (event.target instanceof HTMLElement && rowElement?.dataset.rowId) {
        const opponentTeamClips = getScenarioClips(
          getRowClips(rowElement.dataset.rowId),
          (teamId) => !isHomeTeam(teamId),
        );

        if (event.ctrlKey || event.metaKey) {
          return addMultipleClips(opponentTeamClips.map((clip) => clip.id));
        }
        return replaceMultipleClips(opponentTeamClips.map((clip) => clip.id));
      }
    },
    [addMultipleClips, getRowClips, isHomeTeam, replaceMultipleClips],
  );

  const handleHomeTeamClick = useCallback(
    (event: React.MouseEvent<HTMLElement, MouseEvent>) => {
      const rowElement: HTMLDivElement | null =
        event.target instanceof HTMLDivElement
          ? event.target.dataset.rowId
            ? event.target
            : event.target.closest('[data-row-id]')
          : null;

      if (event.target instanceof HTMLElement && rowElement?.dataset.rowId) {
        const homeTeamClips = getScenarioClips(getRowClips(rowElement.dataset.rowId), isHomeTeam);

        if (event.ctrlKey || event.metaKey) {
          return addMultipleClips(homeTeamClips.map((clip) => clip.id));
        }
        replaceMultipleClips(homeTeamClips.map((clip) => clip.id));
      }
    },
    [addMultipleClips, getRowClips, isHomeTeam, replaceMultipleClips],
  );

  const handleSelectClip = useCallback(
    ({
      clipId,
      ctrlKey,
      shiftKey,
      metaKey,
    }: {
      clipId: string;
      ctrlKey?: boolean;
      shiftKey?: boolean;
      metaKey?: boolean;
    }) => {
      const clipWithSelection = getClip(clipId);

      if (clipWithSelection?.type === 'parent-clip' && clipWithSelection?.clips?.length) {
        const clipsIds = clipWithSelection.clips.map((clip) => clip.id);
        if (shiftKey) return addClipsShift(clipsIds);
        if (ctrlKey || metaKey) return toggleCtrlClipsSelection(clipsIds);
        return toggleClipsSelection(clipsIds);
      }

      if (shiftKey) {
        return addClipShift(clipId, tacticalAnalysisMode === TacticalAnalysisPlayingMode.selection);
      }

      if (ctrlKey || metaKey) {
        return toggleCtrlClipSelection(clipId);
      }

      return toggleClipSelection(clipId);
    },
    [
      getClip,
      toggleClipSelection,
      addClipsShift,
      toggleCtrlClipsSelection,
      toggleClipsSelection,
      addClipShift,
      tacticalAnalysisMode,
      toggleCtrlClipSelection,
    ],
  );

  const handleDeselectClip = useCallback(
    (clipId: string) => {
      removeClipFromSelection(clipId);
    },
    [removeClipFromSelection],
  );

  const handleSelect = useCallback(
    (event: React.MouseEvent<HTMLElement, MouseEvent>) => {
      const element = event.target instanceof HTMLDivElement ? (event.target as HTMLElement) : undefined;
      if (!element?.dataset.clipId) return;

      handleSelectClip({
        clipId: element.dataset.clipId,
        ctrlKey: event.ctrlKey,
        shiftKey: event.shiftKey,
        metaKey: event.metaKey,
      });
    },
    [handleSelectClip],
  );

  const handleDoubleClick = useCallback(
    (event: React.MouseEvent<HTMLElement, MouseEvent>) => {
      const rowElement: HTMLDivElement | null =
        event.target instanceof HTMLDivElement
          ? event.target.dataset.rowId
            ? event.target
            : event.target.closest('[data-row-id]')
          : null;

      if (event.target instanceof HTMLDivElement && event.target.dataset.clipId) {
        return handlePlayClip(Number(event.target.dataset.clipStartTime));
      }

      if (tacticalAnalysisMode === 'selection') return actions.restart();

      if (rowElement && rowElement.dataset.rowId) {
        if (rowElement.dataset.rowType === 'home-team') {
          return handlePlayHomeTeamRow(rowElement.dataset.rowId);
        }

        if (rowElement.dataset.rowType === 'opponent-team') {
          return handlePlayOpponentTeamRow(rowElement.dataset.rowId);
        }

        return handlePlayRow(rowElement.dataset.rowId);
      }
    },
    [actions, handlePlayClip, handlePlayHomeTeamRow, handlePlayOpponentTeamRow, handlePlayRow, tacticalAnalysisMode],
  );

  const handleSelectRowClips = useCallback(
    ({ rowId, ctrlKey }: { rowId: string; ctrlKey?: boolean }) => {
      addRowClips(rowId, !ctrlKey);
    },
    [addRowClips],
  );

  const handleClick = useCallback(
    (event: React.MouseEvent<HTMLElement, MouseEvent>) => {
      const rowElement: HTMLDivElement | null =
        event.target instanceof HTMLDivElement
          ? event.target.dataset.rowId
            ? event.target
            : event.target.closest('[data-row-id]')
          : null;

      if (
        event.target instanceof HTMLDivElement &&
        (event.target.dataset.isSelected === 'true' || rowElement?.dataset.isSelected === 'true')
      ) {
        if (tacticalAnalysisMode === TacticalAnalysisPlayingMode.selection && event.target.dataset.clipId) {
          const clipId = event.target.dataset.clipId;
          const clipWithSelection = getClip(clipId);
          if (clipWithSelection?.isSelectedForPlaying) {
            handleSelectClip({ clipId, ctrlKey: event.ctrlKey, metaKey: event.metaKey, shiftKey: event.shiftKey });
          }
        }
        return;
      }

      if (rowElement && rowElement.dataset.rowType === 'home-team') {
        return handleHomeTeamClick(event);
      }

      if (rowElement && rowElement.dataset.rowType === 'opponent-team') {
        return handleOpponentTeamClick(event);
      }

      if (rowElement && rowElement.dataset.rowId) {
        if (event.ctrlKey || event.metaKey) {
          return addRowClips(rowElement.dataset.rowId);
        }

        return addRowClips(rowElement.dataset.rowId, true);
      }

      if (event.target instanceof HTMLDivElement && event.target.dataset.clipId) {
        return handleSelect(event);
      }

      if (event.target instanceof HTMLDivElement && event.target.closest('[data-timeline-element="ruler"]') !== null) {
        return;
      }

      setSelectedPlayingRow({ rowId: '', team: undefined });
      cleanSelection();
      if (tacticalAnalysisMode === TacticalAnalysisPlayingMode.selection) {
        return playAll();
      }
    },
    [
      setSelectedPlayingRow,
      cleanSelection,
      tacticalAnalysisMode,
      getClip,
      handleSelectClip,
      handleHomeTeamClick,
      handleOpponentTeamClick,
      addRowClips,
      handleSelect,
      playAll,
    ],
  );

  return {
    handleClick,
    handleDoubleClick,
    handlePlayClip,
    handlePlaySelectedClips,
    handlePlayRow,
    handlePlayHomeTeamRow,
    handlePlayOpponentTeamRow,
    handleSelectClip,
    handleDeselectClip,
    handleSelectRowClips,
    handleOpponentTeamClick,
    handleHomeTeamClick,
  };
};
