import { UseQueryResult } from '@tanstack/react-query';

import { useFetchRequest } from 'api/hooks/useFetchRequest';
import { tacticalAnalysesInRecordings } from 'api/routes';
import { transformTacticalAnalysis } from 'api/tactical-analysis/transformers';
import { TacticalAnalysisApiResponse } from 'api/types';
import { TacticalAnalysis } from 'shared/types/recording/types';

interface Params {
  recordingIds: string[];
  onSuccess?: (data: TacticalAnalysisApiResponse[]) => void;
  enabled?: boolean;
}

export const DEFAULT_TACTICAL_ANALYSIS_QUERY_REF = 'tactical-analyses';

const generateQueryRef = (recordingIds: string[]) => [DEFAULT_TACTICAL_ANALYSIS_QUERY_REF, recordingIds.join(',')];

export const useTacticalAnalyses = ({
  recordingIds,
  onSuccess,
  enabled = true,
}: Params): UseQueryResult<TacticalAnalysis[]> => {
  return useFetchRequest<TacticalAnalysis[]>({
    queryRef: generateQueryRef(recordingIds),
    url: tacticalAnalysesInRecordings(recordingIds),
    transformer: (data: TacticalAnalysisApiResponse[]) => data.map((analysis) => transformTacticalAnalysis(analysis)),
    onSuccess,
    options: {
      enabled: enabled,
    },
  });
};
