import { Box, Typography } from '@mui/material';
import { Colors } from 'kognia-ui';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import LiveRecordingCard from 'pages/recordings-list/components/live-recording-card';
import LoadingView from 'pages/recordings-list/components/recordings-list/loading';
import NotFoundView from 'pages/recordings-list/components/recordings-list/not-found';
import styles from 'pages/recordings-list/components/recordings-list/RecordingsList.module.scss';
import { RecordingsListItemDetails } from 'pages/recordings-list/components/recordings-list-item-details';
import VODRecordingCard from 'pages/recordings-list/components/vod-recording-card';
import { List } from 'shared/components/list/List';
import { ListHeader } from 'shared/components/list/ui/list-header/ListHeader';
import { ListContainer } from 'shared/components/list/ui/ListContainer';
import { Recording, RecordingsGroup } from 'shared/types/recording/types';

export interface RecordingsListProps {
  isLoading?: boolean;
  recordings: Recording[];
}

export const groupRecordingsByMatchDay = (recordings: Recording[]) => {
  let currentCompetition = '';
  let currentIndex = -1;
  return recordings.reduce((acc, recording) => {
    if (currentCompetition !== recording.matchDay || currentIndex === -1) {
      currentCompetition = recording.matchDay ?? '';
      currentIndex++;
      acc.push({ matchDay: recording.matchDay ?? '', recordings: [] });
    }

    acc[currentIndex].recordings.push(recording);
    return acc;
  }, [] as RecordingsGroup[]);
};

const RecordingsList = ({ isLoading, recordings }: RecordingsListProps) => {
  const { t } = useTranslation();

  const groupedRecordings = useMemo(() => groupRecordingsByMatchDay(recordings), [recordings]);

  if (isLoading) {
    return <LoadingView />;
  }

  if (!recordings.length) {
    return <NotFoundView />;
  }

  return (
    <ListContainer>
      <ListHeader className={styles.recordingHeader}>
        <div>{t('recording:headers.title')}</div>
        <RecordingsListItemDetails>
          <div />
          <div>{t('recording:headers.event')}</div>
          <div>{t('recording:headers.status')}</div>
          <div />
        </RecordingsListItemDetails>
      </ListHeader>
      <List disablePadding>
        {groupedRecordings.map(({ matchDay, recordings }, idx) => {
          return (
            <Box key={`${matchDay}-${idx}`} component={'li'}>
              <Box sx={{ display: 'flex', marginBottom: 1, marginTop: 2, minHeight: '16px' }}>
                <Box>
                  <Typography sx={{ fontSize: '12px', lineHeight: '16px', color: Colors.storm }}>{matchDay}</Typography>
                </Box>
                <Box sx={{ position: 'relative', flexGrow: 1, marginLeft: matchDay ? 2 : 0 }}>
                  <Box
                    sx={{
                      position: 'absolute',
                      height: '1px',
                      content: '""',
                      left: 0,
                      right: 0,
                      width: '100%',
                      background: Colors.iron,
                      top: '50%',
                    }}
                  ></Box>
                </Box>
              </Box>
              <Box>
                {recordings.map((recording) => (
                  <Box key={recording.id} sx={{ marginTop: 1, marginBottom: 1 }}>
                    {recording.isLive ? (
                      <LiveRecordingCard recording={recording} />
                    ) : (
                      <VODRecordingCard recording={recording} />
                    )}
                  </Box>
                ))}
              </Box>
            </Box>
          );
        })}
      </List>
    </ListContainer>
  );
};

export default RecordingsList;
