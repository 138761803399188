import {
  useTaggingToolTagRecordingData,
  UseTaggingToolTagRecordingDataType,
} from 'pages/tagging-tool-tag-recording/hooks/useTaggingToolTagRecordingData';
import { useClientIdValue } from 'shared/contexts/app-state/hooks/useClientIdValue';
import { DataFetching } from 'shared/hooks/use-page-state-machine';

export const useTaggingToolTagRecordingPage = (
  recordingId: string,
  isLive: boolean,
): DataFetching<UseTaggingToolTagRecordingDataType> => {
  const clientId = useClientIdValue();
  const taggingToolTagRecordingData = useTaggingToolTagRecordingData(recordingId, isLive);

  const isInvalidClient = !taggingToolTagRecordingData?.data?.matchWithEpisodes?.match?.clientIds.includes(clientId);

  return {
    ...taggingToolTagRecordingData,
    isInvalidClient,
  };
};
