import { Box, styled } from '@mui/material';
import { Colors } from 'kognia-ui';

export const AddKeypadButton = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  flexDirection: 'column',
  marginTop: theme.spacing(2),
  padding: theme.spacing(0, 1),
  width: '100%',
  height: '96px',
  background: Colors.athens,
  gap: theme.spacing(1),
  border: `1px dotted ${Colors.night}`,
  borderRadius: theme.shape.borderRadius,
  cursor: 'pointer',
}));

export const AddKeypadButtonActionText = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  gap: theme.spacing(1),
}));

export const KeypadListContent = styled(Box)(({ theme }) => ({
  marginTop: theme.spacing(2),
  paddingTop: theme.spacing(2),
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(1),
}));
