import { InfinityQueryDataResult } from 'api/hooks/useInfinityQuery/types';
import { Competition, CompetitionsApiResponse } from 'shared/types/competition/competition';
import { chooseNextCursorValue } from 'shared/utils/choose-next-cursor-value';
import { createSeasonCrossYear } from 'shared/utils/createSeasonCrossYear';

export const transformRecordingCompetitions = (
  response: CompetitionsApiResponse,
): InfinityQueryDataResult<Competition> => {
  return {
    data: {
      items: response.content.map(({ uuid, name, country, seasons, logo_url, format, organization }) => ({
        id: uuid,
        name,
        country,
        logoUrl: logo_url,
        format: format,
        organization: organization
          ? {
              id: organization.uuid,
              name: organization.name,
              logoUrl: organization.logo_url,
            }
          : null,
        seasons: seasons.map((season) => ({
          id: season.uuid,
          name: season.name,
          year: season.year,
          crossesYear: season.crosses_year,
          crossYearValue: createSeasonCrossYear(season),
        })),
      })),
      page: response.page,
    },
    nextCursor: chooseNextCursorValue(response.page.totalPages, response.page.number),
  };
};
