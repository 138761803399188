import { useMutationRequest } from 'api/hooks/useMutationRequest';
import { HTTPMethod } from 'api/types';
import { annotationApiUrls } from 'pages/backoffice/api/routes';
import { useAllowedBackofficeOrigin } from 'pages/backoffice/api/useAllowedBackofficeOrigin';
import { metadataApiErrorParser, MetadataApiErrorResponse } from 'pages/backoffice/api/utils';

interface DeleteMetadataFixtureOptions {
  data: { fixtureId: string };
  onSuccess?: () => void;
}

export const useDeleteFixturesPlayers = () => {
  const { getIsAllowedBackofficeOrigin } = useAllowedBackofficeOrigin();
  const mutationRequest = useMutationRequest<unknown, unknown, MetadataApiErrorResponse>({
    type: HTTPMethod.DELETE,
    errorMessage: (data) => metadataApiErrorParser(data, 'Delete fixture players error'),
  });

  const deleteFixturesPlayers = ({ data, onSuccess }: DeleteMetadataFixtureOptions) => {
    if (!getIsAllowedBackofficeOrigin()) return;

    mutationRequest.mutate(
      {
        url: `${annotationApiUrls.FIXTURE_PLAYERS(data.fixtureId)}`,
      },
      {
        onSuccess,
      },
    );
  };

  return { ...mutationRequest, deleteFixturesPlayers };
};
